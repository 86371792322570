import { environment } from '../config/environment';

export type RecurlySubscriptionNames = 'Annual' | 'Monthly';

export const SubscriptionPlans: { ANNUAL: RecurlySubscriptionNames; MONTHLY: RecurlySubscriptionNames } = {
    ANNUAL: 'Annual',
    MONTHLY: 'Monthly',
};

export const getPlanByCode = (code, plans) => {
    let plan = null;
    if (plans) {
        plan = plans.find((item) => item?.code.match(code));
    }
    return plan;
};

export enum SUBSCRIPTION_PLAN_NAME {
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
    NONE = 'none',
    UNKNOWN = 'unknown',
}

export const getPlanName = (planId: string): SUBSCRIPTION_PLAN_NAME => {
    let type;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (planId && planId === `${environment.RECURLY_PLAN_PREFIX}aasub_arkcom_${SubscriptionPlans.MONTHLY}`) {
        type = SUBSCRIPTION_PLAN_NAME.MONTHLY;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    } else if (planId && planId === `${environment.RECURLY_PLAN_PREFIX}aasub_arkcom_${SubscriptionPlans.ANNUAL}`) {
        type = SUBSCRIPTION_PLAN_NAME.ANNUAL;
    } else if (!planId) {
        // if user not logged in or is not subscriber
        type = SUBSCRIPTION_PLAN_NAME.NONE;
    } else {
        // if new one plan added and not handled - to get info and check
        type = `${SUBSCRIPTION_PLAN_NAME.UNKNOWN} ('${planId}')`;
    }

    return type;
};
