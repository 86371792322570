import React from 'react';

import i18next from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type i18nTags = 'div' | 'p' | 'span' | 'strong' | 'em' | 'b' | 'i' | 'h1' | 'h2' | 'h3';

type i18nTextProps = {
    as?: i18nTags;
    keyName: string;
    id?: string;
    className?: string;
    html?: boolean;
    params?: { [key: string]: any };
    itemProp?: string;
    link?: string;
    onClick?: () => void;
} & WithTranslation;

i18next.init({
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
});

export const I18nText = React.memo(
    withTranslation()((props: i18nTextProps) => {
        const Component: any = props.as || 'span';
        if (props.html) {
            return (
                <Component
                    id={props.id}
                    className={props.className}
                    itemProp={props.itemProp}
                    dangerouslySetInnerHTML={{
                        __html: props.t(props.keyName, props.params),
                    }}
                    suppressHydrationWarning
                    onClick={props.onClick}
                />
            );
        }
        return (
            <Component
                className={props.className}
                id={props.id}
                itemProp={props.itemProp}
                suppressHydrationWarning
                onClick={props.onClick}
            >
                {props.link && <Link to={props.link}>{props.t(props.keyName, props.params)}</Link>}
                {!props.link && props.t(props.keyName, props.params)}
            </Component>
        );
    })
);
