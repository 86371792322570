import React from 'react';

import { I18nText } from '../../atoms/i18nText/i18nText';
import styles from './ButtonAvatarMore.css';

type ButtonAvatarMoreProps = {
    onClick: () => any;
};
export const ButtonAvatarMore = React.memo(({ onClick }: ButtonAvatarMoreProps) => (
    <div className={styles.container} onClick={onClick}>
        <I18nText as="div" keyName={'MORE'} className={styles.label} />
    </div>
));
