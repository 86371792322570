import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../../atoms/Link/Link';
import { getPlanByCode, SubscriptionPlans } from '../../../../constants/SubscriptionPlan';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { AnnualCard } from '../../../../molecules/AnnualCard/AnnualCard';
import { MonthlyCard } from '../../../../molecules/MonthlyCard/MonthlyCard';
import subsStyles from '../../../../molecules/Subscription/SubscriptionStyles.css';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AppInsightService } from '../../../../services/AppInsight';
import PaymentService from '../../../../services/PaymentService';
import { UrlService } from '../../../../services/UrlService';
import UserService from '../../../../services/UserService';
import { setSnackbarData } from '../../../../store/ducks/layout';
import { setActiveUserSubscriptions } from '../../../../store/ducks/subscription/common';
import styles from './ManagePlan.css';

export enum ManageType {
    MANAGE,
    UPDATE,
    CANCEL,
}

// Consts
const HEADING_MANAGE = 'Manage your plan';
const HEADING_UPDATE_SUBSCRIPTION = 'Confirm your subscription';
const HEADING_DOWNGRADE_SUBSCRIPTION = 'Downgrade your subscription';
const HEADING_CANCELLATION = 'Confirm your cancellation';
const SUB_HEADING_UPGRADE = 'Upgrade to an annual plan';
const SUB_HEADING_DOWNGRADE = 'Downgrade to a monthly plan';
const SUB_HEADING_CANCEL_SUBS = 'Cancel your subscription';
const BTN_LABEL_UPGRADE = 'Upgrade';
const BTN_LABEL_DOWNGRADE = 'Downgrade';

export const ManagePlan: FC = React.memo(() => {
    const [manageType, setManageType] = useState(ManageType.MANAGE);
    // TODO: Just for test
    /* Get Subscription Type from some storage */
    const [subsPlan, setSubsPlan] = useState(SubscriptionPlans.MONTHLY);

    const plans = useSelector((state) => state.subscriptionPlans || []);

    const [expiredDate, setExpiredDate] = useState('');
    // TODO: You need to get active Subscriptions and last active subscription ID
    const [activeSubscriptionId, setActiveSubscriptionId] = useState('');
    const [subscriptionIsAutoRenewable, setSubscriptionIsAutoRenewable] = useState(null);

    const activeSubscriptionFromRedux = useSelector((state) => state.activeUserSubscriptions || []);

    const dispatch = useDispatch();

    // TODO:This useEffect not needed if you got active Subscriptions before
    useEffect(() => {
        if (activeSubscriptionFromRedux?.length === 0) {
            PaymentService.getSubscriptions()
                .then((res) => {
                    dispatch(setActiveUserSubscriptions(res));
                    if (res.length !== 0) {
                        setStatePlan(res);
                    }
                })
                .catch((err) => {
                    console.log('PaymentService.getSubscriptions()', err);
                    AppInsightService.trackAppError(err, { data: 'ManagePlan' });
                });
        } else {
            setStatePlan(activeSubscriptionFromRedux);
        }
    }, []);

    // SEt UP all params for layout
    function setStatePlan(subscriptions) {
        const LAST_SUBSCRIPTION = PaymentService.getLastDateSubscription(subscriptions);
        const { endDate, subscriptionId, isAutoRenewable } = LAST_SUBSCRIPTION;
        // Maybe install some library dateFormatter or smth?
        const ENJOY_UP_DATE = new Date(endDate).toLocaleString('en-US', {
            day: 'numeric',
            year: 'numeric',
            month: 'long',
        });
        setActiveSubscriptionId(subscriptionId);
        setExpiredDate(ENJOY_UP_DATE);
        setSubscriptionIsAutoRenewable(isAutoRenewable);
    }

    // TODO: Just for test rewrite this. Checking here real active subscription type
    useEffect(() => {
        const subsPlanParam = UrlService.getQSParam(window.location.search, 'subsPlan');
        if (subsPlanParam === SubscriptionPlans.MONTHLY) {
            setSubsPlan(SubscriptionPlans.MONTHLY);
        } else {
            setSubsPlan(SubscriptionPlans.ANNUAL);
        }
    }, []);

    const handlerBtnCard = () => {
        if (manageType === ManageType.MANAGE) {
            setManageType(ManageType.UPDATE);
        } else {
            setManageType(ManageType.MANAGE);
        }
    };

    const handlePurchase = (value) => {
        /** true/false for Annual purchase */
        if (value) {
            if (subsPlan === SubscriptionPlans.ANNUAL) {
                alert('Changed to Monthly');
                // TODO: For analytics - uncomment & test in the future when ready
                // Analytics.trackEvent(
                //     Analytics.subscription.subscriptionDowngrade(
                //         subscriptionSource,
                //         planForAnalytics,
                //         paymentMethod,
                //         subscriptionId
                //     )
                // );
            } else {
                alert('Changed to Annual');
                // TODO: For analytics - uncomment & test in the future when ready
                // Analytics.trackEvent(
                //     Analytics.subscription.subscriptionUpgrade(
                //         subscriptionSource,
                //         planForAnalytics,
                //         paymentMethod,
                //         subscriptionId
                //     )
                // );
            }
        } else {
            setManageType(ManageType.MANAGE);
        }
    };

    const handleCancel = (value) => {
        /** true/false for Cancel purchase */
        if (value) {
            subscriptionIsAutoRenewable
                ? PaymentService.cancelSubscription(activeSubscriptionId)
                      .then(() => {
                          setSubscriptionIsAutoRenewable(false);
                          dispatch(
                              setSnackbarData({
                                  isOpened: true,
                                  message: 'Your subscription has been canceled.',
                                  type: 'success',
                              })
                          );
                          Analytics.trackEvent(Analytics.subscription.cancelAutorenewal());
                          PaymentService.getSubscriptions()
                              .then((res) => {
                                  dispatch(setActiveUserSubscriptions(res));
                              })
                              .catch((err) => {
                                  AppInsightService.trackAppError(err, {
                                      data: 'cancelSubscription-GetSubscription()',
                                  });
                              });
                          UserService.closePanel();
                      })
                      .catch((err) => {
                          dispatch(
                              setSnackbarData({
                                  isOpened: true,
                                  message: err.body.details[0].Message,
                                  type: 'error',
                              })
                          );
                          AppInsightService.trackAppError(err, { data: 'handleCancel()' });
                      })
                : dispatch(
                      setSnackbarData({
                          isOpened: true,
                          message: 'Your subscription has already been canceled.',
                          type: 'error',
                      })
                  );
        } else {
            setManageType(ManageType.MANAGE);
        }
    };

    const handlerCancelSubscription = () => {
        setManageType(ManageType.CANCEL);
    };

    const getHeading = () => {
        switch (manageType) {
            case ManageType.MANAGE:
                return HEADING_MANAGE;
            case ManageType.UPDATE:
                if (subsPlan === SubscriptionPlans.MONTHLY) {
                    return HEADING_UPDATE_SUBSCRIPTION;
                }
                return HEADING_DOWNGRADE_SUBSCRIPTION;
            case ManageType.CANCEL:
                return HEADING_CANCELLATION;
            default:
                return null;
        }
    };

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const getSubHeading = () => {
        if (subsPlan === SubscriptionPlans.MONTHLY) {
            return SUB_HEADING_UPGRADE;
        }
        if (subsPlan === SubscriptionPlans.ANNUAL) {
            return SUB_HEADING_DOWNGRADE;
        }
    };

    const getBottomRow = () => {
        switch (manageType) {
            case ManageType.MANAGE:
                return (
                    <div className={styles.bottomRow}>
                        <div className={styles.subHeading}>{SUB_HEADING_CANCEL_SUBS}</div>
                        <div className={styles.enjoyRow}>
                            Enjoy your favorite games without ads, plus a 15% discount and profile customizations
                            through <strong>{expiredDate}</strong>
                        </div>
                        <div>
                            <Button type="button" className={styles.cancelBtn} onClick={handlerCancelSubscription}>
                                Cancel subscription
                            </Button>
                        </div>
                    </div>
                );
            case ManageType.UPDATE:
                return (
                    <div className={styles.confirmBlock}>
                        <div className={styles.confirmBtnsRow}>
                            <Button linkOnMobile outlined onClick={() => handlePurchase(false)}>
                                I’ve changed my mind
                            </Button>
                            <Button className={styles.confirmBtn} onClick={() => handlePurchase(true)}>
                                Confirm purchase
                            </Button>
                        </div>

                        <div className={styles.confirmText}>
                            <p>
                                By confirming purchase you agree to <br />
                                Arkadium's{' '}
                                <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
                                    <I18nText keyName="PLAYER_AGREEMENT" />
                                </NavLink>
                                {', '}
                                <NavLink to="/arkadium-advantage-agreement.pdf" target="_blank" rel="noopener">
                                    <I18nText keyName="SUBSCRIPTION_AGREEMENT" />
                                </NavLink>{' '}
                                and
                                <span className={subsStyles.link}> </span>
                                <NavLink to={UrlService.createURL('privacy-policy')} target="_blank" rel="noopener">
                                    <I18nText keyName="FOOTER_PRIVACY_POLICY" />
                                </NavLink>{' '}
                            </p>
                        </div>
                    </div>
                );
            case ManageType.CANCEL:
                return (
                    <>
                        <div className={classNames(styles.confirmText, styles.cancelText)}>
                            Are you sure you want to cancel? Once you cancel, you can continue to enjoy your favorite
                            games <strong>without ads</strong>, plus a <strong>15% discount</strong> and{' '}
                            <strong>profile customizations</strong> until the end of your billing cycle.
                        </div>
                        <div className={classNames(styles.confirmBtnsRow, styles.cancelBtnsRow)}>
                            <Button outlined onClick={() => handleCancel(true)}>
                                Cancel subscription
                            </Button>
                            <Button className={styles.confirmBtn} onClick={() => handleCancel(false)}>
                                I’ve changed my mind
                            </Button>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const getCardType = () => {
        switch (subsPlan) {
            case SubscriptionPlans.MONTHLY:
                return (
                    <AnnualCard
                        btnLabel={BTN_LABEL_UPGRADE}
                        managePlanStyles
                        manageType={manageType}
                        plan={getPlanByCode(SubscriptionPlans.ANNUAL, plans)}
                        handlerBtn={handlerBtnCard}
                    />
                );
            case SubscriptionPlans.ANNUAL:
                return (
                    <MonthlyCard
                        btnLabel={BTN_LABEL_DOWNGRADE}
                        managePlanStyles
                        manageType={manageType}
                        plan={getPlanByCode(SubscriptionPlans.ANNUAL, plans)}
                        handlerBtn={handlerBtnCard}
                    />
                );
            default:
                return null;
        }
    };

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const getSaveText = () => {
        switch (subsPlan) {
            case SubscriptionPlans.MONTHLY:
                return (
                    <p>
                        Save <strong>37%</strong> when you switch to an annual plan. Any changes you make will take
                        effect at the start of your next billing cycle.
                    </p>
                );
            case SubscriptionPlans.ANNUAL:
                if (manageType === ManageType.MANAGE) {
                    return (
                        <p>
                            <strong>You're currently saving 37%</strong> on your annual plan. Any changes you make will
                            take effect at the start of your next billing cycle
                        </p>
                    );
                }
                return <p>Any changes you make will take effect at the start of your next billing cycle.</p>;
            default:
                return null;
        }
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.mainHeading}>{getHeading()}</div>
                {/* {manageType === ManageType.MANAGE && (
                    <div className={classNames(styles.subHeading, styles.top)}>{getSubHeading()}</div>
                )}
                {manageType !== ManageType.CANCEL && (
                    <>
                        <div className={styles.annualRow}>{plans.length !== 0 ? getCardType() : <AppLoader />}</div>
                        <div className={styles.saveInfo}>{getSaveText()}</div>
                    </>
                )} */}
                {getBottomRow()}
            </div>
        </>
    );
});
