import React from 'react';

import classnames from 'classnames';

import { MiscUtils } from '../../../utils/MiscUtils';
import styles from './DisplayAd.css';

interface DisplayAdProps {
    dimensions: number[][];
    id: string;
    refreshRate?: number;
    className?: string;
    bidtimeout?: number;
    viewableOnly?: boolean;
    keyValues?: [string[]];
    style?: React.CSSProperties;
}

class DisplayAdBase extends React.Component<DisplayAdProps> {
    static displayName = 'DisplayAd';

    get adOptions() {
        const { id, dimensions, bidtimeout, refreshRate, viewableOnly } = this.props;

        const resProps: any = {
            product: 'arena',
            dimensions: JSON.stringify(dimensions),
            'data-id': id,
            viewableOnly,
        };

        if (bidtimeout) {
            resProps.biddertimeout = String(bidtimeout);
        }

        if (refreshRate) {
            resProps.refreshrate = refreshRate;
        }

        // if (keyValues) {
        //     resProps.keyValues = JSON.stringify(keyValues);
        // }

        return resProps;
    }

    shouldComponentUpdate(): boolean {
        return false;
    }

    // May be reused later
    // getFixingDimensionsStyles = (
    //     arrWithPossibleDimensions: number[][]
    // ): { maxWidth: string; maxHeight: string; overflow: string } => {
    //     const arrayWithMaxDimensions = arrWithPossibleDimensions.reduce(
    //         (acc, item) => {
    //             acc[0] = Math.max(item[0], acc[0]);
    //             acc[1] = Math.max(item[1], acc[1]);
    //
    //             return acc;
    //         },
    //         [0, 0]
    //     );
    //
    //     return {
    //         maxWidth: `${arrayWithMaxDimensions[0].toString()}px`,
    //         maxHeight: `${arrayWithMaxDimensions[1].toString()}px`,
    //         overflow: 'hidden',
    //     };
    // };

    render() {
        const { className, style } = this.props;

        if (MiscUtils.isServer) {
            return null;
        }

        return (
            <Container className={className} style={style}>
                <display-ad-component key={this.adOptions['data-id']} {...this.adOptions} />
            </Container>
        );
    }
}

const Container = ({ className, ...props }) => <div className={classnames(styles.container, className)} {...props} />;

export const DisplayAd = DisplayAdBase;
