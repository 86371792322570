import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GemsAnalyticsGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    gemsPurchaseSuccess(purchasableItemId: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setNameGA('virtualItemPurchaseSuccess');
        event.setEventGaCategory('virtualItemPurchaseSuccess');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(purchasableItemId);
        event.setGaNonInteraction(false);
        return event.build();
    }
}
