import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class ProfileAnalyticsGa {
    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }

    registration(xsollaId: string): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('registration');
        event.setNameGA('registration');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(xsollaId);
        event.setGaNonInteraction(false);
        return event.build();
    }
}
