import React, { PureComponent } from 'react';

import styles from './ArkadiumLogo.css';

type ArkadiumLogoProps = {
    className?: string;
    arrowInheritColor?: boolean;
    textInheritColor?: boolean;
    arrowHoverColor?: string;
    textHoverColor?: string;
    focusable?: boolean;
    ariaHidden?: boolean;
    role?: string;
};

type ArkadiumLogoState = {
    hover: boolean;
};

export class ArkadiumFullLogo extends PureComponent<ArkadiumLogoProps, ArkadiumLogoState> {
    state: ArkadiumLogoState = { hover: false };

    onMouseOver = () => {
        this.setState({ hover: true });
    };

    onMouseOut = () => {
        this.setState({ hover: false });
    };

    render() {
        const { hover } = this.state;
        const arrowColor = this.props.arrowInheritColor ? 'currentColor' : '#DC1E34';
        const arrowHoverColor = this.props.arrowHoverColor ? this.props.arrowHoverColor : arrowColor;
        const textColor = this.props.textInheritColor ? 'currentColor' : '#000000';
        const textHoverColor = this.props.textHoverColor ? this.props.textHoverColor : textColor;

        return (
            <svg
                className={this.props.className}
                xmlns="http://www.w3.org/2000/svg"
                width="139"
                height="40"
                viewBox="0 0 542.55 160.1"
                onMouseOut={this.onMouseOut}
                onMouseOver={this.onMouseOver}
                focusable={this.props.focusable}
                aria-hidden={this.props.ariaHidden}
                role={this.props.role}
            >
                <g>
                    <path
                        className={styles.logoPathArrow}
                        fill={hover ? arrowHoverColor : arrowColor}
                        d="M526.58,6a9.22,9.22,0,0,1,4,1c5.8,2.79,6,7.29,5.91,15.12v61c0,3.72-1.72,9.78-7.2,9.78a10.54,10.54,0,0,1-3.95-.87c-4-1.62-5.59-7.68-7.29-10.93-2.22-4.25-6.16-12.07-15.19-16.4a28.61,28.61,0,0,0-12.55-2.78,48.76,48.76,0,0,0-9.75,1.17c-2.61.51-6.27,1.83-9.56,1.83a8.29,8.29,0,0,1-3.53-.69c-8.08-3.77-3.58-11.11-.19-13.83l47.28-37.77c4.57-3.73,8.05-6.61,12-6.61m0-6h0c-6,0-10.47,3.64-15.62,7.84l-.13.1L463.58,45.7c-3.73,3-7.18,8.47-6.52,14,.3,2.48,1.72,7.08,7.92,10A14.25,14.25,0,0,0,471,70.91a33.87,33.87,0,0,0,8.42-1.39c.86-.22,1.67-.43,2.29-.55l.29-.06a41.77,41.77,0,0,1,8.3-1,22.47,22.47,0,0,1,10,2.18c7,3.39,10.23,9.48,12.33,13.51l.14.26c.29.56.64,1.37,1,2.23,1.69,3.93,4,9.32,9.34,11.48a16.53,16.53,0,0,0,6.21,1.31A11.93,11.93,0,0,0,538,95.34c4.26-4.27,4.57-10.45,4.57-12.25v-61c.07-7.51.15-16-9.32-20.56A15.27,15.27,0,0,0,526.58,0Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M87,159.38H81.48V102.12H87v10.71c4-7.31,10.67-11.43,18.77-11.43a21.51,21.51,0,0,1,9.18,1.83l.47.22v6l-1.17-.55a18.85,18.85,0,0,0-8.91-2.09C94.84,106.84,87,116.7,87,129.77Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M207.26,160.1c-18.67,0-30.74-11.58-30.74-29.51,0-17.74,12.11-29.2,30.85-29.2,8.43,0,16,2.31,21.65,6.54v-5.81h5.37v57.26H229v-5.82C223.3,157.84,215.82,160.1,207.26,160.1Zm.32-53.47c-15.72,0-25.48,9.18-25.48,24,0,15,9.72,24.27,25.38,24.27,9.12,0,16.56-2.62,21.54-7.57v-33C223.92,109.43,216.13,106.63,207.58,106.63Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M277.32,160.1c-18.67,0-30.74-11.58-30.74-29.51,0-17.74,12.11-29.2,30.85-29.2,8.29,0,16.07,2.37,21.65,6.54v-28h5.37v79.45h-5.37v-5.82C293.35,157.84,285.88,160.1,277.32,160.1Zm.32-53.47c-15.72,0-25.48,9.18-25.48,24,0,15,9.72,24.27,25.37,24.27,8.71,0,16.34-2.69,21.54-7.58v-33C294,109.43,286.19,106.63,277.64,106.63Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M326.26,159.38h-5.47V102.12h5.47Zm-2.63-66.47a4.63,4.63,0,1,1,0-9.26,4.63,4.63,0,1,1,0,9.26Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M365.8,160.1c-17.06,0-24.67-7.67-24.67-24.86V102.12h5.36v33.12c0,13.76,5.84,19.63,19.52,19.63,8.29,0,16.9-3,22.07-7.6V102.12h5.47v57.26h-5.47v-6.31C383.2,156.72,374.51,160.1,365.8,160.1Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M499.92,159.38h-5.36V125.95c0-13.54-5.14-19.32-17.18-19.32a36.23,36.23,0,0,0-21.16,6.93A37.8,37.8,0,0,1,458,125.95v33.43H452.6V125.95c0-13.54-5-19.32-16.86-19.32-9.65,0-16.5,3.87-21.43,7.35v45.4h-5.47V102.12h5.47v6.29c4.5-3,12.39-7,21.86-7,8.72,0,14.65,2.6,18.1,7.95a38.4,38.4,0,0,1,23.53-7.95c15.29,0,22.12,7.57,22.12,24.55Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M67.09,159.38h6.06L38.86,85.46,38.64,85H34.51L0,159.38H6.07l9.44-20.7h42.1ZM18,133.25,36.52,92.62l18.6,40.64Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M173,159.05v.31h.37Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M129.92,92.71h0v-.45h0V79.93H124.5v79.45h5.42Z"
                    />
                    <path
                        className={styles.logoPathText}
                        fill={hover ? textHoverColor : textColor}
                        d="M141.77,128.12l29.65-25.34h-8.88l-29.35,25.55,33.07,30.17h9.13Z"
                    />
                </g>
            </svg>
        );
    }
}

export const ArkadiumSmallLogo = React.memo((props: ArkadiumLogoProps) => {
    const color = props.arrowInheritColor ? 'currentColor' : '#FFF';
    return (
        <svg
            role="img"
            aria-label="arkadium-logo-small-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" />
                <path
                    id="Path"
                    stroke={color}
                    strokeWidth="2"
                    d="M21.7127873,5.5882446 C22.3540875,5.59980165 22.9472497,5.74176421 23.439865,5.99279506 C24.4054763,6.45864101 25.0458961,7.01099644 25.4357201,7.74207757 C25.8834093,8.58168021 26.0127189,9.6247489 25.9988671,10.9196336 L25.9988671,10.9196336 L25.9988671,24.2539842 C25.9988671,25.3572597 25.6266315,26.4923333 24.8769831,27.3048176 C24.2581533,27.9755179 23.376059,28.4081048 22.2531676,28.4109077 C21.7102216,28.3959301 21.1748518,28.2791839 20.7223399,28.0814385 C19.5844494,27.6132426 19.038046,26.8148497 18.5210228,25.6901443 C17.3899034,23.2935944 16.6011074,22.558298 15.6733732,22.0634438 C15.0468917,21.8174215 14.6496667,21.7337674 14.261182,21.7061762 C13.1072242,21.6959835 12.032705,21.9187677 11.1171176,22.1148855 C10.5726766,22.2315041 10.0988021,22.3461987 9.82025067,22.3461987 C9.21050062,22.3461987 8.68856501,22.2532822 8.11737263,22.00212 C7.32672589,21.6284476 6.72643863,21.0761545 6.3702503,20.4093881 C6.02094991,19.7555157 5.9122797,18.9967773 6.07054686,18.2164779 C6.28004713,17.1835856 6.92041069,16.2592199 7.68696642,15.6415975 L7.68696642,15.6415975 L17.8708282,7.47380855 C19.0400599,6.51586749 19.4516901,6.19856143 20.2620739,5.87651645 C20.725802,5.69223178 21.2085745,5.58933162 21.7127873,5.5882446 Z"
                />
            </g>
        </svg>
    );
});
