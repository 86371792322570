import { storeOS } from './ConnectedApps';

export enum GameAreaBackgroundColorTone {
    DARK = 'dark',
    LIGHT = 'light',
}

type Thumbs = {
    promoThumbPath: string;
    thumbPath: string;
    secondaryThumbPath: string;
    screenshotPath: string;

    squareIcon76: string;
    squareIcon120: string;
    squareIcon152: string;
    squareIcon167: string;
    squareIcon180: string;
    squareIcon192: string;
    squareIcon512: string;

    pwaIcon512: string;
    pwaIcon152: string;
    pwaIcon192: string;
    pwaIcon180: string;
    pwaIcon167: string;
    pwaIcon120: string;
    pwaIcon76: string;

    promoTile280x600: string;
    promoTile760x312: string;
    promoTile1000x312: string;
    promoTile280x280: string;
    promoTile384x216: string;

    webGameAreaTopAssetLeft: string;
    webGameAreaTopAssetRight: string;
};

export type Meta = {
    locale: string;
    gameReleaseDate: number;
    name: string;
    alias: string;
    description: string;
    rules: string;
    subtitle: string;
    shortTitle: string;
    fullTitle: string;
    htmlTitle: string;
    htmlDescription: string;
    seoTitle: string;
    seoTitleEnUs: string;
    seoTitleEnCn: string;
    seoTitleEnNz: string;
    seoTitleEnAu: string;
    seoTitleEnGb: string;
    details: string;
    categories: string[];
    backgroundColor: string;

    isFlex: boolean;
    isAdsFree: boolean;
    isGrm: boolean;
    isIframe: boolean;
    aspectRatio: number;
    webGameAreaTopAssetLeft: string;
    webGameAreaTopAssetRight: string;
    externalConfigUrl: string;
    pushNotificationEnabled: boolean;
    gameMetaFilesBaseUrl: string;
    subscriberOnlyGame: string;
    primaryCategory: string;
    appStore: storeOS[];
    hasCollection: string;
    thumbs: Thumbs;
};

type AnalyticsSorting = {
    desktopRank: number;
    mobileRank: number;
    tabletRank: number;
};

type AnalyticsMetaData = {
    rank: AnalyticsSorting;
    recommendedGames: string[];
};

export type GameModel = {
    id: string;
    name: string;
    slug: string;
    sdkName: string;
    gameAssetOriginUrl: string;
    thumbPath: string;
    description: string;
    tags: string;
    version: string;
    gameReleaseDate: number;
    metaHTMLTitle: string;
    metaHTMLDescription: string;
    meta: Meta;
    analyticsMetaData: AnalyticsMetaData;
    orderByGameStarts: number;
    renderingType: 'JavaScript' | 'Html' | 'External';
    hasFrenchLocale: boolean;
};
