import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { TranslationService } from '../../../../services/TranslationService';
import UserService from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';

export const ResetPassword = React.memo(() => {
    const [loading, setLoading] = useState(false);
    const authDataContext = useContext(AuthDataContext);

    const dispatch = useDispatch();

    const handleSignIn = () => {
        dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
    };

    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.PASSWORD_RESET);

    const [message, setMessage] = useState(TranslationService.translateIt('REGISTER_REMEMBER_YOUR_PASSWORD'));

    const emailValidation = yup.string().required().email();

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
            .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')),
    });

    const defaultValues = {
        email: authDataContext.value.email || '',
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();
    const { email } = values;

    const inputElement = React.useRef(null);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // call submit
                inputElement.current?.click();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const onSubmit = () => {
        if (!captchaToken) {
            return updateCaptchaToken(true);
        }
        setLoading(true);
        authDataContext.setAuthData({ ...authDataContext.value, email });
        UserService.requestResetPassword(email, captchaToken)
            .then((res) => {
                if (res === 200) {
                    dispatch(setSignInUpState(SignInUpComponents.THANK_YOU));
                    Analytics.trackEvent(Analytics.profile.resetPassword('start'));
                } else if (
                    UserService.errorCodeToEnum(res) === 'UserNotConfirmedError' ||
                    UserService.errorCodeToEnum(res) === 'UserEmailConfirmationCodeIsInvalid'
                ) {
                    setMessage(TranslationService.translateIt('PLEASE_CHECK_YOUR_INBOX_FOR_THE_CONFIRMATION'));
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: TranslationService.translateIt('YOUR_EMAIL_ADDRESS_HAS_NOT_YET_BEEN_CONFIRMED'),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <RightPanelWrapper headerLabelKey="REGISTER_RESET_YOUR_PASSWORD">
            <I18nText as="div" className={styles.subHeader} keyName={'REGISTER_PLEASE_ENTER_THE_EMAIL'} />{' '}
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent}>
                <div className={styles.inputRow}>
                    <Input
                        required
                        className={styles.input}
                        isValid={!errors.email}
                        ariaInvalid={errors.email ? 'true' : 'false'}
                        register={register}
                        name="email"
                        type="text"
                        maxLength={50}
                        placeholder={TranslationService.translateIt('REGISTER_ENTER_YOUR_EMAIL_ADDRESS')}
                        value={values.email}
                        showError={errors?.email}
                        errorMessage={errors?.email?.message ?? ''}
                        onBlur={() => {
                            // do we need to add loading here?
                            emailValidation.isValidSync(values.email);
                        }}
                    />
                </div>
                <div className={styles.rememberRow}>
                    {message}{' '}
                    <Button isLink onClick={handleSignIn}>
                        <I18nText keyName={'REGISTER_SIGN_IN'} />
                    </Button>
                </div>
                <div className={styles.buttonRow}>
                    <Button
                        className={styles.submitButton}
                        type="submit"
                        disabled={loading && !captchaToken}
                        ref={inputElement}
                    >
                        <I18nText keyName={'REGISTER_SUBMIT'} />
                    </Button>
                </div>
            </form>
        </RightPanelWrapper>
    );
});
