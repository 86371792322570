import { createAction, createReducer } from 'redux-act';

import { SubscriptionPlans } from '../../../constants/SubscriptionPlan';
import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import {
    SubscriptionPlan,
    SubscriptionSourceType,
    UserSubscription,
} from '../../../models/Subscription/SubscriptionData';

export const setRecurlyToken = createAction<null | string>('set recurly token');
export const recurlyTokenReducer = createReducer<null | string>({}, null);
recurlyTokenReducer.on(setRecurlyToken, (_state, payload) => payload);

export const setStepIndex = createAction<number>('set subscription page step');
export const stepIndexReducer = createReducer<number>({}, 0);
stepIndexReducer.on(setStepIndex, (_state, payload) => payload);

export const setPaymentMethod = createAction<PaymentMethod>('set payment method');
export const paymentMethodReducer = createReducer<PaymentMethod>({}, PaymentMethod.CARD);
paymentMethodReducer.on(setPaymentMethod, (_state, payload) => payload);

export const enum SubscriptionStep2Content {
    AuthForm,
    PurchaseDetails,
}

type subscriptionSecondStepContent = {
    type: SubscriptionStep2Content;
    step: number;
};

export const setSubscriptionStep2Content = createAction<subscriptionSecondStepContent>(
    'set subscription step 2 content'
);
export const subscriptionSecondStepContentReducer = createReducer<subscriptionSecondStepContent>(
    {},
    {
        type: SubscriptionStep2Content.PurchaseDetails,
        step: 1,
    }
);
subscriptionSecondStepContentReducer.on(setSubscriptionStep2Content, (_state, payload) => payload);

export const setIsValidForm = createAction<boolean>('set subscription form valid');
export const isValidFormReducer = createReducer<boolean>({}, false);
isValidFormReducer.on(setIsValidForm, (_state, payload) => payload);

export const setActiveUserSubscriptions = createAction<UserSubscription[]>('set active subscriptions for a user');
export const activeUserSubscriptionsReducer = createReducer<UserSubscription[] | null>({}, null);
activeUserSubscriptionsReducer.on(setActiveUserSubscriptions, (_state, payload: UserSubscription[]) => payload);

export const setExpiredUserSubscriptions = createAction<UserSubscription[]>('set active subscriptions for a user');
export const expiredUserSubscriptionsReducer = createReducer<UserSubscription[] | null>({}, null);
expiredUserSubscriptionsReducer.on(setExpiredUserSubscriptions, (_state, payload: UserSubscription[]) => payload);

export const setSubscriptionPlans = createAction<SubscriptionPlan[]>('set available plans for subscription');
export const subscriptionPlansReducer = createReducer<SubscriptionPlan[] | null>({}, null);
subscriptionPlansReducer.on(setSubscriptionPlans, (_state, payload: SubscriptionPlan[]) => payload);

export const setSubscriptionSource = createAction<string>('set source from which opened subscription tab');
export const subscriptionSourceReducer = createReducer<SubscriptionSourceType | null>({}, null);
subscriptionSourceReducer.on(setSubscriptionSource, (_state, payload: SubscriptionSourceType) => payload);

export const setActiveSubscriptionPlan = createAction<string>('set active subscription plan');
export const activeSubscriptionPlanCodeReducer = createReducer<string>({}, SubscriptionPlans.ANNUAL);
activeSubscriptionPlanCodeReducer.on(setActiveSubscriptionPlan, (_state, payload) => payload);
