import { environment } from '../client/config/environment';

export class StringUtils {
    public static equalIgnoreCase(a: string, b: string): boolean {
        return a?.toLowerCase?.() === b.toLowerCase();
    }

    public static containsIgnoreCase(searchIn: string, searchFor: string): boolean {
        searchIn = searchIn.toLowerCase();
        searchFor = searchFor.toLowerCase();
        return searchIn.indexOf(searchFor) >= 0;
    }

    public static clearPathname(pathname: string): string {
        const str = pathname.includes('?') ? pathname.split('?')[0] : pathname;
        let result = str.endsWith('/') ? str.slice(0, -1) : str;
        result = result.startsWith('/') ? result.slice(1) : result;
        return result;
    }

    // TODO: consider create separate page for 500 status code response
    public static render500Page(title: string, e: Error): string {
        const errInfo = `<p><strong>Message:</strong> ${e.message}</p>
                         <p><strong>Stack:</strong> ${e.stack}</p>`;
        const errorHtml = `<h3>Internal Server Error.</h3>
                           <h4>(${title})</h4>
                           ${environment.ERROR_DETAILS ? errInfo : ``}`;
        return errorHtml;
    }

    public static trimWhiteSpaces(str: string): string {
        let trimmedString = '';
        for (const char of str) {
            if (char !== ' ') {
                trimmedString += char;
            }
        }
        return trimmedString;
    }
}
