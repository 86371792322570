import { LeanplumCarouselSlide, setLeanplumCarouselData } from '../../store/ducks/leanplum/homepageCarousel';
import { setExpiredUserSubscriptions } from '../../store/ducks/subscription/common';
import { AppInsightService } from '../AppInsight';
import PaymentService from '../PaymentService';
import UserService from '../UserService';
import { deferCall, getLeanplum, LeanplumAnalytics } from './LeanplumAnalytics';
import { handleLeanplumMarketingActions } from './LeanplumAnalyticsMarketingActions';

export const lpAsyncSetUserAttributes = async () => await LeanplumAnalytics.setUserAttributes();
export const lpAsyncSetEmailConsent = async (emailConsent) =>
    deferCall(async () => {
        await LeanplumAnalytics.setEmailConsent(emailConsent);
        await LeanplumAnalytics.setUserAttributesCustom({ emailConsent });
    });
export const lpAsyncStartOrUpdateSession = async (
    dispatch,
    userUid,
    lpDelayWakeUp,
    setToastBannerData,
    setToastBannerOpen
) => {
    // We should call it anyway to base on user attributes on logout https://docs.leanplum.com/reference/user-ids#logouts
    await LeanplumAnalytics.start(userUid, lpDelayWakeUp, async () => {
        const Leanplum = getLeanplum();
        let homepageCarouselSlides: LeanplumCarouselSlide[] = [];
        const variables = Leanplum?.getVariables();
        for (const key in variables) {
            if (key.includes('homepageCarouselSlide')) {
                const index = parseInt(key.charAt(0));
                if (!isNaN(index) && index >= 0) {
                    homepageCarouselSlides[index] = variables[key];
                }
            }
        }
        //fallback to old leanplum carousel variable if no slides found
        if (!homepageCarouselSlides.length) {
            homepageCarouselSlides = variables?.homepageCarouselSlides || [];
        }
        handleLeanplumMarketingActions();
        dispatch(setLeanplumCarouselData(homepageCarouselSlides.filter((s) => s)));
        Leanplum?.on('showMessage', onShowMessage);
        async function onShowMessage({ message, context }) {
            const userAttributes = await LeanplumAnalytics.getUserAttributes();
            if (message.__name__ === 'Custom Popup' && message?.Layout?.isToastBanner) {
                const {
                    disclaimer,
                    tabletBackground,
                    mobileBackground,
                    ctaLabel,
                    ctaLink,
                    emailOptIn,
                    emailDisclaimer,
                    theme,
                } = message.Layout;
                setToastBannerData({
                    messageId: message.messageId,
                    title: message.Title,
                    subtitle: message.Content,
                    disclaimer,
                    desktopBackground: message.Image,
                    tabletBackground,
                    mobileBackground,
                    buttonLabel: ctaLabel,
                    buttonLink: ctaLink,
                    userEmail: userAttributes.user_email,
                    emailOptIn,
                    checkboxLabel: emailDisclaimer,
                    theme,
                    onClose: () => setToastBannerOpen(false),
                });
                setTimeout(() => {
                    context.track();
                    setToastBannerOpen(true);
                }, 10000);
            }
            return onShowMessage;
        }
    });
};
export const lpHandleSubscriptions = async (dispatch, userProcessed, expiredUserSubscriptions) => {
    const isUserLoggedIn = UserService.isUserLoggedIn();
    if (isUserLoggedIn && userProcessed && !expiredUserSubscriptions) {
        PaymentService.getExpiredSubscriptions()
            .then((res) => {
                dispatch(setExpiredUserSubscriptions(res));
            })
            .catch((err) => {
                console.error(' getExpiredSubscriptions', err);
                AppInsightService.trackAppError(err, { data: 'getExpiredSubscriptions()' });
            });
    }
};
