import React from 'react';

import { useSelector } from 'react-redux';

import {
    getFirstDateSubscription,
    getLastDateSubscription,
    getType,
    removeInternal,
} from '../../services/PaymentAndSubscriptionHelpers';
import { AvatarCardBlock } from '../../templates/Profile/Tabs/PaymentAndSubscription/AvatarCardBlock/AvatarCardBlock';
import { SubscriptionActions } from '../../templates/Profile/Tabs/PaymentAndSubscription/PaymentAndSubscription';
import { StatsBlock } from '../../templates/Profile/Tabs/PaymentAndSubscription/StatsBlock/StatsBlock';
import { StatusBlock } from '../../templates/Profile/Tabs/PaymentAndSubscription/StatusBlock/StatusBlock';
import styles from './AdvantageShopTab.css';

export const AdvantageShopTab = React.memo(() => {
    const activeSubscriptionFromRedux = useSelector((state) => state.activeUserSubscriptions);
    const activeSubscriptions = removeInternal(activeSubscriptionFromRedux);
    const expiredSubscriptionFromRedux = useSelector((state) => state.expiredUserSubscriptions || []);
    const expiredSubscriptions = removeInternal(expiredSubscriptionFromRedux);

    const type = getType(activeSubscriptions);

    let avatarCardBlockDate;
    switch (type) {
        case SubscriptionActions.RENEW:
            avatarCardBlockDate = getLastDateSubscription(activeSubscriptions).endDate;
            break;
        case SubscriptionActions.RESTART:
            avatarCardBlockDate = getLastDateSubscription(expiredSubscriptions).endDate;
            break;
        case SubscriptionActions.STANDART:
        case SubscriptionActions.SUBSCRIBED_BOTH:
        case SubscriptionActions.SUBSCRIBED_EXTERNAL:
        case SubscriptionActions.NONE:
        default:
            avatarCardBlockDate = getFirstDateSubscription(activeSubscriptions).startDate;
            break;
    }
    return (
        <div className={styles.container}>
            <AvatarCardBlock type={type} date={avatarCardBlockDate} />
            <StatsBlock type={type} />
            <StatusBlock
                date={getLastDateSubscription(activeSubscriptions).endDate}
                planId={getLastDateSubscription(activeSubscriptions).planId}
            />
        </div>
    );
});
