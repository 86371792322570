import React, { ReactNode, useEffect, useState } from 'react';

import { useSelector, connect } from 'react-redux';

import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { PlatformType } from '../../models/Subscription/SubscriptionData';
import PaymentService from '../../services/PaymentService';
import styles from './AdvantageTab.css';

type advantageTabProps = {
    advantageTabContent: ReactNode;
    subscription: boolean;
};

const AdvantageTabTest = ({ advantageTabContent, subscription }: advantageTabProps) => {
    //TODO: Check here subscription with useSelector - subscription is not changed if the state has been changed (in parent - header.tsx everything is ok)
    const activeUserSubscriptions = useSelector((state) => state.activeUserSubscriptions);
    const [advTabIsVisible, setAdvTabIsVisible] = useState(!subscription);
    const [advTabIsLoading, setAdvTabIsLoading] = useState(false);
    const renderAdvantageTab = () => {
        subscription ? setAdvTabIsVisible(false) : setAdvTabIsVisible(true);
        subscription && !activeUserSubscriptions && setAdvTabIsLoading(true);
        if (subscription && activeUserSubscriptions) {
            PaymentService.getLastDateSubscription(activeUserSubscriptions)?.platformId === PlatformType.INTERNAL
                ? setAdvTabIsVisible(true)
                : setAdvTabIsVisible(false);
            setAdvTabIsLoading(false);
        }
    };

    useEffect(() => {
        renderAdvantageTab();
    }, [subscription, activeUserSubscriptions]);
    return (
        <>
            {advTabIsVisible && advantageTabContent}
            {advTabIsLoading && <AppLoader className={styles.loader} />}
        </>
    );
};

export const AdvantageTab = connect((state) => ({
    subscription: state.preLoadData.subscription,
}))(AdvantageTabTest);
