import React, { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setSideMenuActivePage, setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';

export const RequestActivation = React.memo(() => {
    const [loading, setLoading] = useState(false);
    const [buttonCancelLabelKey, setButtonCancelLabelKey] = useState('CANCEL_BTN');
    const [buttonResendLabelKey, setButtonResendLabelKey] = useState('REGISTER_RESEND');

    const [disabledResend, setDisabledResend] = useState(false);
    const [message, setMessage] = useState('');

    const [emailWasResent, setEmailWasResent] = useState(false);

    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);

    // TODO: What it is?
    const authDataContext = useContext(AuthDataContext);
    const { authProvider } = authDataContext.value;

    const dispatch = useDispatch();

    const pressResendActivation = () => {
        setLoading(true);
        UserService.resendConfirmationEmail(values.email, captchaToken, authProvider || AuthType.EmailPassword)
            .then((res) => {
                setLoading(false);
                setDisabledResend(true);
                if (res === 200) {
                    setMessage(TranslationService.translateIt('REGISTER_THE_EMAIL_HAS_BEEN_SENT_AGAIN'));
                    setButtonResendLabelKey('REGISTER_OK');
                    setButtonCancelLabelKey('AD_DIVIDER_HELP_CAPTION');
                    setEmailWasResent(true);
                    Analytics.trackEvent(Analytics.profile.resendConfirmation());
                } else {
                    const mess = UserService.errorCodeToText(res);
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: mess,
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            })
            .finally(() => {
                setDisabledResend(false);
            });
    };

    const cancelBtn = () => {
        dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
    };

    const goToHelpTab = () => {
        dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
    };

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
            .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')),
    });

    const defaultValues = {
        email: '',
    };

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();

    return (
        <RightPanelWrapper headerLabelKey="RESEND_YOUR_CONFIRMATION_EMAIL">
            <I18nText as="div" className={styles.subHeader} keyName={'REGISTER_PLEASE_ENTER_THE_EMAIL'} />{' '}
            <form onSubmit={handleSubmit(pressResendActivation)} className={styles.formContent}>
                <div className={styles.inputRow}>
                    <Input
                        required
                        className={styles.input}
                        isValid={!errors.email}
                        ariaInvalid={errors.email ? 'true' : 'false'}
                        register={register}
                        name="email"
                        type="text"
                        maxLength={50}
                        placeholder={TranslationService.translateIt('REGISTER_ENTER_YOUR_EMAIL_ADDRESS')}
                        value={values.email}
                        showError={errors?.email}
                        errorMessage={errors?.email?.message ?? ''}
                        onChange={() => trigger('email')}
                    />
                </div>
                <div className={styles.receiveText}>{message}</div>
                <div className={styles.buttonsRow}>
                    <Button outlined onClick={emailWasResent ? goToHelpTab : cancelBtn}>
                        <I18nText keyName={buttonCancelLabelKey} />
                    </Button>
                    <Button
                        loading={loading}
                        onClick={emailWasResent ? cancelBtn : () => {}}
                        type="submit"
                        disabled={!values.email || disabledResend || !isValid}
                    >
                        <I18nText keyName={buttonResendLabelKey} />
                    </Button>
                </div>
            </form>
        </RightPanelWrapper>
    );
});
