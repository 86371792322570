import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents'; // TODO: We should Refactor this
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import UserService, { EmailCodes, ERRORS_DESCRIPTION } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import stylesSignUP from '../SignUp/SignUp.css';
import stylesConfirm from './ConfirmResetPassword.css';

export const ConfirmResetPassword = React.memo(() => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .required('Password is required')
            .matches(
                /^[A-Za-z0-9!@#$%^&*()_[\]{},.<>+=-]*$/,
                TranslationService.translateIt('REGISTER_YOUR_PASSWORD_CONTAINS')
            )
            .min(8)
            .matches(/(.*[A-Z].*)/, ' '),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], TranslationService.translateIt('REGISTER_SORRY_YOUR_PASSWORDS'))
            .required('Confirm password password is required'),
    });

    const passwordMinValidation = yup.string().min(8);
    const passwordUppercaseValidation = yup.string().matches(/(.*[A-Z].*)/);

    const defaultValues = {
        password: '',
        confirmPassword: '',
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();

    const { password } = values;

    const isPasswordEmpty = password.length === 0;

    const handleResetPassword = () => {
        const code = UrlService.getQSParam(window.location.search, EmailCodes.RESET_PASSWORD_CODE);
        if (code) {
            setLoading(true);
            UserService.changePasswordByRecoveryCode(password, code).then((res) => {
                setLoading(false);
                if (res === 200) {
                    Analytics.trackEvent(Analytics.profile.resetPassword('success'));
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: TranslationService.translateIt('REGISTER_RESET_PASSWORD_MESSAGE'),
                            type: 'success',
                            parentNode: 'rightSideMenu',
                        })
                    );
                    dispatch(setSignInUpState(SignInUpComponents.RESET_PASSWORD_SUCCESS));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
            });
        } else {
            dispatch(
                setSnackbarData({
                    isOpened: true,
                    message: ERRORS_DESCRIPTION.UserPasswordResetCodeIsInvalid,
                    type: 'error',
                    parentNode: 'rightSideMenu',
                })
            );
        }
    };

    const cleanField = () => {
        setValue('confirmPassword', '');
    };

    return (
        <RightPanelWrapper headerLabelKey="REGISTER_RESET_YOUR_PASSWORD">
            <form
                onSubmit={handleSubmit(handleResetPassword)}
                className={classNames(styles.formContent, stylesConfirm.formContainer)}
            >
                <div className={stylesConfirm.inputWrapper}>
                    <Input
                        required
                        className={styles.input}
                        isValid={!errors.password}
                        ariaInvalid={errors.password ? 'true' : 'false'}
                        register={register}
                        name="password"
                        type="password"
                        placeholder={TranslationService.translateIt('REGISTER_ENTER_YOUR_NEW_PASSWORD')}
                        value={values.password}
                        onFocus={() => {
                            cleanField();
                        }}
                    />

                    <div
                        className={classNames(stylesSignUP.validationMessage, styles.validationMessageCustom, {
                            [stylesSignUP.validationMessageError]:
                                !isPasswordEmpty && !passwordMinValidation.isValidSync(values.password),
                            [stylesSignUP.validationMessageOk]:
                                !isPasswordEmpty && passwordMinValidation.isValidSync(values.password),
                        })}
                    >
                        <span className={stylesSignUP.dot} />
                        <I18nText keyName={'REGISTER_AT_LEAST'} />
                        <span> 8 </span>
                        <I18nText keyName={'REGISTER_CHARS'} />
                    </div>
                    <div
                        className={classNames(
                            stylesSignUP.validationMessage,
                            styles.validationMessageCustom,
                            stylesConfirm.m30bottom,
                            {
                                [stylesSignUP.validationMessageError]:
                                    !isPasswordEmpty && !passwordUppercaseValidation.isValidSync(values.password),
                                [stylesSignUP.validationMessageOk]:
                                    !isPasswordEmpty && passwordUppercaseValidation.isValidSync(values.password),
                            }
                        )}
                    >
                        <span className={stylesSignUP.dot} />
                        <I18nText keyName={'REGISTER_UPPERCASE_LETTER'} />
                    </div>
                    <Input
                        required
                        className={classNames(styles.input)}
                        isValid={!errors.confirmPassword}
                        ariaInvalid={errors.confirmPassword ? 'true' : 'false'}
                        register={register}
                        name="confirmPassword"
                        type="password"
                        placeholder={TranslationService.translateIt('REGISTER_REENTER_YOUR_NEW_PASSWORD')}
                        value={values.confirmPassword}
                        showError={errors?.confirmPassword}
                        errorMessage={errors?.confirmPassword?.message ?? ''}
                    />
                </div>
                <div className={styles.buttonRow}>
                    <Button
                        className={styles.submitButton}
                        onClick={handleResetPassword}
                        type="submit"
                        disabled={
                            !isValid ||
                            values.password !== values.confirmPassword ||
                            !values.password ||
                            !values.confirmPassword ||
                            loading
                        }
                    >
                        <I18nText keyName={'REGISTER_SUBMIT'} />
                    </Button>
                </div>
            </form>
        </RightPanelWrapper>
    );
});
