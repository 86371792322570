import urlJoin from 'url-join';

import { storeOS } from './ConnectedApps';
import { GameAreaBackgroundColorTone, GameModel, Meta } from './GameModel';
import { environment } from '../../config/environment';
import { UrlService } from '../../services/UrlService';

export interface IGame {
    readonly rawGameData: GameModel;

    readonly alias: string;
    readonly slug: string;

    readonly name: string;
    readonly description: string;
    readonly locales: string[];
    readonly rules: string;
    readonly shortTitle: string;
    readonly fullTitle: string;
    readonly subTitle: string;
    readonly details: string;
    readonly gameReleaseDate: number;
    readonly meta: Meta;

    readonly backgroundColor: string;
    readonly webGameAreaBackgroundColorTone: GameAreaBackgroundColorTone;
    readonly isFlex: boolean;
    readonly isAdsFree: boolean;
    readonly isGrm: boolean;
    readonly isIframe: boolean;
    readonly aspectRatio: number;

    readonly externalConfigUrl: string;
    readonly sdkName: string;

    readonly gameAssetOriginUrl: string;
    readonly gameThumbPath: string;
    readonly version: string;
    readonly renderingType: 'JavaScript' | 'Html' | 'External' | 'Unity' | 'Construct';

    readonly metaHTMLTitle: string;
    readonly metaHTMLDescription: string;
    readonly categories: string[];

    readonly recommendedGames: string[];
    readonly hasCollection: string;
    readonly desktopRank: number;
    readonly mobileRank: number;
    readonly tabletRank: number;
    readonly finalUrl: string;
    readonly webGameAreaTopAssetLeft: string;
    readonly webGameAreaTopAssetRight: string;
    readonly orderByGameStarts: number;

    readonly pwaIcon512: string;
    readonly pwaIcon192: string;
    readonly pwaIcon180: string;
    readonly pwaIcon167: string;
    readonly pwaIcon152: string;
    readonly pwaIcon120: string;
    readonly pwaIcon76: string;

    readonly promoTile280x600: string;
    readonly promoTile760x312: string;
    readonly promoTile1000x312: string;
    readonly promoTile1000x312webp: string;
    readonly promoTile280x280: string;
    readonly promoTile384x216: string;

    readonly subscriberOnlyGame: string;
    readonly primaryCategory: string;
    readonly hasFrenchLocale: boolean;

    readonly appStore: storeOS[];

    promoTile(size: string, webp?: boolean, doubleSize?: boolean): string;
    updateVersion(version: string): void;
    updateGameAPI(gameAPI: string): void;
    updateGame(game: GameModel): void;
    getSeoTitle(): string;
    isFavorite?: boolean;
}

// we use getters for original game data properties since it has potential override for arkadium site
export class Game implements IGame {
    private gameData: GameModel;

    constructor(gameData: GameModel) {
        this.gameData = { ...(gameData || {}) } as GameModel;
    }

    get rawGameData(): GameModel {
        return this.gameData;
    }

    get alias(): string {
        return this.gameData.meta.alias;
    }

    get slug(): string {
        return this.gameData.slug;
    }

    get sdkName(): string {
        return this.gameData.sdkName;
    }

    get meta(): any {
        return this.gameData.meta;
    }

    get name(): string {
        const originalName = this.gameData.name;
        const overrideName = this.gameData.meta.name;
        return overrideName || originalName;
    }

    get description(): string {
        const originalDescription = this.gameData.description;
        const overrideDescription = this.gameData.meta.description;

        return overrideDescription || originalDescription || '[description]';
    }

    get locales(): string[] {
        try {
            return [this.gameData.meta.locale];
        } catch (e) {
            console.log(e);
        }
        return [];
    }

    get rules(): string {
        return this.gameData.meta.rules ?? '[rules]';
    }

    get shortTitle(): string {
        const gameName = this.gameData.name;
        const gameShortTitle = this.gameData.meta.name;
        return gameShortTitle || gameName || `[shortTitle]`;
    }

    get fullTitle(): string {
        const gameName = this.gameData.name;
        const gameFullTitle = this.gameData.meta.fullTitle;
        return gameFullTitle || gameName || `[fullTitle]`;
    }

    get subTitle(): string {
        const gameName = this.gameData.name;
        const gameSubTitle = this.gameData.meta.subtitle;
        return gameSubTitle || gameName || `[subTitle]`;
    }

    get details(): string {
        const gameDescription = this.gameData.meta.description;
        const gameDetails = this.gameData.meta.details;
        return gameDetails || gameDescription || `[details]`;
    }

    get gameReleaseDate(): number {
        return this.gameData.meta.gameReleaseDate ? new Date(this.gameData.meta.gameReleaseDate).getTime() : 0;
    }

    get backgroundColor(): string {
        const color = this.gameData.meta.backgroundColor;
        return color && color.length ? color : 'rgba(64, 43, 155, 1)';
    }

    get webGameAreaBackgroundColorTone(): GameAreaBackgroundColorTone {
        return GameAreaBackgroundColorTone.DARK;
    }

    get isFlex(): boolean {
        return this.gameData.meta.isFlex;
    }
    get isAdsFree(): boolean {
        return this.gameData.meta.isAdsFree;
    }

    get isGrm(): boolean {
        return this.gameData.meta.isGrm;
    }

    get isIframe(): boolean {
        return this.gameData.meta.isIframe;
    }

    get aspectRatio(): number {
        const ratio = this.gameData.meta.aspectRatio;
        return ratio ?? 1.33;
    }

    get externalConfigUrl(): string {
        return this.gameData.meta.externalConfigUrl;
    }

    get gameAssetOriginUrl(): string {
        if (!this.gameData.version) {
            throw new Error('Game object has insufficient data to build assets url');
        }
        if (!this.gameData.gameAssetOriginUrl) {
            this.gameData.gameAssetOriginUrl = `${environment.GAMES_CONTAINER_URL}/${
                this.gameData.sdkName || this.gameData.slug
            }/${this.gameData.version}/`;
            return this.gameData.gameAssetOriginUrl;
        }
        return this.gameData.gameAssetOriginUrl;
    }

    get gameThumbPath(): string {
        return this.gameData.meta.thumbs.thumbPath;
    }

    get version(): string {
        return this.gameData.version;
    }

    get hasCollection() {
        return this.gameData.meta.hasCollection;
    }

    get metaHTMLTitle(): string {
        return this.gameData.meta.htmlTitle ? this.gameData.meta.htmlTitle : this.name;
    }

    get metaHTMLDescription(): string {
        return this.gameData.meta.htmlDescription ? this.gameData.meta.htmlDescription : this.gameData.description;
    }

    get categories(): string[] {
        let originalCategories = [];
        if (this.gameData.tags) {
            try {
                originalCategories = JSON.parse(this.gameData.tags);
            } catch (e) {
                console.log(e);
            }
        }
        const overrideCategories = this.gameData.meta.categories;
        return !overrideCategories || overrideCategories.length === 0 ? originalCategories : overrideCategories;
    }

    get recommendedGames(): string[] {
        return this.gameData.analyticsMetaData.recommendedGames;
    }

    get desktopRank(): number {
        return this.gameData.analyticsMetaData.rank.desktopRank;
    }

    get mobileRank(): number {
        return this.gameData.analyticsMetaData.rank.mobileRank;
    }

    get tabletRank(): number {
        return this.gameData.analyticsMetaData.rank.tabletRank;
    }

    get finalUrl(): string {
        let arkSlug = this.gameData.meta.alias;
        arkSlug = arkSlug || 'test';
        return UrlService.createURL(`/games/${arkSlug}/`);
    }

    get webGameAreaTopAssetLeft(): string {
        if (!this.gameData.meta.thumbs.webGameAreaTopAssetLeft) {
            return `${environment.GAME_ARTS}/_shared_/default/left_600x296.png`;
        }
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.webGameAreaTopAssetLeft);
    }

    get webGameAreaTopAssetRight(): string {
        if (!this.gameData.meta.thumbs.webGameAreaTopAssetRight) {
            return `${environment.GAME_ARTS}/_shared_/default/right_600x296.png`;
        }
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.webGameAreaTopAssetRight);
    }

    get orderByGameStarts(): number {
        return this.gameData.orderByGameStarts;
    }

    get pwaIcon512(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon512);
    }

    get pwaIcon192(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon192);
    }

    get pwaIcon180(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon180);
    }

    get pwaIcon167(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon167);
    }

    get pwaIcon152(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon152);
    }

    get pwaIcon120(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon120);
    }

    get pwaIcon76(): string {
        return this.createMetaAssetUrl(this.gameData.meta.thumbs.pwaIcon76);
    }

    get promoTile760x312(): string {
        return this.createHardcodedMetaAssetsUrl('760x312');
    }

    get promoTile1000x312(): string {
        return this.createHardcodedMetaAssetsUrl('1000x312');
    }

    get promoTile1000x312webp(): string {
        return this.createHardcodedMetaAssetsUrl('1000x312', true);
    }

    get promoTile280x280(): string {
        return this.createHardcodedMetaAssetsUrl('280x280');
    }

    get promoTile384x216(): string {
        return this.createHardcodedMetaAssetsUrl('384x216');
    }

    get promoTile280x600(): string {
        return this.createHardcodedMetaAssetsUrl('280x600');
    }

    get subscriberOnlyGame(): string {
        return this.gameData.meta.subscriberOnlyGame;
    }

    get primaryCategory(): string {
        return this.gameData.meta.primaryCategory?.replace(/(^\w)|(\s+\w)/g, (letter) => letter.toUpperCase());
    }

    get appStore(): storeOS[] {
        return this.gameData.meta.appStore;
    }

    get renderingType(): 'JavaScript' | 'Html' | 'External' | 'Unity' | 'Construct' {
        return this.gameData.renderingType;
    }

    get hasFrenchLocale(): boolean {
        return this.gameData.hasFrenchLocale;
    }

    createHardcodedMetaAssetsUrl(size: string, webp?: boolean, doubleSize?: boolean): string {
        const folder = webp ? 'webp' : 'jpeg';
        const extension = webp ? 'webp' : 'jpg';
        const finalSize = doubleSize ? `${size}_x2` : size;
        const base = `${environment.GAME_ARTS}/${this.slug}/Game_Tiles/${folder}`;
        return urlJoin(base, `${finalSize}.${extension}`);
    }

    promoTile(size: string, webp: boolean, doubleSize: boolean) {
        return this.createHardcodedMetaAssetsUrl(size, webp, doubleSize);
    }

    updateVersion(version: string) {
        if (!this.sdkName || !this.version) {
            throw new Error('Game object has insufficient data to build assets url');
        }
        this.gameData.version = version;
        this.gameData.gameAssetOriginUrl = `${environment.GAMES_CONTAINER_URL}/${this.sdkName}/${this.version}/`;
    }

    updateGameAPI(gameAPI: string) {
        this.gameData.gameAssetOriginUrl = gameAPI;
    }

    updateGame(game: GameModel): void {
        this.gameData = game;
    }

    getSeoTitle(): string {
        return this.gameData.meta.seoTitle || this.gameData.meta.name || this.gameData.name || `[seoTitle]`;
    }

    private createMetaAssetUrl(path: string): string {
        const base = environment.GAME_METAS;
        return path ? urlJoin(base ?? '', path ?? '') : '';
    }
}
