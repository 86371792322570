import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import styles from './SignIn.css';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { LS_COOKIE_CONSTS } from '../../../../models/Enums';
import { IGame } from '../../../../models/Game/Game';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import { Divider } from '../../../../molecules/Divider/Divider';
import AdFreeService from '../../../../services/AdFreeService';
import adFreeService from '../../../../services/AdFreeService';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../../../services/AppInsight';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import CollectionsService from '../../../../services/CollectionsService';
import { CookieService } from '../../../../services/CookieService';
import { GameService } from '../../../../services/GameService';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { LocalStorageService } from '../../../../services/LocalStorage';
import PaymentService from '../../../../services/PaymentService';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import UserService, { AuthType, EmailCodes } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { activePageSelector } from '../../../../store/ducks/layoutSelectors';
import { setPreLoadData } from '../../../../store/ducks/preLoadData';
import { setActiveUserSubscriptions } from '../../../../store/ducks/subscription/common';
import { GemsEffects } from '../../../../store/effects/gems.effects';
import { SocialButtons } from '../../../SocialButtons/SocialButtons';
import stylesCommon from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';

type SignInProps = {
    onSignInUpFinished: () => unknown;
};

const REGISTER_DONT_HAVE_PROFILE_BELOW = 'If you don’t have a profile yet, we will create one for you.';
const SIGN_IN_INPUT_PLACEHOLDER_EMAIL = 'Enter your email address';
const SIGN_IN_INPUT_PLACEHOLDER_PASSWORD = 'Enter or create your password';

const SignIn = React.memo(({ onSignInUpFinished }: SignInProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const categoryPageName = useSelector((state) => state.categoryPageName);
    const gameArkadiumSlug = useSelector((state) => state.gameArkadiumSlug);
    const games = useSelector((state) => state.games);
    const socialRegistrationLocation = useSelector((state) => state.socialRegistrationLocation);
    const currentTab = useSelector(activePageSelector);
    const authDataContext = useContext(AuthDataContext);
    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.SIGN_IN);
    const [checkEmailcaptchaToken, updateCheckEmailCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.CHECK_EMAIL);

    const confirmSignUpCopy = useSelector((state) => state.signInUpState.copyConfirmation);

    const headerLabelKey =
        currentTab === HeaderSideMenuTabs.SHOP_TAB
            ? 'REGISTER_SIGN_IN_FOR_ACCESS_TO_GEMS_SHOP'
            : confirmSignUpCopy
            ? 'REGISTER_SIGN_IN_ARKADIUM_PROFILE_2'
            : 'REGISTER_SIGN_IN_ARKADIUM_PROFILE';

    const game = gameArkadiumSlug
        ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
        : '';
    const emailValidation = yup.string().required().email();

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
            .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')),
        password: yup.string().required('Password is required'),
    });

    const defaultValues = {
        email: authDataContext.value.email || '',
        password: authDataContext.value.password || '',
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();
    const { email, password } = values;


    const onSubmit = () => {
        Analytics.trackEvent(Analytics.profile.loginClick(categoryPageName, game as IGame, RegistrationSource.EMAIL));
        // TODO: set auth data to save it if user came back from resend screens etc.

        setLoading(true);
        UserService.userLogin(email, password, captchaToken)
            .then((res) => {
                setLoading(false);
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
                    UserService.resetPurchaseFlow();
                    onSignInUpFinished();
                    Analytics.trackEvent(
                        Analytics.profile.loginSuccess(
                            categoryPageName,
                            game,
                            socialRegistrationLocation,
                            RegistrationSource.EMAIL
                        )
                    );
                    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.LOGIN_BTN, { action: 'login' });
                    // check subscriptions to set adFree status
                    PaymentService.getSubscriptions()
                        .then((res) => {
                            if (res.length > 0) {
                                dispatch(setActiveUserSubscriptions(res));
                                //Check subscription type

                                const isUserSubscriber = UserService.isUserSubscriber();
                                const isUserBonusOnly = UserService.isUserHasBonusOnly();

                                CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, isUserBonusOnly);
                                CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, isUserSubscriber);

                                if (isUserSubscriber) {
                                    CookieService.setArkCookie(
                                        LS_COOKIE_CONSTS.SUBSCRIPTION_ID,
                                        UserService.getSubscriptionId(),
                                        30
                                    );
                                }

                                dispatch(
                                    setPreLoadData({
                                        adFree: adFreeService.adFreeTurnOffFilter(isUserBonusOnly),
                                        subscription: isUserSubscriber,
                                    })
                                );
                                AdFreeService.vignetteAdsFix();
                            }
                        })
                        .catch((err) => {
                            AppInsightService.trackAppError(err, { data: 'userLogin()' });
                        });
                    CollectionsService.synchronizeAnonymousUserCollections().catch((err) => {
                        AppInsightService.trackAppError(err, { data: 'synchronizeAnonymousUserCollections()' });
                    });
                } else {
                    if (
                        UserService.errorCodeToEnum(res) === 'UserNotConfirmedError' ||
                        UserService.errorCodeToEnum(res) === 'UserEmailConfirmationCodeIsInvalid'
                    ) {
                        authDataContext.setAuthData({
                            ...authDataContext.value,
                            email,
                            authProvider: AuthType.EmailPassword,
                        });
                        dispatch(setSignInUpState(SignInUpComponents.REQUEST_ACTIVATION));
                    } else {
                        dispatch(
                            setSnackbarData({
                                isOpened: true,
                                message: UserService.errorCodeToText(res),
                                type: 'error',
                                parentNode: 'rightSideMenu',
                            })
                        );
                    }
                }
                updateCaptchaToken(res !== 200);
            })
            .then(() => dispatch(GemsEffects.UpdateGemsAmount()));
    };

    const getCopyBelow = () => {
        return (
            <p>
                <strong>{REGISTER_DONT_HAVE_PROFILE_BELOW}</strong>
            </p>
        );
    };

    useEffect(() => {
        Analytics.trackEvent(Analytics.profile.loginImpression(categoryPageName, game as IGame));
        // if there is a query parameter with confirmation code to confirm the email
        const confirmationCode = UrlService.getQSParam(window.location.search, EmailCodes.CONFIRMATION_CODE);
        if (confirmationCode) {
            setLoading(true);
            UserService.confirmUser(confirmationCode).then((res) => {
                setLoading(false);
                if (res === 200) {
                    const isWebviewMessage = UrlService.getQSParam(window.location.search, 'webview');
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: isWebviewMessage
                                ? t('REGISTER_CONFIRM_MAIL_MESSAGE_WEBVIEW')
                                : t('REGISTER_CONFIRM_MAIL_MESSAGE'),
                            type: 'success',
                            parentNode: 'rightSideMenu',
                        })
                    );
                    // To have LP userAttribute {registered: true} set here we don't have final Eagle user id, just code (!== id)
                } else {
                    dispatch(setSignInUpState(SignInUpComponents.REQUEST_ACTIVATION));
                }
                window.history.replaceState(null, '', encodeURI(UserService.generateUrl()));
            });
        }
    }, []);

    return (
        <RightPanelWrapper
            headerLabelKey={headerLabelKey}
            topBlock={
                <img
                    style={{ marginBottom: 20 }}
                    src={`${UrlService.toCDNUrl('/images/signUpHeader_2.png')}`}
                    alt="sign_up_avatars"
                />
            }
            fullWidth
        >
            <div className={stylesCommon.subHeader}>
                <I18nText keyName={confirmSignUpCopy ? 'REGISTER_DONT_HAVE_PROFILE_2' : 'REGISTER_DONT_HAVE_PROFILE'} />
                {getCopyBelow()}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={stylesCommon.formContent} style={{ marginTop: '30px' }}>
                <div className={styles.inputWrapper}>
                    <Input
                        required
                        className={stylesCommon.input}
                        isValid={!errors.email}
                        ariaInvalid={errors.email ? 'true' : 'false'}
                        register={register}
                        name="email"
                        type="text"
                        placeholder={SIGN_IN_INPUT_PLACEHOLDER_EMAIL}
                        value={email}
                        showError={errors?.email}
                        errorMessage={errors?.email?.message ?? ''}
                        onBlur={() => {
                            if (emailValidation.isValidSync(email)) {
                                setLoading(true);
                                UserService.getUserByEmail(email, checkEmailcaptchaToken)
                                    .then((data) => {
                                        setLoading(false);
                                        // set the data (name/email/avatar) to show it on the COMPLETE_SOCIAL_PROFILE screen
                                        authDataContext.setAuthData({ ...authDataContext.value, ...data });

                                        // if the user is registered through social networks,
                                        // but he has not password, then we show the COMPLETE_SOCIAL_PROFILE screen
                                        const authProvider = data.auth_provider_id[0];
                                        if (authProvider && !data.hasPassword) {
                                            dispatch(setSignInUpState(SignInUpComponents.COMPLETE_SOCIAL_PROFILE));
                                        }
                                    })
                                    // if the email does not exist, then show the sign up screen
                                    .catch(() => {
                                        setLoading(false);
                                        // set email to put this on the SIGN_UP screen
                                        authDataContext.setAuthData({ ...authDataContext.value, email });
                                        dispatch(setSignInUpState(SignInUpComponents.SIGN_UP));
                                    });
                            }
                        }}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <Input
                        required
                        className={stylesCommon.input}
                        isValid={!errors.password}
                        ariaInvalid={errors.password ? 'true' : 'false'}
                        register={register}
                        name="password"
                        type="password"
                        placeholder={SIGN_IN_INPUT_PLACEHOLDER_PASSWORD}
                        value={password}
                        showError={errors?.password}
                        errorMessage={errors?.password?.message ?? ''}
                    />
                </div>
                <Button
                    isLink
                    className={styles.forgotPassword}
                    onClick={() => dispatch(setSignInUpState(SignInUpComponents.RESET_PASSWORD))}
                    type="button"
                >
                    <I18nText keyName="REGISTER_FORGOT_PASSWORD" as="span" />
                </Button>
                <Button type="submit" disabled={!captchaToken} className={styles.submitButton} loading={loading}>
                    <I18nText keyName="Submit" as="span" />
                </Button>
                <Divider className={styles.dividerBlock} />
                <SocialButtons
                    className={styles.socialButtons}
                    signIn
                    game={game}
                    category={categoryPageName}
                    errorMessageSocial={(res) => {
                        dispatch(
                            setSnackbarData({
                                isOpened: true,
                                message: UserService.errorCodeToText(res),
                                type: 'error',
                                parentNode: 'rightSideMenu',
                            })
                        );
                    }}
                />
                <div className={styles.policy}>
                    <a target="_blank" href={UrlService.createURL('/privacy-policy/')} rel="noreferrer noopener">
                        <I18nText keyName={'REGISTER_PRIVACY_POLICY'} />
                    </a>
                </div>
            </form>
        </RightPanelWrapper>
    );
});
SignIn.displayName = 'SignIn';
export default SignIn;
