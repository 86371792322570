import React from 'react';

import classNames from 'classnames';

import { I18nText } from '../../i18nText/i18nText';
import styles from './BorderedButton.css';

type BorderedButtonProps = {
    caption: string;
    focusable?: boolean;
    disabled?: boolean;
    submit?: boolean;
    filled?: boolean;
    onClick?: (e) => void;
    className?: string;
    dataElementDescription?: string;
};

export const BorderedButton = React.memo(
    ({
        caption,
        focusable = true,
        submit,
        filled,
        disabled,
        onClick,
        className,
        dataElementDescription,
    }: BorderedButtonProps) => {
        return (
            <button
                type={submit ? 'submit' : 'button'}
                className={classNames(
                    styles.borderedButton,
                    {
                        [styles.filled]: filled,
                    },
                    className
                )}
                disabled={disabled}
                onClick={onClick}
                data-element-description={dataElementDescription}
                tabIndex={focusable ? 0 : -1}
            >
                <I18nText keyName={caption} className={styles.caption} />
            </button>
        );
    }
);
