import parser, { UAParser } from 'ua-parser-js';

export enum DeviceType {
    DESKTOP = 'pc',
    TABLET = 'tablet',
    MOBILE = 'phone',
}
export interface BrowserInfo {
    name: string;
    version: string;
    major: string;
}

export interface OSInfo {
    name: string;
    version: string;
}

export class DeviceDetector {
    private p: UAParser;

    constructor() {
        this.p = new parser.UAParser();
    }

    public DetectDevice(): DeviceType {
        const deviceInfo = this.p.getDevice();
        switch (deviceInfo.type) {
            case 'mobile':
                return DeviceType.MOBILE;
            case 'tablet':
                return DeviceType.TABLET;
            default:
                return DeviceType.DESKTOP;
        }
    }

    public DetectBrowser(): BrowserInfo {
        const browserInfo = this.p.getBrowser();
        const result: BrowserInfo = {
            name: browserInfo.name,
            version: browserInfo.version,
            major: browserInfo.major,
        };
        return result;
    }

    public DetectOS(): OSInfo {
        const osInfo = this.p.getOS();
        const result: OSInfo = {
            name: osInfo.name,
            version: osInfo.version,
        };
        return result;
    }

    static getDeviceType(req): DeviceType {
        const PlatformTypeQueryKey = 'ark__platformtype';
        let platform = req.query[PlatformTypeQueryKey] || DeviceType.DESKTOP;

        if (platform === 'phone' || platform === 'mobile') {
            platform = DeviceType.MOBILE;
        }

        return platform;
    }
}
