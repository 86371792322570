import Fuse from 'fuse.js';

import { IGame } from '../models/Game/Game';

export class SearchService {
    public static prepareSearchParam(searchParam: string): string {
        return (searchParam || '').toString().toLowerCase().trim();
    }

    public static searchGames(searchedTerm = ''): IGame[] {
        searchedTerm = this.prepareSearchParam(searchedTerm);
        const fuseSearchResults: Fuse.FuseResult<IGame>[] = SearchService.fuse.search(searchedTerm);
        const gameSearchResults: IGame[] = fuseSearchResults.map((fuseResultItem) => fuseResultItem.item);
        return gameSearchResults;
    }

    public static fuse: Fuse<IGame> = null;

    public static initFuse(games: IGame[]) {
        this.fuse = new Fuse(games, {
            keys: ['shortTitle', 'fullTitle', 'categories'],
        });
    }
}
