import { createAction, createReducer } from 'redux-act';

type authErrorsProps = {
    email: string;
    password: string;
    checkbox: string;
};

export const setAuthErrors = createAction<authErrorsProps>('set auth errors');
export const authErrorsReducer = createReducer<authErrorsProps>({}, { email: '', password: '', checkbox: '' });
authErrorsReducer.on(setAuthErrors, (_state, payload) => payload);

type authValuesProps = {
    email: string;
    password: string;
    checkbox: boolean;
};

export const setAuthValues = createAction<authValuesProps>('set auth values');
export const authValuesReducer = createReducer<authValuesProps>({}, { email: '', password: '', checkbox: true });
authValuesReducer.on(setAuthValues, (_state, payload) => payload);

export const setIsUserExists = createAction<boolean>('set auth values');
export const isUserExistsReducer = createReducer<boolean>({}, true);
isUserExistsReducer.on(setIsUserExists, (_state, payload) => payload);
