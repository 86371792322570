import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { StringUtils } from '../../../utils/StringUtils';
import { Button } from '../../atoms/Buttons/Base/Base';
import { ButtonWithIcon, ButtonWithIconTypes } from '../../atoms/Buttons/ButtonWithIcon/ButtonWithIcon';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { EarlyAccessIcon } from '../../atoms/Icons/Styleguide/EarlyAccessIcon';
import { NavLink } from '../../atoms/Link/Link';
import { ImageTypes, LazyImage } from '../../atoms/ProgressiveImage/LazyImage';
import { defaultSupportedLocales, disabledCardDescriptionsLocales } from '../../constants/Locales';
import { PageTypes } from '../../constants/Pages';
import { FavoritesAnalyticsClickPageTypes } from '../../features/Favorites/FavoritesAnalyticsAi';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import styles from './GameCard.css';

export enum GameCardTypes {
    SMALL = 'small',
    MEDIUM = 'medium',
    MEDIUM_PLUS = 'mediumPlus',
    LARGE = 'large',
    SUPER_LARGE = 'superLarge',
    DETAILED = 'detailed',
    LONG = 'long',
    SQUARE = 'square',
}

type GameCardProps = {
    gameCardType: GameCardTypes;
    onClick?: (game: IGame) => void;
    allGamesTile?: boolean;
    disableDescription?: boolean;
    disableTitle?: boolean;
    game?: IGame;
    previousGame?: string;
    dataElementDescription?: string;
    noProgress?: boolean;
    pseudoTile?: boolean;
    headerLink?: string;
    onSeeAllClick?: () => void;
    isCobrandedLP?: boolean;
    isFavorite?: boolean;
    favoritePageType?: FavoritesAnalyticsClickPageTypes;
    doubleSize?: boolean;
    variation?: string;
};

const GameCardSizeMap = {
    [GameCardTypes.DETAILED]: '280x280',
    [GameCardTypes.SMALL]: '280x280',
    [GameCardTypes.MEDIUM]: '280x280',
    [GameCardTypes.MEDIUM_PLUS]: '280x280',
    [GameCardTypes.LARGE]: '280x280',
    [GameCardTypes.SUPER_LARGE]: '280x600',
    [GameCardTypes.SQUARE]: '280x600',
    [GameCardTypes.LONG]: '384x216',
};

export const GameCard = React.memo((props: GameCardProps) => {
    const {
        game,
        previousGame,
        allGamesTile,
        gameCardType,
        pseudoTile,
        onSeeAllClick,
        disableDescription,
        dataElementDescription,
        headerLink,
        noProgress,
        onClick,
        isFavorite,
        favoritePageType,
        doubleSize,
        variation,
    } = props;

    const [isDescriptionDisabled, setIsDescriptionDisabled] = useState(disableDescription);

    useEffect(() => {
        if (disabledCardDescriptionsLocales.find((l) => StringUtils.equalIgnoreCase(l, UrlService.currentLang))) {
            setIsDescriptionDisabled(true);
        }
        if (gameCardType === GameCardTypes.SQUARE || pseudoTile) {
            setIsDescriptionDisabled(true);
        }
    }, [disableDescription, gameCardType, pseudoTile]);

    if (!allGamesTile && !game) {
        return (
            <Button
                onDragStart={() => false}
                draggable={false}
                className={classNames(styles.gameCard, styles[gameCardType])}
                data-element-description={dataElementDescription}
                noPadding
            >
                <div>You should either set 'allGamesTile' or 'game' prop to use GameCard component.</div>
            </Button>
        );
    }

    if (game && game.name === null) {
        return null;
    }

    const cardImageSrc = allGamesTile ? undefined : game?.promoTile(GameCardSizeMap[gameCardType], false, doubleSize);

    const cardImageSrcWebP = allGamesTile
        ? undefined
        : game?.promoTile(GameCardSizeMap[gameCardType], true, doubleSize);

    const cardImageAlt = allGamesTile ? '' : game?.name;

    const prevGame = previousGame ? `/?recPreviousGame=${previousGame}` : '';

    const cardHref = () => {
        if (allGamesTile)
            return UrlService.createURL(
                `/${UrlService.getPageLocalizedRoute(UrlService.currentLang, PageTypes.Category)}/`
            );
        if (pseudoTile) return UrlService.createURL(`/${headerLink}`);
        return UrlService.createGameURL(game?.alias!, prevGame);
    };

    const allGamesIllustration = UrlService.toCDNUrl('/illustrations/header/illustrations-all-games.png');
    const allGamesIllustrationWebp = UrlService.toCDNUrl('/illustrations/header/illustrations-all-games.webp');

    const renderGameCardInfo = () => {
        if (!pseudoTile && gameCardType !== GameCardTypes.LONG) return <GameCardInfo {...props} />;
    };

    const isEarlyAccessOnly = () => {
        const { subscriberOnlyGame } = game || {};
        return subscriberOnlyGame && moment(subscriberOnlyGame).isAfter(moment());
    };

    // favorites click handling, AI
    const handleFavoriteGameClick = () => {
        if (game?.isFavorite && favoritePageType) {
            Analytics.trackEvent(Analytics.favorites.clickFavoriteGameTile({ game, pageType: favoritePageType }));
        }
    };

    return (
        <div data-variations={variation}>
            <NavLink
                onDragStart={() => false}
                draggable={false}
                onClick={() => {
                    handleFavoriteGameClick();
                    game && onClick && onClick(game);
                    pseudoTile && onSeeAllClick && onSeeAllClick();
                }}
                to={cardHref()}
                className={classNames(styles.gameCard, styles[gameCardType], {
                    [styles.allGamesTile]: allGamesTile,
                })}
                data-element-description={dataElementDescription}
                suppressHydrationWarning
                aria-label={cardImageAlt}
            >
                <div className={styles.gameCardWrapper}>
                    {renderGameCardInfo()}
                    <div
                        className={classNames(
                            styles.imageContainer,
                            isEarlyAccessOnly() && !allGamesTile && !pseudoTile && styles.earlyAccessContainer
                        )}
                    >
                        <div className={styles.imageOverlay} />
                        {allGamesTile && (
                            <div className={classNames(styles.image, styles.allGamesTileBg)} suppressHydrationWarning />
                        )}
                        {pseudoTile && (
                            <picture>
                                <source
                                    srcSet={`/pseudo_tile_${GameCardSizeMap[gameCardType]}.webp`}
                                    type="image/webp"
                                />
                                <img
                                    className={classNames(styles.image)}
                                    src={`/pseudo_tile_${GameCardSizeMap[gameCardType]}.webp`}
                                    alt="Explore this category"
                                    suppressHydrationWarning
                                />
                            </picture>
                        )}
                        {noProgress && !allGamesTile && !pseudoTile && (
                            <picture>
                                <source srcSet={cardImageSrcWebP} type="image/webp" />
                                <img
                                    className={classNames(styles.image)}
                                    src={cardImageSrc}
                                    alt={cardImageAlt}
                                    suppressHydrationWarning
                                />
                            </picture>
                        )}
                        {!noProgress && !allGamesTile && !pseudoTile && (
                            <LazyImage
                                imageType={ImageTypes.SQUARE}
                                img={cardImageSrc}
                                webp={cardImageSrcWebP}
                                className={classNames(styles.image)}
                                alt={cardImageAlt}
                                loader={true}
                            />
                        )}
                        {allGamesTile && (
                            <>
                                <LazyImage
                                    imageType={ImageTypes.SQUARE}
                                    img={allGamesIllustration}
                                    webp={allGamesIllustrationWebp}
                                    className={styles.allGamesTileBgImage}
                                    alt="All games"
                                    aria-hidden="true"
                                    loader={true}
                                />
                                <ButtonWithIcon
                                    className={styles.allGamesButton}
                                    buttonType={ButtonWithIconTypes.SECONDARY}
                                    caption="SEE_ALL_GAMES"
                                />
                            </>
                        )}
                        {isEarlyAccessOnly() && !allGamesTile && !pseudoTile && (
                            <div className={styles.earlyAccessBadge}>
                                <I18nText className={styles.earlyAccessText} keyName="EARLY_ACCESS" />
                                <EarlyAccessIcon className={styles.earlyAccessIcon} />
                            </div>
                        )}
                        {isFavorite && !allGamesTile && !pseudoTile && <div className={styles.favoriteBadge}></div>}
                        {gameCardType !== GameCardTypes.DETAILED && !isDescriptionDisabled && (
                            <div className={styles.hiddenDescription}>
                                <div
                                    className={classNames(
                                        styles.hiddenDescriptionWrapper,
                                        isEarlyAccessOnly() && styles.additionalPadding
                                    )}
                                    role="tooltip"
                                >
                                    <p className={styles.hiddenDescriptionGameName}>{game?.name}</p>
                                    <p className={styles.hiddenDescriptionContent}>{game?.details}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </NavLink>
        </div>
    );
});

const GameCardInfo = React.memo(({ game, gameCardType, disableTitle }: GameCardProps) => {
    const limitCharacters = !defaultSupportedLocales
        .concat('en')
        .find((l) => StringUtils.equalIgnoreCase(l, UrlService.currentLang));

    return (
        <div className={styles.info}>
            {gameCardType !== GameCardTypes.SQUARE && !disableTitle && (
                <h3
                    className={classNames(styles.caption, {
                        [styles.limitCharacters]: limitCharacters,
                    })}
                    suppressHydrationWarning
                >
                    {game?.shortTitle}
                </h3>
            )}
            {gameCardType === GameCardTypes.DETAILED && (
                <>
                    <p className={styles.description}>{game?.details}</p>
                    <p className={styles.tags}>
                        <span>{game?.subTitle}</span>
                    </p>
                </>
            )}
        </div>
    );
});
