import React, { useCallback, useEffect, useState } from 'react';

import { RecurlyItemDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/recurly-data.dto';
import { PurchasableItemDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item.dto';
import { useDispatch } from 'react-redux';

import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { GEMS_TEST_SKU } from '../../constants/GemsConstants';
import { gemsSkuList } from '../../constants/RecurlyGemPackItems';
import { GemsTabAdvantagePromo } from '../../FigmaStyleguide/GemsTabAdvantagePromo/GemsTabAdvantagePromo';
import { GemCardProps } from '../../molecules/Cards/GemCard/GemCard';
import GemsService from '../../services/GemsService';
import PaymentService from '../../services/PaymentService';
import UserService from '../../services/UserService';
import { setSideMenuOpened } from '../../store/ducks/layout';
import { GemsCardsList } from '../GemsCardsList/GemsCardsList';
import { GemShopErrorView } from '../GemShopErrorView/GemShopErrorView';
import styles from './GemsShopLoggedInView.css';

const enum STATUSES {
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    CONTENT_READY = 'CONTENT_READY',
}

export const GemsShopLoggedInView = React.memo(() => {
    const dispatch = useDispatch();

    const [componentStatus, setComponentStatus] = useState<STATUSES>(STATUSES.LOADING);
    const [gemCardsData, setGemCardsData] = useState<GemCardProps[]>([]);

    const isSubscriber = UserService.isUserSubscriber();

    const closeSidePanel = useCallback(() => dispatch(setSideMenuOpened(false)), [dispatch]);

    const setUpGemCardsData = useCallback(async () => {
        setComponentStatus(STATUSES.LOADING);

        try {
            const [gemsServiceData, paymentServiceData] = await Promise.all([
                GemsService.getPurchasableItemsBySkuList(gemsSkuList),
                PaymentService.getPurchasableItems(gemsSkuList),
            ]);

            const gemCardsData = calculateGemsCardData(gemsServiceData, paymentServiceData, isSubscriber);

            setGemCardsData(gemCardsData);
            setComponentStatus(STATUSES.CONTENT_READY);
        } catch (err) {
            console.error(err);
            setComponentStatus(STATUSES.ERROR);
        }
    }, [isSubscriber]);

    useEffect(() => {
        setUpGemCardsData();
    }, [setUpGemCardsData]);

    return (
        <div className={styles.container}>
            {componentStatus === STATUSES.LOADING && (
                <>
                    <GemsTabAdvantagePromo />
                    <div className={styles.loaderWrapper}>
                        <AppLoader />
                    </div>
                </>
            )}
            {componentStatus === STATUSES.CONTENT_READY && (
                <>
                    <GemsTabAdvantagePromo />
                    <GemsCardsList data={gemCardsData} />
                </>
            )}
            {componentStatus === STATUSES.ERROR && (
                <div className={styles.errorViewWrapper}>
                    <GemShopErrorView tryAgainHandler={setUpGemCardsData} goBackHandler={closeSidePanel} />
                </div>
            )}
        </div>
    );
});
GemsShopLoggedInView.displayName = 'GemsShopLoggedInView';

function calculateGemsCardData(
    gemsServiceData: PurchasableItemDto[],
    paymentServiceData: RecurlyItemDto[],
    isSubscriber: boolean
): GemCardProps[] {
    const gemCardsData = gemsServiceData
        .map((gemsServiceDataItem) => {
            // TODO: need to handle special marks later
            // const { sku, image, items, specialMarks } = gemsServiceData[index];
            const RECURLY_ERR_MESSAGE = 'Recurly data are incorrect';
            const EAGLE_ERR_MESSAGE = 'Eagle data are incorrect';

            const { sku, image, items } = gemsServiceDataItem;
            const gemsAmountInPack = items.find(({ sku }) => sku === GEMS_TEST_SKU)?.amount;

            if (gemsAmountInPack === undefined) {
                throw new Error(EAGLE_ERR_MESSAGE);
            }

            const paymentServiceDataItem = paymentServiceData.find(({ code }) => code === sku);

            if (paymentServiceDataItem === undefined) {
                throw new Error(RECURLY_ERR_MESSAGE);
            }

            const { currencies, discountCurrencies } = paymentServiceDataItem;

            let oldPrice;
            let finalPrice;

            const priceWithoutDiscount = currencies.find(({ currency }) => currency === 'USD')?.unitAmount;
            const withDiscount = Boolean(discountCurrencies.length);

            if (priceWithoutDiscount === undefined) {
                throw new Error(RECURLY_ERR_MESSAGE);
            }

            finalPrice = priceWithoutDiscount;

            if (isSubscriber && withDiscount) {
                finalPrice = discountCurrencies.find(({ currency }) => currency === 'USD')?.unitAmount;

                if (finalPrice === undefined) {
                    throw new Error(RECURLY_ERR_MESSAGE);
                }

                oldPrice = priceWithoutDiscount;
            }

            const cardData: GemCardProps = {
                gemPackId: sku,
                gemsAmount: gemsAmountInPack,
                imageSrc: image,
                price: finalPrice,
                oldPrice: oldPrice,
                // TODO: need to handle special marks later
                // ribbon: specialMarks,
            };

            return cardData;
        })
        .sort((cardDataA, cardDataB) => cardDataB.gemsAmount - cardDataA.gemsAmount);

    return gemCardsData;
}
