import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Avatar, AvatarTypes, BorderTypes } from '../../../../../atoms/Avatar/Avatar';
import { SubscriptionPlans } from '../../../../../constants/SubscriptionPlan';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { ArkadiumAdvantageLogo } from '../../../../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { SubscriptionSource } from '../../../../../models/Subscription/SubscriptionData';
import { AppInsightService } from '../../../../../services/AppInsight';
import PaymentService from '../../../../../services/PaymentService';
import UserService from '../../../../../services/UserService';
import { setSubscriptionPlans, setSubscriptionSource } from '../../../../../store/ducks/subscription/common';
import { SubscriptionActions } from '../PaymentAndSubscription';
import styles from './JoinBlock.css';

type JoinBlockProps = {
    type: SubscriptionActions;
};

export const JoinBlock: FC<JoinBlockProps> = React.memo(({ type }: JoinBlockProps) => {
    const user = useSelector((state) => state.user);
    const plansData = useSelector((state) => state.subscriptionPlans);
    const [plans, setPlans] = useState(plansData || []);
    const userAvatar = user?.avatar || user?.subscriberAvatar?.avatar || '';
    const dispatch = useDispatch();

    useEffect(() => {
        if (!plansData) {
            getSubscriptionPlans(dispatch, setPlans);
        }
    }, [plansData]);

    function getUnitAmountMonthly(plansArray) {
        let result: number;
        plansArray.forEach((plan) => {
            if (plan.code === SubscriptionPlans.ANNUAL) {
                const planFullPrice = plan?.currencies[0]?.unitAmount;
                const planPriceValue = planFullPrice / plan?.intervalLength;
                const planLabel = Math.floor(planPriceValue * 100) / 100;
                result = planLabel; // @todo update if multiple currencies
            }
        });
        return result;
    }

    function getCurrency(plansArray) {
        let result = '';
        plansArray.forEach((item) => {
            if (item.code === SubscriptionPlans.ANNUAL) {
                result = item.currencies && item.currencies[0].currency === 'USD' ? '$' : ''; // @todo update if multiple currencies
            }
        });
        return result;
    }

    return (
        <div className={styles.container}>
            <div className={styles.avatar}>
                <Avatar
                    image={userAvatar}
                    selectable={false}
                    selected={false}
                    size={AvatarTypes.REGULAR_ADVANTAGE}
                    onSelect={() => null}
                    disabled
                    border={borderMapping(type)}
                    noFrame
                />
            </div>
            <div className={styles.text}>
                <h2 className={styles.title}>Join Arkadium Advantage</h2>
                <div className={styles.description}>
                    <p>
                        Play over 100 of your favorite web and app games without ads for as low as{' '}
                        {plans && getCurrency(plans)}
                        {plans && getUnitAmountMonthly(plans)} a month
                    </p>
                </div>
                <Button
                    className={styles.btnSubscribe}
                    onClick={() => {
                        dispatch(setSubscriptionSource(SubscriptionSource.PROFILE));
                        UserService.openSubscriptionPanel();
                    }}
                >
                    Subscribe
                </Button>
            </div>
            <div className={styles.logo}>
                <ArkadiumAdvantageLogo className={styles.logoImg} />
            </div>
        </div>
    );
});

const borderMapping = (type: SubscriptionActions) => {
    return {
        [SubscriptionActions.NONE]: BorderTypes.DEFAULT,
        [SubscriptionActions.STANDART]: BorderTypes.GOLD,
        [SubscriptionActions.RENEW]: BorderTypes.GOLD,
        [SubscriptionActions.RESTART]: BorderTypes.DISABLED,
    }[type];
};

export function getSubscriptionPlans(dispatch, callback?) {
    PaymentService.getPlans()
        .then((res) => {
            dispatch(setSubscriptionPlans(res));
            if (callback) {
                callback(res);
            }
        })
        .catch((err) => {
            console.error(' PaymentService.getPlans()', err);
            AppInsightService.trackAppError(err, { data: 'getPlans()' });
        });
}
