import React, { useContext, useEffect, useState } from 'react';

import { batch, useDispatch, useSelector } from 'react-redux';

import { DEFAULT_AVATAR, DEFAULT_AVATAR_BACKGROUND, getAvatarAnalyticsKey } from '../../constants/AvatarImagesData';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { RegistrationSource } from '../../models/User/RegistrationSource';
import AdFreeService from '../../services/AdFreeService';
import adFreeService from '../../services/AdFreeService';
import { Analytics } from '../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../services/AppInsight';
import { AuthDataContext } from '../../services/AuthDataReact';
import { CookieService } from '../../services/CookieService';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../services/hooks/useRecaptchaSimple';
import { LocalStorageService } from '../../services/LocalStorage';
import PaymentService from '../../services/PaymentService';
import UserService, { AuthType } from '../../services/UserService';
import { setSideMenuActivePage, setSignInUpState } from '../../store/ducks/layout';
import { activePageSelector } from '../../store/ducks/layoutSelectors';
import { setPreLoadData } from '../../store/ducks/preLoadData';
import { setActiveUserSubscriptions } from '../../store/ducks/subscription/common';
import styles from './SocialButtons.css';

type SocialButtonsProps = {
    t?: any;
    className?: string;
    signIn?: boolean;
    game?: string | IGame;
    category?: string;
    checkBox?: boolean;
    errorMessageSocial?: (v) => void;
    email?: string;
    password?: string;
    provider?: string;
};

export const SocialButtons = React.memo((props: SocialButtonsProps) => {
    const [facebook, setFacebook] = useState(<></>);
    const [google, setGoogle] = useState(<></>);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const authDataContext = useContext(AuthDataContext);
    const activePage = useSelector(activePageSelector);
    const socialRegistrationLocation = useSelector((state) => state.socialRegistrationLocation);
    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.SIGN_IN);

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    });

    useEffect(() => {
        getFacebookButton();
        getGoogleButton();
    }, []);

    const onMessage = (event) => {
        if (event.origin !== 'https://login.arkadium.com') {
            return;
        }

        if (event.data?.type === 'ark-fb-action' && event.data?.action === 'button-click') {
            Analytics.trackEvent(
                Analytics.profile.loginClick(props.category, props.game as IGame, RegistrationSource.FACEBOOK)
            );
        }
        if (event.data?.type === 'ark-google-action' && event.data?.action === 'button-click') {
            Analytics.trackEvent(
                Analytics.profile.loginClick(props.category, props.game as IGame, RegistrationSource.GOOGLE)
            );
        }

        try {
            if (event.data?.type === 'ark-fb-action' && event.data.data?.access_token) {
                UserService.loginViaFacebook(
                    event.data.data.access_token,
                    null,
                    props.checkBox,
                    new URL(UserService.generateUrl()),
                    props.password,
                    null,
                    captchaToken
                ).then((resData) => {
                    const res = resData.errorCode;
                    const email = resData.errorEmail;
                    authDataContext.setAuthData({
                        ...authDataContext.value,
                        authProvider: AuthType.Facebook,
                        authProviderToken: event.data.data.access_token,
                    });
                    LocalStorageService.setItem('authProvider', AuthType.Facebook);
                    if (res === 200) {
                        afterSuccessLogin({ ...props, provider: AuthType.Facebook }, resData?.isRegistered === true);
                    } else if (UserService.errorCodeToEnum(res) === 'EmailDoesNotSpecified') {
                        console.log('err', res);
                        authDataContext.setAuthData({
                            ...authDataContext.value,
                            authProvider: AuthType.Facebook,
                            authProviderToken: event.data.data.access_token,
                        });
                        UserService.openRequestEmail();
                    } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                        console.log('err', res);
                        authDataContext.setAuthData({
                            ...authDataContext.value,
                            email,
                            authProvider: AuthType.Facebook,
                        });
                        UserService.openAfterSignPanel();
                    } else {
                        console.log('err', res);
                        props.errorMessageSocial(res);
                    }
                    updateCaptchaToken(res !== 200);
                });
            }
            if (event.data?.action === 'auth-google-user' && event.data.user?.access_token) {
                UserService.loginViaGoogle(
                    event.data.user.access_token,
                    props.email,
                    props.checkBox,
                    new URL(UserService.generateUrl()),
                    props.password,
                    null,
                    captchaToken
                ).then((resData) => {
                    const res = resData.errorCode;
                    const email = resData.errorEmail;
                    authDataContext.setAuthData({
                        ...authDataContext.value,
                        authProvider: AuthType.Google,
                        authProviderToken: event.data.user.access_token,
                    });
                    LocalStorageService.setItem('authProvider', AuthType.Google);
                    if (res === 200) {
                        afterSuccessLogin({ ...props, provider: AuthType.Google }, resData?.isRegistered === true);
                    } else if (UserService.errorCodeToEnum(res) === 'EmailDoesNotSpecified') {
                        console.log('err', res);
                        authDataContext.setAuthData({
                            ...authDataContext.value,
                            authProvider: AuthType.Google,
                        });
                        UserService.openRequestEmail();
                    } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                        console.log('err', res);
                        authDataContext.setAuthData({
                            ...authDataContext.value,
                            email,
                            authProvider: AuthType.Google,
                        });
                        UserService.openAfterSignPanel();
                    } else {
                        console.log('err', res);
                        props.errorMessageSocial(res);
                    }
                    updateCaptchaToken(res !== 200);
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    function afterSuccessLogin(props: SocialButtonsProps, isRegistered: boolean) {
        const { game, category, provider } = props;

        let loginRegistrationType;
        switch (provider) {
            case 'Facebook': {
                loginRegistrationType = RegistrationSource.FACEBOOK;
                break;
            }
            case 'Google': {
                loginRegistrationType = RegistrationSource.GOOGLE;
                break;
            }
        }

        LocalStorageService.setItem('authProvider', provider);

        if (isRegistered) {
            // first time registered from social (true equals FIRST TIME)
            UserService.userLoad().then(() => {
                const storedUser = UserService.getUserFromStore();
                batch(() => {
                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                    // These additional check fixes bug #142914, when after logout from google/facebook
                    // when user falls into PERSONALIZE_PROFILE after logout and can't go to login page
                    // until page reload
                    if (storedUser && storedUser.name && !storedUser.avatar) {
                        dispatch(setSignInUpState(SignInUpComponents.PERSONALIZE_PROFILE));
                    }
                });
                let userAvatar = DEFAULT_AVATAR.filename;
                let userBackground = DEFAULT_AVATAR_BACKGROUND;
                if (user) {
                    userAvatar = user.avatar;
                    userBackground = user.avatarBackground;
                }
                const avatarAnalyticsKey = getAvatarAnalyticsKey(userAvatar);
                LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
                // To have LP userAttribute {registered: true} set here we don't have email confirmed,
                // final Eagle user id, and LP session started with this id to set it in LP to correct user
                Analytics.trackEvent(
                    Analytics.profile.registration(
                        category,
                        game,
                        loginRegistrationType,
                        avatarAnalyticsKey,
                        userBackground,
                        socialRegistrationLocation
                    )
                );
            });
        } else {
            Analytics.trackEvent(
                Analytics.profile.loginSuccess(
                    props.category,
                    props.game,
                    socialRegistrationLocation,
                    loginRegistrationType
                )
            );
            LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.LOGIN_BTN, { action: 'login' });
            UserService.userLoad().then(() => {
                AdFreeService.vignetteAdsFix();
                if (activePage !== HeaderSideMenuTabs.SHOP_TAB) UserService.closePanel();
                UserService.resetPurchaseFlow();
            });
        }

        PaymentService.getSubscriptions()
            .then((res) => {
                if (res.length > 0) {
                    dispatch(setActiveUserSubscriptions(res));
                    //Check subscription

                    const isUserSubscriber = UserService.isUserSubscriber();
                    const isUserBonusOnly = UserService.isUserHasBonusOnly();

                    CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, isUserBonusOnly);
                    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, isUserSubscriber);

                    if (isUserSubscriber) {
                        CookieService.setArkCookie(
                            LS_COOKIE_CONSTS.SUBSCRIPTION_ID,
                            UserService.getSubscriptionId(),
                            30
                        );
                    }

                    dispatch(
                        setPreLoadData({
                            adFree: adFreeService.adFreeTurnOffFilter(isUserBonusOnly),
                            subscription: isUserSubscriber,
                        })
                    );
                }
            })
            .catch((err) => {
                AppInsightService.trackAppError(err, { data: 'userLogin()' });
            });
    }

    function getFacebookButton() {
        return UserService.getFacebookButton()
            .then((v) => {
                setFacebook(
                    <iframe
                        className={styles.facebookButton}
                        title="facebook"
                        src={v}
                        frameBorder="0"
                        id="facebook"
                        height="50px"
                        width="220px"
                        // sandbox="allow-scripts allow-same-origin allow-popups allow-forms" not auto close popup
                    />
                );
            })
            .catch(() => {
                console.error('Error on getting social button');
            });
    }

    function getGoogleButton() {
        return UserService.getGoogleButton()
            .then((v) => {
                setGoogle(
                    <iframe
                        className={styles.googleButton}
                        title="google"
                        src={v}
                        frameBorder="0"
                        id="google"
                        height="50px"
                        width="220px"
                        // sandbox="allow-scripts allow-same-origin allow-popups allow-forms" not auto close popup
                    />
                );
            })
            .catch(() => {
                console.error('Error on getting social button');
            });
    }

    const renderSocialBtns = (provider?) => {
        switch (provider) {
            case 'GOOGLE':
                return <>{google}</>;
            case 'FACEBOOK':
                return <>{facebook}</>;
            default:
                return (
                    <>
                        {facebook}
                        {google}
                    </>
                );
        }
    };

    return <div className={props.className}>{renderSocialBtns(props.provider)}</div>;
});
