import { createAction, createReducer } from 'redux-act';

type arkConfigType = {
  textCaptchaOn1StepPurchase: string;
  lpDelayWakeUp: number;
  feedId: string,
};

export const arkConfigReducer = createReducer<arkConfigType>({}, null);
export const setArkConfigData = createAction<arkConfigType>('set ark config');
arkConfigReducer.on(setArkConfigData, (_state, payload) => payload);
