import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import { DeviceUtils } from '../../../utils/DeviceUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArrowSmallIcon } from '../../atoms/Icons/Styleguide/ArrowSmallIcon';
import { ArkadiumIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ArkadiumLogo';
import { CollectionsIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/CollectionsIcon';
import { LBIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/LBIcon';
import { PlayGameIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/PlayGameIcon';
import { SearchIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/SearchIcon';
import { ShopIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ShopIcon';
import { SubscriptionTabIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/SubscriptionTabIcon';
import { SupportIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/SupportIcon';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageTypes, ProfileRightSideRoutes } from '../../constants/Pages';
import { GameState } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { HelpFormStatus } from '../../models/HelpFormStatus';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../models/PagesData';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { UserModel } from '../../models/User/UserModel';
import { AdvantageTab } from '../../molecules/AdvantageTab/AdvantageTab';
import { Analytics } from '../../services/Analytics/Analytics';
import { GameService } from '../../services/GameService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps, lsDispatchGamePurchaseRequestFail } from '../../services/LocalStorageListenerLogic';
import { Media } from '../../services/MediaService';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setActiveGameView } from '../../store/ducks/games';
import {
    setHelpTabFormActive,
    setProfilePanelState,
    setSideMenuActivePage,
    setSideMenuOpened,
} from '../../store/ducks/layout';
import { activePageSelector } from '../../store/ducks/layoutSelectors';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';
import { LeaderboardNotificationType } from '../Leaderboard/Leaderboard';
import { LeaderboardNotificationIcon } from '../Leaderboard/LeaderboardNotificationIcon';
import styles from './NewMobileBottomNav.css';

const mapStateToProps = (state) => ({
    isSideMenuOpened: state.sideMenu.isOpened,
    isHelpFormActive: state.sideMenu.helpFormStatus.active,
    activePage: activePageSelector(state),
    user: state.user,
    isHomePage: state.pageType === PageTypes.Home,
    isItGamePage: state.pageType === PageTypes.Game,
    isItProfilePage: state.pageType === PageTypes.Profile,
    isOpenedProfilePanel: state.profilePanel.isOpened,
    activeGameNavigationTab: state.gamePageViewState.activeTab,
    gameState: state.gameState,
    gameArkadiumSlug: state.gameArkadiumSlug,
    games: GameService.gameModelToGame(state.games),
    pages: state.pages,
});

interface MobileBottomNavProps extends RouteComponentProps {
    isSideMenuOpened: boolean;
    isHelpFormActive: boolean;
    activePage: HeaderSideMenuTabs;
    user: UserModel;
    isHomePage: boolean;
    isItGamePage: boolean;
    isItProfilePage: boolean;
    isOpenedProfilePanel: boolean;
    activeGameNavigationTab: string;
    dispatch: Dispatch<Action>;
    gameState: GameState;
    leaderboardNotificationType: LeaderboardNotificationType;
    gameArkadiumSlug: string;
    games: IGame[];
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
}

const MobileBottomNav = withRouter(
    connect(mapStateToProps)(
        React.memo((props: MobileBottomNavProps) => {
            const {
                isSideMenuOpened,
                isHelpFormActive,
                activePage,
                user,
                isHomePage,
                isItGamePage,
                isItProfilePage,
                isOpenedProfilePanel,
                activeGameNavigationTab,
                dispatch,
                gameState,
                leaderboardNotificationType,
                gameArkadiumSlug,
                games,
                history,
                pages,
            } = props;
            const [navDisabled, setNavDisabled] = useState(false);

            useEffect(() => {
                setNavDisabled(
                    [GameState.GAME, GameState.GAME_END, GameState.ADBLOCK, GameState.PREROLL].indexOf(gameState) === -1
                );
            }, [gameState]);

            const shouldHideNavigationPanel =
                DeviceUtils.isOrientationPortraitByJS() &&
                !isSideMenuOpened &&
                !isHelpFormActive &&
                (gameState === GameState.GAME ||
                    gameState === GameState.REWARD ||
                    gameState === GameState.INTERSTITIAL ||
                    gameState === GameState.PREROLL_PLAYING) &&
                activeGameNavigationTab === 'game';

            const game = isItGamePage ? GameService.findGameByArkadiumSlug(games, gameArkadiumSlug) : null;
            const hasCollection = isItGamePage ? game?.meta.hasCollection : null;

            function openHomeUrl() {
                const homeLinkAffiliate = UrlService.generateHomeLinkForAffiliate(pages);
                if (!isItGamePage) {
                    history.push(homeLinkAffiliate);
                } else {
                    typeof window !== 'undefined' && (window.location.href = homeLinkAffiliate);
                }
            }

            function closeSideMenu() {
                if (isHelpFormActive && activePage === HeaderSideMenuTabs.HELP_TAB) {
                    dispatch(setHelpTabFormActive(new HelpFormStatus(false)));
                    return;
                }
                if (isItGamePage) {
                    lsDispatchGamePurchaseRequestFail();
                    LocalStorageService.removeStorageListening(LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
                }
                dispatch(setSideMenuOpened(false));
            }

            return (
                <>
                    <div>
                        <Media lessThan="ARK_SMALL_DESKTOP">
                            <Helmet>
                                <style>
                                    {`
                                    @media (max-width: 1024px) and (min-width: 566px) and (orientation: landscape) {
                                        #root {
                                            padding-left: 5.625rem;
                                            width: 100%;
                                            box-sizing: border-box;
                                        }
                                    }
                                `}
                                </style>
                            </Helmet>
                        </Media>
                    </div>
                    <nav
                        className={classNames(styles.mobileNav, {
                            [styles.extendMediaQuery]: isItGamePage,
                            [styles.hidden]: shouldHideNavigationPanel,
                            [styles.isSideMenuOpened]: isSideMenuOpened,
                            [styles.noHeaderOffset]: activePage === HeaderSideMenuTabs.LOG_IN_TAB,
                        })}
                        aria-label="main menu"
                    >
                        <div className={styles.buttonsWrapper}>
                            {isSideMenuOpened ? (
                                <button key="back" className={styles.navItem} onClick={closeSideMenu}>
                                    <ArrowSmallIcon className={styles.backIcon} />
                                    <I18nText keyName="SIDEBAR_HIDE" className={styles.navItemCaption} />
                                </button>
                            ) : (
                                <button
                                    key="home"
                                    className={classNames(styles.navItem, styles.stroke, {
                                        [styles.active]: !isSideMenuOpened && isHomePage,
                                    })}
                                    onClick={() => openHomeUrl()}
                                >
                                    <ArkadiumIcon className={classNames(styles.navItemIcon)} />
                                    <I18nText keyName="MOBILE_NAV_HOME" className={styles.navItemCaption} />
                                </button>
                            )}
                            <button
                                className={classNames(styles.navItem, {
                                    [styles.active]: isSideMenuOpened && activePage === HeaderSideMenuTabs.SEARCH_TAB,
                                })}
                                onClick={() => {
                                    if (isOpenedProfilePanel) {
                                        dispatch(
                                            setProfilePanelState({
                                                isOpened: false,
                                                caption: '',
                                                target: ProfileRightSideRoutes.NOOP,
                                            })
                                        );
                                    }
                                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SEARCH_TAB));
                                }}
                            >
                                <SearchIcon className={styles.navItemIcon} />
                                <I18nText keyName="MOBILE_NAV_SEARCH" className={styles.navItemCaption} />
                            </button>

                            {/*  Advantage Tab */}
                            <AdvantageTab
                                advantageTabContent={
                                    <button
                                        className={classNames(styles.navItem, styles.stroke, {
                                            [styles.active]:
                                                isSideMenuOpened && activePage === HeaderSideMenuTabs.SUBSCRIPTION_TAB,
                                        })}
                                        onClick={() => {
                                            if (isOpenedProfilePanel) {
                                                dispatch(
                                                    setProfilePanelState({
                                                        isOpened: false,
                                                        caption: '',
                                                        target: ProfileRightSideRoutes.NOOP,
                                                    })
                                                );
                                            }
                                            dispatch(setSubscriptionSource(SubscriptionSource.MOBILE_MENU));
                                            UserService.openSubscriptionPanel();
                                        }}
                                    >
                                        <SubscriptionTabIcon className={styles.navItemIcon} />
                                        <I18nText keyName="SUBSCRIPTION_TAB" className={styles.navItemCaption} />
                                    </button>
                                }
                            />
                            {/* GEMS SHOP TAB */}
                            <button
                                className={classNames(styles.navItem, {
                                    [styles.active]: isSideMenuOpened && activePage === HeaderSideMenuTabs.SHOP_TAB,
                                })}
                                onClick={() => {
                                    if (isOpenedProfilePanel) {
                                        dispatch(
                                            setProfilePanelState({
                                                isOpened: false,
                                                caption: '',
                                                target: ProfileRightSideRoutes.NOOP,
                                            })
                                        );
                                    }
                                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
                                }}
                            >
                                <ShopIcon className={styles.navItemIcon} />
                                <I18nText keyName="MOBILE_NAV_SHOP" className={styles.navItemCaption} />
                            </button>

                            <button
                                className={classNames(styles.navItem, {
                                    [styles.active]: isSideMenuOpened && activePage === HeaderSideMenuTabs.HELP_TAB,
                                })}
                                onClick={() => {
                                    if (isOpenedProfilePanel) {
                                        dispatch(
                                            setProfilePanelState({
                                                isOpened: false,
                                                caption: '',
                                                target: ProfileRightSideRoutes.NOOP,
                                            })
                                        );
                                    }
                                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                                }}
                            >
                                <SupportIcon className={styles.navItemIcon} />
                                <I18nText keyName="MOBILE_NAV_SUPPORT" className={styles.navItemCaption} />
                            </button>

                            {/* Game page leaderboard */}
                            {isItGamePage && (
                                <>
                                    <button
                                        className={classNames(styles.navItem, {
                                            [styles.disabled]: navDisabled,
                                        })}
                                        onClick={() => {
                                            if (!navDisabled) {
                                                if (isOpenedProfilePanel) {
                                                    dispatch(
                                                        setProfilePanelState({
                                                            isOpened: false,
                                                            caption: '',
                                                            target: ProfileRightSideRoutes.NOOP,
                                                        })
                                                    );
                                                }

                                                if (activeGameNavigationTab !== 'leaderboard' && game) {
                                                    Analytics.trackEvent(
                                                        Analytics.games.gameNavigationLeaderboardIconClick(game)
                                                    );
                                                }

                                                dispatch(
                                                    setActiveGameView({
                                                        activeTab:
                                                            activeGameNavigationTab === 'game' && !navDisabled
                                                                ? 'leaderboard'
                                                                : 'game',
                                                    })
                                                );
                                            }
                                        }}
                                    >
                                        {activeGameNavigationTab !== 'game' ? (
                                            <>
                                                <PlayGameIcon className={styles.navItemIcon} />
                                                <I18nText keyName="PLAY_BTN" className={styles.navItemCaption} />
                                            </>
                                        ) : (
                                            <>
                                                <LBIcon className={styles.navItemIcon} />
                                                <I18nText keyName="LEADERBOARD" className={styles.navItemCaption} />
                                                {leaderboardNotificationType.ICON && (
                                                    <LeaderboardNotificationIcon place="nav" />
                                                )}
                                            </>
                                        )}
                                    </button>
                                    {/* COLLECTION TAB */}
                                    {/* if hasCollection string value === prod, then show tab on prod and devs, if value === dev, then only on devs */}
                                    {(BUILD_ENV === 'prod' && hasCollection === 'prod') ||
                                        (BUILD_ENV !== 'prod' &&
                                            (hasCollection === 'dev' || hasCollection === 'prod') && (
                                                <button
                                                    className={classNames(styles.navItem, {
                                                        [styles.disabled]: navDisabled,
                                                    })}
                                                    onClick={() => {
                                                        if (!navDisabled) {
                                                            if (isOpenedProfilePanel) {
                                                                dispatch(
                                                                    setProfilePanelState({
                                                                        isOpened: false,
                                                                        caption: '',
                                                                        target: ProfileRightSideRoutes.NOOP,
                                                                    })
                                                                );
                                                            }

                                                            dispatch(
                                                                setActiveGameView({
                                                                    activeTab: 'collections',
                                                                })
                                                            );

                                                            if (game) {
                                                                Analytics.trackEvent(
                                                                    Analytics.games.gameNavigationCollectionButton(game)
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <>
                                                        <CollectionsIcon className={styles.navItemIcon} />
                                                        <I18nText
                                                            keyName="Collections"
                                                            className={styles.navItemCaption}
                                                        />
                                                    </>
                                                </button>
                                            ))}
                                </>
                            )}
                        </div>
                    </nav>
                </>
            );
        })
    )
);

export default MobileBottomNav;
