import { PurchasableItemPurchaseItemRequestDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item.dto';
import { UserInventoryUpdateRequestDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/user-inventory-update-request.dto';

import { environment } from '../config/environment';

export const skipPrerollItem: PurchasableItemPurchaseItemRequestDto = {
    sku: environment.SKU_GEMS_SKIP_AD_BUNDLE,
    amount: 1,
};

export const skipPrerollVirtualItem: UserInventoryUpdateRequestDto = {
    items: [
        {
            sku: environment.SKU_GEMS_SKIP_AD,
            amount: 1,
        },
    ],
};

export const GEMS_TEST_SKU = environment.SKU_GEMS_NAME;
export const GEMS_COST_PREROLL = 5; // todo after mvp replace with real request to eagle
export const LS_SENT_AI_GEMS_IMPRESSION = `${environment.STORAGE_PREFIX}LS_SENT_AI_GEMS_IMPRESSION`;
