import React, { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import {
    PASSWORD_VALIDATION_INSTRUCTIONS_IDS,
    ValidationHints,
} from '../../../../molecules/ValidationHints/ValidationHints';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { LocalStorageService } from '../../../../services/LocalStorage';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';

export const RequestEmail = React.memo(() => {
    const [loading, setLoading] = useState(false);
    const authDataContext = useContext(AuthDataContext);
    const { checkBox, authProviderToken, authProvider } = authDataContext.value;
    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.SIGN_IN);

    const dispatch = useDispatch();

    const handleSignIn = () => {
        dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
    };

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
            .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')),
    });

    const defaultValues = {
        email: '',
        password: '',
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();

    const { email, password } = values;

    const onSubmit = () => {
        setLoading(true);

        if (authProvider === AuthType.Facebook) {
            UserService.loginViaFacebook(
                authProviderToken,
                email,
                checkBox,
                new URL(UserService.generateUrl()),
                null,
                null,
                captchaToken
            ).then((resData) => {
                setLoading(false);
                const res = resData.errorCode;
                Analytics.trackEvent(Analytics.profile.socialEmailRequest());
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.Facebook);
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
                    UserService.openAfterSignPanel();
                } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                    console.log('err', res);
                    authDataContext.setAuthData({
                        ...authDataContext.value,
                        email,
                        authProvider: AuthType.Facebook,
                    });
                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            });
        } else if (authProvider === AuthType.Google) {
            UserService.loginViaGoogle(
                authProviderToken,
                email,
                checkBox,
                new URL(UserService.generateUrl()),
                null,
                null,
                captchaToken
            ).then((resData) => {
                setLoading(false);
                const res = resData.errorCode;
                Analytics.trackEvent(Analytics.profile.socialEmailRequest());
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.Facebook);
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
                    console.log('Request email confirm with social google');
                    UserService.openAfterSignPanel();
                } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                    console.log('err', res);
                    authDataContext.setAuthData({
                        ...authDataContext.value,
                        email,
                        authProvider: AuthType.Google,
                    });
                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            });
        } else {
            dispatch(
                setSnackbarData({
                    isOpened: true,
                    message: 'No social network selected',
                    type: 'error',
                    parentNode: 'rightSideMenu',
                })
            );
        }
    };

    return (
        <RightPanelWrapper headerLabelKey="Complete your account">
            <I18nText as="div" className={styles.subHeader} keyName={'REGISTER_SOCIAL_COMPLETE_PROFILE_DESCRIPTION'} />{' '}
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formContent}>
                <div className={styles.inputRow}>
                    <Input
                        required
                        className={styles.input}
                        isValid={!errors.email}
                        ariaInvalid={errors.email ? 'true' : 'false'}
                        register={register}
                        name="email"
                        type="text"
                        maxLength={50}
                        placeholder={TranslationService.translateIt('REGISTER_ENTER_YOUR_EMAIL_ADDRESS')}
                        value={email}
                        showError={errors?.email}
                        errorMessage={errors?.email?.message ?? ''}
                    />
                </div>
                <div className={styles.inputRow}>
                    <Input
                        className={styles.input}
                        isValid={!errors.password}
                        ariaInvalid={errors.password ? 'true' : 'false'}
                        register={register}
                        name="password"
                        type="password"
                        maxLength={50}
                        placeholder={TranslationService.translateIt('REGISTER_ENTER_YOUR_PASSWORD_OPTIONAL')}
                        value={password}
                        showError={errors?.password}
                        errorMessage={errors?.password?.message ?? ''}
                        ariaDescribedBy={PASSWORD_VALIDATION_INSTRUCTIONS_IDS}
                    />
                    <ValidationHints password={password} />
                </div>
                <div className={styles.buttonRow}>
                    <Button className={styles.submitButton} type="submit" disabled={!!errors.email} loading={loading}>
                        <I18nText keyName={'REGISTER_SUBMIT'} />
                    </Button>
                </div>
                <div className={styles.rememberRow}>
                    Not fangamer@mail.com?{' '}
                    <Button isLink onClick={handleSignIn}>
                        <I18nText keyName={'Start over '} />
                    </Button>
                </div>
            </form>
        </RightPanelWrapper>
    );
});
