import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setProfilePanelState } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../StillNeedHelp/StillNeedHelp';

export const AfterSignUp = React.memo(() => {
    const [disabledResend, setDisabledResend] = useState(false);
    const [loading, setLoading] = useState(false);

    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);

    const dispatch = useDispatch();

    const activeSignInUpState = useSelector((state) => state.signInUpState.activeComponent);

    const authDataContext = useContext(AuthDataContext);
    const { email, authProvider } = authDataContext.value;

    const [message, setMessage] = useState(TranslationService.translateIt('REGISTER_IF_YOU_DO_NOT_RECEIVE_EMAIL_2'));

    let timerId: any = -1;

    const handleResend = () => {
        setLoading(true);
        UserService.resendConfirmationEmail(email, captchaToken, authProvider || AuthType.EmailPassword).then((res) => {
            setDisabledResend(true);
            setLoading(false);
            if (res === 200) {
                setMessage(TranslationService.translateIt('REGISTER_THE_EMAIL_HAS_BEEN_SENT_AGAIN'));
                Analytics.trackEvent(Analytics.profile.resendConfirmation());
            } else {
                console.log(res);
            }
            updateCaptchaToken(res !== 200);
        });
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            setDisabledResend(false);
        }, 4000);
    };

    const GetHeaderActions = () => {
        return (
            <div className={classNames(styles.newVersion)}>
                <Button loading={loading} onClick={handleResend} disabled={disabledResend}>
                    <I18nText keyName={'REGISTER_RESEND_TXT'} />
                </Button>
            </div>
        );
    };

    const getBodyMiddle = () => {
        switch (activeSignInUpState) {
            case SignInUpComponents.AFTER_SIGN:
                return <GetHeaderActions />;

            case SignInUpComponents.AFTER_SIGN_COMPLETE:
                return null;

            default:
                break;
        }
    };

    const sendCorrect = () => {
        dispatch(
            setProfilePanelState({
                isOpened: true,
                caption: 'FOR_YOUR_SECURITY',
                target: ProfileRightSideRoutes.REQUEST_EMAIL,
            })
        );
    };

    const getButtonsResend = () => {
        switch (activeSignInUpState) {
            case SignInUpComponents.AFTER_SIGN:
                return null;

            case SignInUpComponents.AFTER_SIGN_COMPLETE:
                return <GetHeaderActions />;

            default:
                break;
        }
    };

    const getNoticeEmailLabel = () => {
        switch (activeSignInUpState) {
            case SignInUpComponents.AFTER_SIGN:
                return null;

            case SignInUpComponents.AFTER_SIGN_COMPLETE:
                return (
                    <div className={styles.noticeText}>
                        <I18nText as="div" keyName={'REGISTER_NOTICE_A_TYPO'} />
                        <Button isLink onClick={sendCorrect}>
                            <I18nText keyName={'REGISTER_CLICK_HERE'} />
                        </Button>{' '}
                        <I18nText keyName={'REGISTER_TO_SEND_IT'} />
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <RightPanelWrapper headerLabelKey="REGISTER_CONGRATULATIONS_YOURE_ALMOST_DONE">
            <div className={styles.subHeader}>
                <I18nText keyName={'REGISTER_TO_COMPLETE_YOUR_PROFILE'} />{' '}
                <span className={styles.subHeaderEmail}>{email || 'longemailaddress@email.com'}</span>
            </div>
            <div className={styles.receiveText}>{message}</div>
            {getBodyMiddle()}
            {getNoticeEmailLabel()}
            {getButtonsResend()}
            <StillNeedHelp />
        </RightPanelWrapper>
    );
});
AfterSignUp.displayName = 'AfterSignUp';
