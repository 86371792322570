import { useEffect, useState } from 'react';

import { CaptchaActionsEnum } from '@arkadium/eagle-payments-api-client';

import { MiscUtils } from '../../../utils/MiscUtils';
import { environment } from '../../config/environment';

/*
 * IMPLEMENTATION STATUS INFORMATION:
 * These methods were updated with recaptcha in "@arkadium/eagle-user-client" v. 0.0.65:
 *
 * ------------------------------------------------------------------------------------------------------------------------
 * registerViaEmail(data: EmailRegistrationDTO): Promise<void>; // UPDATED IN UserService.userRegister()
 *
 * loginViaEmail(data: EmailLoginDTO): Promise<void>; // UPDATED IN UserService.userLogin()
 * loginViaApple(data: AppleLoginDTO): Promise<AuthResponsePayloadDto>; // NONE USED ON ARK.COM
 * loginViaFacebook(data: FacebookLoginDTO): Promise<AuthResponsePayloadDto>; // UPDATED IN UserService.loginViaFacebook()
 * loginViaGoogle(data: GoogleLoginDTO): Promise<AuthResponsePayloadDto>; // UPDATED IN UserService.loginViaGoogle()
 *
 * resendConfirmation(data: ResendConfirmationDTO): Promise<void>; // UPDATED IN UserService.resendConfirmationEmail()
 *
 * requestResetPassword(data: RequstResetPasswordDTO): Promise<void>; // UPDATED IN UserService.requestResetPassword()
 *
 * confirmResetPassword(data: ConfirmResetPasswordDTO): Promise<void>; // // MARK SAID NOT NEEDED FOR API USAGE, also double checked in Swagger
 * confirmUser(data: EmailConfirmationDTO): Promise<AuthResponseDTO>; // MARK SAID NOT NEEDED FOR API USAGE, also double checked in Swagger
 * changePassword(data: ChangePasswordDTO): Promise<void>; // MARK SAID NOT NEEDED FOR API USAGE, also double checked in Swagger
 * ------------------------------------------------------------------------------------------------------------------------
 *
 * /// CRV ToDo: Check and update this if Eagle API / @arkadium/eagle-user-client updated
 */

enum RECAPTCHA_ACTIONS_CLIENT {
    // User flows, provided by Eagle directly
    SIGN_IN = 'SignIn',
    SIGN_UP = 'SignUp',
    PASSWORD_RESET = 'PasswordReset',
    CONFIRMATION_RESEND = 'ResendConfirmation',
    CHECK_EMAIL = 'CheckEmail',
}
const RECAPTCHA_ACTIONS_PAYMENTS = CaptchaActionsEnum; // Payment flows, from "@arkadium/eagle-payments-api-client"
export const RECAPTCHA_ACTIONS = Object.assign({}, RECAPTCHA_ACTIONS_CLIENT, RECAPTCHA_ACTIONS_PAYMENTS);
export type RECAPTCHA_ACTIONS_TYPE = typeof RECAPTCHA_ACTIONS[keyof typeof RECAPTCHA_ACTIONS];

const getRecaptchaToken = async (
    actionName: RECAPTCHA_ACTIONS_TYPE,
    stateCallback?: any,
    addCallback?: (token?: string) => void
) => {
    const token = await MiscUtils.getRecaptchaToken(environment.RECAPTCHAAPPKEY, actionName);
    stateCallback && stateCallback?.(token);
    addCallback && addCallback?.(token);
};

export const useRecaptchaSimple = (actionName: RECAPTCHA_ACTIONS_TYPE, addCallback?: (token?: string) => void) => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const updateCaptchaToken = (doUpdate?: boolean): void => {
        if (!captchaToken || doUpdate) {
            getRecaptchaToken(actionName, setCaptchaToken, addCallback);
        }
    };
    useEffect(() => {
        updateCaptchaToken();
    }, []);
    return [captchaToken, updateCaptchaToken];
};
