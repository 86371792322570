import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { ArkadiumFullLogo } from '../../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { IGame } from '../../../models/Game/Game';
import { PwaPlatformsMobile } from '../../../services/Analytics/AnalyticsEventBuilder';
import { PWAService } from '../../../services/PWAService';
import { MobileNavButtonsNames } from '../MobileNavigationUxRedesigned.types';
import { AddGamePwaTutorial } from './AddGamePwaTutorial';
import { ButtonsBlock } from './ButtonsBlock';
import { CategoriesBody } from './CategoriesBody';
import { CategoriesTitle } from './CategoriesTitle';
import { IconBack } from './IconBack';
import { SearchField } from './SearchInput';
import styles from './styles.css';

export type SharedProps = {
    activeButtonName?: MobileNavButtonsNames;
    isItGamePage?: boolean;
    isHomePage?: boolean;
    games?: IGame[];
    style?: React.CSSProperties;
};

export const MenuBody = (props: SharedProps) => {
    const { activeButtonName } = props;
    const style = props.style || {};
    const getSafariVhAddressbarHeightStyleFix = () => ({
        paddingTop: `calc(${(window.outerHeight - window.innerHeight) / 1}px - 1.5rem)`,
    });
    const [safariVhAddressbarHeightStyleFix, setSafariVhAddressbarHeightStyleFix] = useState<any>({});
    const handleSafariVhAddressbarHeightStyleFix = () => {
        PWAService.getDeviceOs() === PwaPlatformsMobile.IOS &&
            window?.outerHeight !== window?.innerHeight &&
            setSafariVhAddressbarHeightStyleFix(getSafariVhAddressbarHeightStyleFix());
    };
    useEffect(() => {
        handleSafariVhAddressbarHeightStyleFix();
        window.addEventListener('resize', handleSafariVhAddressbarHeightStyleFix);
        return window.removeEventListener('resize', handleSafariVhAddressbarHeightStyleFix);
    }, []);
    return (
        <div className={styles.Root} style={{ ...style, ...safariVhAddressbarHeightStyleFix }}>
            {activeButtonName === MobileNavButtonsNames.MENU && (
                <>
                    <div className={classNames(styles.Row, styles.__RowLogo)}>
                        <IconBack />
                        <ArkadiumFullLogo className={styles.ArkLogo} />
                    </div>
                    <div className={(styles.Row, styles.__RowSearch)}>
                        <SearchField />
                    </div>
                    <div className={(styles.Row, styles.__RowButtons)}>
                        <ButtonsBlock {...props} />
                    </div>
                    <div className={(styles.Row, styles.__RowCategoriesTitle)}>
                        <CategoriesTitle />
                    </div>
                    <div className={(styles.Row, styles.__RowCategories)}>
                        <CategoriesBody games={props.games} />
                    </div>
                </>
            )}
            {/* @ts-ignore */}
            {activeButtonName === MobileNavButtonsNames.ADD_GAME && <AddGamePwaTutorial {...props} />}
        </div>
    );
};
