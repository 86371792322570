import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import { Button } from '../../FigmaStyleguide/Button/Button';
import { SubscriptionPlan } from '../../models/Subscription/SubscriptionData';
import { ManageType } from '../../organisms/HeaderSideMenu/SubscriptionTab/ManagePlan/ManagePlan';
import styles from './MonthlyCard.css';

type MonthlyCardProps = {
    btnLabel: string;
    manageType?: ManageType;
    managePlanStyles?: boolean;
    plan: SubscriptionPlan;
    handlerBtn: () => void;
};
export const MonthlyCard: FC<MonthlyCardProps> = React.memo(
    ({ btnLabel, managePlanStyles, manageType, plan, handlerBtn }: MonthlyCardProps) => {
        MonthlyCard.displayName = 'MonthlyCard';

        const [planPriceLabel, setPlanPriceLabel] = useState<number>(null);
        const [currencySign, setCurrencySign] = useState<string>('');

        useEffect(() => {
            const planFullPrice = plan?.currencies[0]?.unitAmount;
            const planPriceValue = planFullPrice / plan?.intervalLength;
            const planLabel = Math.floor(planPriceValue * 100) / 100;
            const planCurency = plan?.currencies[0]?.currency === 'USD' ? '$' : ''; // create a helper for getting currencies signs
            setPlanPriceLabel(planLabel);
            setCurrencySign(planCurency);
        }, []);

        return (
            <div
                className={classNames(
                    styles.container,
                    managePlanStyles && styles.managePlanStyles,
                    manageType === ManageType.UPDATE && styles.managePlanUpdate
                )}
            >
                <div className={styles.info}>
                    <div className={styles.valuesRow}>
                        <div className={styles.heading}>Monthly</div>
                        <div>
                            <span className={styles.price}>
                                {currencySign}
                                {planPriceLabel}
                            </span>{' '}
                            <span className={styles.monthLabel}>/month</span>
                        </div>
                    </div>
                </div>
                {manageType !== ManageType.UPDATE && (
                    <div className={classNames(styles.btnRow, styles.btnRowMonthly)}>
                        <Button outlined className={styles.btnSubscribe} onClick={handlerBtn}>
                            {btnLabel}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
);
