import React from 'react';

import { useDispatch } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { setSignInUpState } from '../../../../store/ducks/layout';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../StillNeedHelp/StillNeedHelp';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';

export const ResetPasswordSuccess = React.memo(() => {
    const dispatch = useDispatch();

    const handleSignIn = () => {
        dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
    };

    return (
        <RightPanelWrapper headerLabelKey="REGISTER_RESET_YOUR_PASSWORD_SUCCESS">
            <div className={styles.buttonRow}>
                <Button className={styles.submitButton} onClick={handleSignIn}>
                    <I18nText keyName={'REGISTER_SIGN_IN'} />
                </Button>
            </div>
            <StillNeedHelp />
        </RightPanelWrapper>
    );
});
