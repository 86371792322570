import { BlogAnalyticsAi } from './AI/BlogAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';

export class BlogAnalytics {
    aiAnalytics = new BlogAnalyticsAi();

    async postCTAClick(CTAtag: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.BlogPostCTA(CTAtag));
    }

}
