import { Api, ApiGateway, ApiGatewayInitParams } from '@arkadium/eagle-payments-api-client';
import { RecurlyItemDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/recurly-data.dto';

import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { SubscriptionPlan, UserSubscription } from '../models/Subscription/SubscriptionData';
import UserService from './UserService';

class PaymentService {
    public paymentApiService: ApiGateway;

    constructor() {
        if (!MiscUtils.isServer) {
            this.initAPI();
        }
    }

    public createSubscription = async (planCode: string, tokenId: string, couponCodes?: string[]): Promise<any> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const subscriptions = await api.recurlySubscriptions.createSubscription({
            planCode,
            tokenId,
            couponCodes,
            arenaName: environment.ARENA_DOMAIN,
        });
        return subscriptions;
    };

    public purchaseSubscription = async (
        email: string,
        tokenId: string,
        couponCodes: string[],
        giftCardRedemptionCode: string,
        planCode: string,
        captchaToken: string
    ): Promise<any> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const subscriptions = await api.recurlySubscriptions.purchaseSubscriptionByEmail(email, {
            planCode,
            tokenId,
            arenaName: environment.ARENA_DOMAIN,
            couponCodes,
            giftCardRedemptionCode,
            captchaToken,
        });
        return subscriptions;
    };

    public purchaseGiftCard = async (
        currency: string, // USD
        currencyAmountInCents: number, // gift card cost in cents: 2999
        customMessage: string,
        deliveryDate: string,
        productCode: string, // code for gift card in Recurly constant: test_gift
        recipientEmail: string,
        recipientName: string,
        senderEmail: string,
        senderName: string,
        tokenId: string
    ): Promise<any> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const subscriptions = await api.recurlySubscriptions.purchaseGiftCard({
            currency,
            currencyAmountInCents,
            customMessage,
            deliveryDate,
            productCode,
            recepientEmail: recipientEmail, // TODO: fix this after update library
            recepientName: recipientName, // TODO: fix this after update library
            senderEmail,
            senderName,
            tokenId,
        });
        return subscriptions;
    };

    public cancelSubscription = async (subscriptionId: string) => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const data = {
            subscriptionId,
        };
        const subscriptions = await api.recurlySubscriptions.cancelSubscription(data);
        return subscriptions;
    };

    // TODO: types from api are wrong. Need to request an update from eagle
    public getPlans = async (): Promise<SubscriptionPlan[]> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const subscriptions = await api.recurlySubscriptions.getPlans();
        // @ts-ignore
        return subscriptions;
    };

    // TODO: types from api are wrong. Need to request an update from eagle
    public getSubscriptions = async (): Promise<UserSubscription[]> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const subscriptions = await api.generalSubscriptions.getSubscriptions();
        // @ts-ignore
        return subscriptions;
    };

    public getLastDateSubscription = (subscriptions) => {
        const sortedSubscription = subscriptions.sort(function (a, b) {
            return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
        });
        return sortedSubscription[0];
    };

    public getRecurlyData = async (): Promise<{ hostedLoginToken: string }> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const subscriptions = await api.recurlySubscriptions.getRecurlyData();
        return subscriptions;
    };

    // TODO: Fix: UserSubscription type from eagle api is different from our UserSubscription
    public getExpiredSubscriptions = async () => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const expiredSubscriptions = await api.generalSubscriptions.getExpiredSubscriptions();
        return expiredSubscriptions as UserSubscription[];
    };

    public getPurchasableItems = async (purchasableItemsSkuList: string[]): Promise<RecurlyItemDto[]> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        const items = await api.recurlySubscriptions.getPurchasableItems({ ids: purchasableItemsSkuList });
        return items;
    };

    public purchaseItem = async (
        itemCode: string,
        captchaToken: string,
        quantity: number,
        tokenId?: string,
        gameKey?: string,
        couponCodes?: string[]
    ): Promise<void> => {
        const api = await this.paymentApiService.getApi(Api.v1);
        return api.recurlySubscriptions.purchaseItem({
            itemCode,
            quantity,
            arenaName: environment.ARENA_DOMAIN,
            tokenId,
            gameKey,
            couponCodes,
            captchaToken,
        });
    };

    private initAPI() {
        const params: ApiGatewayInitParams = {
            // Base url of Eagle API
            server: new URL(
                environment.EAGLE_API_PAYMENT || 'https://eagle-payment-api.uup-aks-dev.arkadiumhosted.com'
            ),
            sessionStorage: UserService && UserService.getSessionStorage ? UserService.getSessionStorage() : {},
        };
        this.paymentApiService = new ApiGateway(params);
    }
}

export default new PaymentService();
