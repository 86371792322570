import React from 'react';

import Cookies from 'js-cookie';

import { AppInsightService } from '../../services/AppInsight';
import { CookieService } from '../../services/CookieService';
import { LocalStorageService } from '../../services/LocalStorage';
import { SessionStorageService } from '../../services/SessionStorage';
import { I18nText } from '../i18nText/i18nText';
import styles from './ErrorBoundary.css';

type ErrorBoundaryState = {
    swallow?: boolean;
    hasError: boolean;
};

const REFRESH_ATTEMPTED = 'refresh_attempted';
const REFRESH_EXPIRES_SECONDS = 60;
const AUTH_TOKEN = 'eagle-access-token';
const EXPIRES_IN = 'eagle-expires_in';
const REFRESH_TOKEN = 'eagle-refresh-token';

export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    state = { hasError: false };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.error(error, info);
        AppInsightService.trackAppError(`[ErrorBoundary]`, error.message + error.stack);
    }

    getRefreshAttemptedTime = (time: string) => new Date(Number(time)).getTime() / 1000;

    render() {
        if (this.state.hasError) {
            const urlParams = new URLSearchParams(window.location.search);
            const arkver = urlParams.get('__arkver');
            const refreshAttempted = LocalStorageService.getItem(REFRESH_ATTEMPTED);
            const currentTime = new Date().getTime() / 1000;

            if (
                (refreshAttempted &&
                    currentTime - this.getRefreshAttemptedTime(refreshAttempted) < REFRESH_EXPIRES_SECONDS) ||
                (arkver && +arkver > 0)
            ) {
                return this.props.swallow ? null : (
                    <div className={styles.container}>
                        <h1 className={styles.title}>
                            <I18nText keyName="SOMETHING_WENT_WRONG" />
                        </h1>
                        <p>
                            <I18nText keyName="CLEAR_YOUR_CACHE" />
                        </p>
                        <a
                            href="https://support.arkadium.com/en/support/solutions/articles/44002171149-how-do-i-clear-cookie-files-in-my-browser-"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <I18nText keyName="GUIDE_CLEAR_CACHE" />
                        </a>
                    </div>
                );
            }

            Object.keys(Cookies.get()).forEach((cookieName) => {
                CookieService.removeArkCookie(cookieName);
            });

            SessionStorageService.clear();
            //Keep user signed in
            const toPersist = {
                [AUTH_TOKEN]: LocalStorageService.getItem(AUTH_TOKEN, true),
                [EXPIRES_IN]: LocalStorageService.getItem(EXPIRES_IN, true),
                [REFRESH_TOKEN]: LocalStorageService.getItem(REFRESH_TOKEN, true),
            };
            LocalStorageService.clear();
            for (const key in toPersist) {
                LocalStorageService.setItem(key, toPersist[key], true);
            }
            LocalStorageService.setItem(REFRESH_ATTEMPTED, Date.now().toString());

            const randomNumber = (Math.floor(Math.random() * 999) + 100).toString();
            urlParams.set('__arkver', randomNumber);
            window.location.search = urlParams.toString();
            return;
        }

        return this.props.children;
    }
}
