import { environment } from '../config/environment';

export const LS_LISTENER_IS_ON_PROP = 'LS_LISTENER_IS_ON_PROP';

class LocalStorageProvider {
    private data = {};

    private readonly prefix: string = environment.STORAGE_PREFIX;

    private eventCallbacks = {};

    public setItem(key: string, value: any, noprefix = false) {
        if (!noprefix) {
            key = `${this.prefix}${key}`;
        }
        if (typeof window === 'undefined') {
            this.data[key] = String(value);
        } else {
            try {
                window.localStorage.setItem(key, String(value));
            } catch (e) {
                console.log(e);
            }
        }
    }

    public getItem(key: string, noprefix = false): string | null {
        if (!noprefix) {
            key = `${this.prefix}${key}`;
        }

        if (typeof window === 'undefined') {
            return this.data[key];
        }
        const value = window.localStorage.getItem(key);

        if (!value || value === 'undefined' || value === 'null') {
            return null;
        }

        return value;
    }

    public removeItem(key: string, prefix: boolean = true) {
        key = prefix ? `${this.prefix}${key}` : key;
        if (typeof window === 'undefined') {
            delete this.data[key];
        } else {
            window.localStorage.removeItem(key);
        }
    }

    public clear() {
        if (typeof window === 'undefined') {
            this.data = {};
        } else {
            window.localStorage.clear();
        }
    }

    public dispatchEvent(key: string, newValue: string, noprefix = false) {
        if (typeof window === 'undefined') return;

        const oldValue = this.getItem(key, noprefix);
        this.setItem(key, newValue, noprefix);
        const event = new StorageEvent('storage', {
            key,
            oldValue,
            newValue,
        });

        window.dispatchEvent(event);
    }

    /**
     * Adds a localStorage event listener
     *
     * @remarks
     * Make sure to also call removeEvent to avoid memory leaks.
     *
     * Event is not fired or catchable under Chrome, Edge and Safari if domain was modified through script.
     *
     * Safari Incognito is not supported
     * @param handler - the useCallback or regular function to be called
     * @param options - eventListener options
     */
    public addStorageListening(
        callbackObj: any,
        targetWindow: Window = window,
        handler = this.handleStorageListening,
        options: AddEventListenerOptions = { capture: true }
    ) {
        if (this.checkStorageListening() || !callbackObj) {
            return;
        }
        if (typeof window === 'undefined' || !window.localStorage) {
            console.warn('Could not add storage listening.');
            return;
        }
        const prevEventCallbacks = Object.assign({}, this.eventCallbacks);
        if (callbackObj) {
            this.eventCallbacks = { ...this.eventCallbacks, ...callbackObj };
        }
        if (!Object.keys(prevEventCallbacks).length) {
            const selectedWindow = targetWindow || window;
            selectedWindow.addEventListener('storage', handler, options || false);
        }
        if (!this.checkStorageListening()) {
            // Add mark for listener check in case of not gameplay gems purchase
            this.setItem(LS_LISTENER_IS_ON_PROP, true);
        }
    }

    /**
     * Check id localStorage event listener is on
     */
    public checkStorageListening() {
        return Boolean(this.getItem(LS_LISTENER_IS_ON_PROP));
    }

    /**
     * Removes a localStorage event listener
     *
     * @remarks
     * Safari Incognito is not supported
     * @param handler - the useCallback or regular function referenced
     * @param options - eventListener options
     */
    public removeStorageListening(
        callbackEventName: string,
        targetWindow: Window = window,
        handler = this.handleStorageListening,
        options: AddEventListenerOptions = { capture: true }
    ) {
        if (typeof window === 'undefined' || !window.localStorage) {
            console.warn('Could not remove storage listening.');
            return;
        }
        if (callbackEventName && this.eventCallbacks[callbackEventName]) {
            delete this.eventCallbacks[callbackEventName];
            this.removeItem(callbackEventName);
        }
        if (!Object.keys(this.eventCallbacks).length) {
            const selectedWindow = targetWindow || window;
            selectedWindow.removeEventListener('storage', handler, options || false);
            // Remove mark for listener check in case of not gameplay gems purchase
            this.removeItem(LS_LISTENER_IS_ON_PROP);
        }
    }

    /**
     * Handles a localStorage event listener when it's added, actual logic is in ./LocalStorageListenerLogic.ts
     */
    public async handleStorageListening(ev: StorageEvent) {
        const { key, newValue, oldValue } = ev;
        if (this.getItem(key) === undefined || newValue === oldValue) {
            return;
        }
        const keyUnprefixed = key.replace(new RegExp(`^${this.prefix}`, 'gi'), '');
        if (
            Object.keys(this.eventCallbacks).some((cb) => cb === keyUnprefixed) &&
            this.eventCallbacks.hasOwnProperty(keyUnprefixed) &&
            typeof this.eventCallbacks[keyUnprefixed] === 'function'
        ) {
            try {
                await this.eventCallbacks[keyUnprefixed](ev);
            } catch (err) {
                console.error('Could not execute storage listening handler function.', err);
            }
        }
    }

    constructor() {
        this.getItem = this.getItem.bind(this);
        this.setItem = this.setItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.clear = this.clear.bind(this);
        this.checkStorageListening = this.checkStorageListening.bind(this);
        this.addStorageListening = this.addStorageListening.bind(this);
        this.removeStorageListening = this.removeStorageListening.bind(this);
        this.handleStorageListening = this.handleStorageListening.bind(this);
    }
}

export const LocalStorageService = new LocalStorageProvider();
