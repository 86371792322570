import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import UserService from '../../../../services/UserService';
import styles from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import { StillNeedHelp } from '../StillNeedHelp/StillNeedHelp';

export const ThankYou = React.memo(() => {
    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState('REGISTER_IF_YOU_DO_NOT_RECEIVE_EMAIL');
    const [disabledResend, setDisabledResend] = useState(false);

    const authDataContext = useContext(AuthDataContext);
    const userEmail = useSelector((state) => authDataContext.value.email || 'longemailaddress@email.com');
    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.PASSWORD_RESET);

    let timerId: any = -1;

    const handleResend = () => {
        if (userEmail) {
            setLoading(true);
            UserService.requestResetPassword(userEmail, captchaToken).then((res) => {
                setLoading(false);
                setDisabledResend(true);
                if (res === 200) {
                    setMessageKey('REGISTER_THE_EMAIL_HAS_BEEN_SENT_AGAIN');
                    setDisabledResend(true);
                    Analytics.trackEvent(Analytics.profile.resetPassword('start'));
                } else {
                    console.log(res);
                }
            });
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                setDisabledResend(false);
            }, 4000);
        }
    };

    const handleOk = () => {
        UserService.closePanel();
    };

    return (
        <RightPanelWrapper headerLabelKey="REGISTER_THANK_YOU">
            <div className={styles.subHeader}>
                <I18nText keyName={'REGISTER_WE_SENT_AN_EMAIL_TO'} />{' '}
                <span className={styles.subHeaderEmail}>{userEmail}</span>{' '}
                <I18nText keyName={'REGISTER_WITH_INSTRUCTIONS'} />
            </div>
            <div className={styles.receiveText}>
                <I18nText keyName={messageKey} />
            </div>
            <div className={classNames(styles.buttonsRow, styles.resendBtnsRow)}>
                <Button outlined loading={loading} onClick={handleResend} disabled={disabledResend}>
                    <I18nText keyName={'REGISTER_RESEND'} />
                </Button>
                <Button onClick={handleOk}>
                    <I18nText keyName={'REGISTER_OK'} />
                </Button>
            </div>
            <StillNeedHelp />
        </RightPanelWrapper>
    );
});
ThankYou.displayName = 'ThankYou';
