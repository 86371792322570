import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Checkbox } from '../../../../atoms/Checkbox/Checkbox';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { CheckboxTypes } from '../../../../atoms/Icons/Styleguide/CheckboxIcon';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Divider } from '../../../../molecules/Divider/Divider';
import {
    PASSWORD_VALIDATION_INSTRUCTIONS_IDS,
    ValidationHints,
} from '../../../../molecules/ValidationHints/ValidationHints';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import UserService, { AuthType, ERRORS_DESCRIPTION } from '../../../../services/UserService';
import { setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import { SocialButtons } from '../../../SocialButtons/SocialButtons';
import stylesCommon from '../RightPanelCommonStyles/RightPanelCommonStyles.css';
import { RightPanelWrapper } from '../RightPanelWrapper/RightPanelWrapper';
import styles from './SignUp.css';

const SignUp = React.memo(() => {
    const [checkBox, setCheckbox] = useState(true);
    const [loading, setLoading] = useState(false);
    const authDataContext = useContext(AuthDataContext);
    const categoryPageName = useSelector((state) => state.categoryPageName);
    const gameArkadiumSlug = useSelector((state) => state.gameArkadiumSlug);
    const games = useSelector((state) => state.games);

    const game = gameArkadiumSlug
        ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
        : '';

    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required(TranslationService.translateIt('REGISTER_AN_EMAIL_ADDRESS_IS_REQUIRED'))
            .email(TranslationService.translateIt('REGISTER_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS')),
        password: yup
            .string()
            .required('Password is required')
            .matches(
                /^[A-Za-z0-9!@#$%^&*()_[\]{},.<>+=-]*$/,
                TranslationService.translateIt('REGISTER_YOUR_PASSWORD_CONTAINS')
            )
            .min(8)
            .matches(/(.*[A-Z].*)/, ' '),
    });

    const defaultValues = {
        email: authDataContext.value.email || '',
        password: '',
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();
    const { email, password } = values;

    // //need to upd args recaptcha
    // console.log(captchaToken);

    const onSubmit = () => {
        setLoading(true);
        UserService.getUserByEmail(email)
            // if the email exist, then go to the sign in screen
            .then(() => {
                dispatch(
                    setSnackbarData({
                        isOpened: true,
                        message: ERRORS_DESCRIPTION.UserAlreadyExist,
                        type: 'error',
                        parentNode: 'rightSideMenu',
                    })
                );
                setLoading(false);
                // set email to put this on the SIGN_IN screen
                authDataContext.setAuthData({ ...authDataContext.value, email, password, authProvider: AuthType.EmailPassword });
                dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
            })
            // if the email does not exist, then check password and go to the next step
            .catch(() => {
                UserService.checkPasswordComplexity({ password })
                    .then((data) => {
                        setLoading(false);
                        const isPasswordTooEasy = data.result;
                        if (isPasswordTooEasy) {
                            dispatch(
                                setSnackbarData({
                                    isOpened: true,
                                    message: ERRORS_DESCRIPTION.PasswordTooEasy,
                                    type: 'error',
                                    parentNode: 'rightSideMenu',
                                })
                            );
                        } else {
                            // if password is not too easy then go to the next step - PersonalizeProfile screen
                            authDataContext.setAuthData({
                                ...authDataContext.value,
                                authProvider: AuthType.EmailPassword,
                                email,
                                password,
                                checkBox,
                            });
                            dispatch(setSignInUpState(SignInUpComponents.PERSONALIZE_PROFILE));
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        dispatch(
                            setSnackbarData({
                                isOpened: true,
                                message: err,
                                type: 'error',
                                parentNode: 'rightSideMenu',
                            })
                        );
                    });
            });
    };

    useEffect(() => {
        // we are making this request here
        // to improve the UX on the PERSONALIZE_PROFILE screen and not wait for a response from the request there
        UserService.generateUserName().then((name) => {
            authDataContext.setAuthData({ ...authDataContext.value, name, authProvider: AuthType.EmailPassword });
        });
    }, []);

    const getBenefits = () => {
        return (
            <ul className={styles.signUpBenefits}>
                <li>
                    <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Play 100+ games" />
                    <p>Play 100+ games</p>
                </li>
                <li>
                    <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Save your high scores" />
                    <p>Save your high scores​</p>
                </li>
                <li>
                    <img width={24} height={24} src={'/svg/feature_tick.svg'} alt="Access to special promotions" />
                    <p>Access to special promotions</p>
                </li>
            </ul>
        );
    };

    return (
        <>
            <RightPanelWrapper
                headerLabelKey={'Create your profile'}
                topBlock={
                    <img
                        style={{ marginBottom: 20 }}
                        src={`${UrlService.toCDNUrl('/images/signUpHeader.png')}`}
                        alt="sign_up_avatars"
                    />
                }
                fullWidth
            >
                <div className={styles.wrapper}>
                    <I18nText
                        className={classNames(stylesCommon.subHeader, styles.subHeader)}
                        keyName={'Enter an email and password below to ​create your free profile'}
                    />
                    {getBenefits()}
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={stylesCommon.formContent}
                    style={{ marginTop: '30px' }}
                >
                    <div className={styles.inputWrapper}>
                        <Input
                            required
                            className={stylesCommon.input}
                            isValid={!errors.email}
                            ariaInvalid={errors.email ? 'true' : 'false'}
                            register={register}
                            name="email"
                            type="text"
                            placeholder="Enter your email address"
                            value={email}
                            showError={errors?.email}
                            errorMessage={errors?.email?.message ?? ''}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <Input
                            required
                            className={stylesCommon.input}
                            isValid={!errors.password}
                            ariaInvalid={errors.password ? 'true' : 'false'}
                            register={register}
                            name="password"
                            type="password"
                            placeholder="Enter or create your password"
                            value={password}
                            ariaDescribedBy={PASSWORD_VALIDATION_INSTRUCTIONS_IDS}
                        />
                        <ValidationHints password={password} />
                    </div>
                    <Checkbox
                        className={styles.checkboxContainer}
                        iconClassName={styles.checkbox}
                        labelClassName={styles.checkboxLabel}
                        onClick={() => {
                            setCheckbox(!checkBox);
                            if (!checkBox) {
                                Analytics.trackEvent(Analytics.profile.emailOptIn());
                            }
                        }}
                        state={checkBox ? CheckboxTypes.ACTIVE : CheckboxTypes.INACTIVE}
                        caption={TranslationService.translateIt('REGISTER_EMAIL_ME')}
                        dataElementDescription="radio-button"
                        btnType="button"
                    />

                    <Button loading={loading} disabled={!isValid} type="submit" className={styles.submitButton}>
                        <I18nText keyName="Submit" as="span" />
                    </Button>
                    <Divider className={styles.dividerBlock} />
                    <SocialButtons
                        className={styles.socialButtons}
                        game={game}
                        category={categoryPageName}
                        checkBox={checkBox}
                        errorMessageSocial={(res) => {
                            dispatch(
                                setSnackbarData({
                                    isOpened: true,
                                    message: UserService.errorCodeToText(res),
                                    type: 'error',
                                    parentNode: 'rightSideMenu',
                                })
                            );
                        }}
                    />
                    <div className={styles.policy}>
                        <a target="_blank" href={UrlService.createURL('/privacy-policy/')} rel="noreferrer noopener">
                            <I18nText keyName={'REGISTER_PRIVACY_POLICY'} />
                        </a>
                    </div>
                </form>
            </RightPanelWrapper>
        </>
    );
});
export default SignUp;
