import React from 'react';

import { TFunction, WithTranslation, withTranslation } from 'react-i18next';
import { Action, Dispatch } from 'redux';

import { DeviceUtils } from '../../../../utils/DeviceUtils';
import { IGame } from '../../../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../../models/PagesData';
import { CategoryCard } from '../../../molecules/Cards/CategoryCard/CategoryCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { PageService } from '../../../services/PageService';
import { setSideMenuOpened, setSideMenuRequest } from '../../../store/ducks/layout';
import { SearchResultList } from '../SearchResultList/SearchResultList';
import styles from './CategorySearchTab.css';

// TODO: remove below code if no need
// let inputSearchTimer: any = -1;
//
// function onChangeHandler(setSearchValue: (v: string) => void, value: string) {
//     clearTimeout(inputSearchTimer);
//     setSearchValue(value);
//     inputSearchTimer = setTimeout(() => {
//         if (value && value.length >= 2 && value.length <= 50) {
//             Analytics.trackEvent(Analytics.general.searchBoxQuery('search', value));
//         }
//     }, 2000);
// }

type CategorySearchTabProps = {
    games: IGame[];
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    currentLang: string;
    currentRequest: string;
    extendMediaQuery: boolean;
    searchValue: string;
    dispatch?: Dispatch<Action>;
} & WithTranslation;

export const CategorySearchTab = withTranslation()(
    React.memo((props: CategorySearchTabProps) => {
        const { games, pages, currentLang, currentRequest, extendMediaQuery, searchValue = '', dispatch, t } = props;
        const CATEGORIES_LIST = PageService.getSidebarLevelMenuItems(pages, currentLang, games).map((p) => p.name);
        const searchValueRef = DeviceUtils.isStyleguideMobileMode() ? currentRequest : searchValue;
        const cats = filterCategories(CATEGORIES_LIST, searchValueRef);
        const hasSearchResult = searchValueRef.length > 0 && cats.length > 0;
        const renderItem = renderItemFn.bind(null, t, games, pages, currentLang);

        function renderItemFn(
            t: TFunction,
            games: IGame[],
            pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
            currentLang: string,
            data,
            index: number
        ) {
            const category = PageService.getPageSEOByName(pages, data, currentLang);

            return (
                <CategoryCard
                    key={index}
                    cardType={category.pageName}
                    categorySlug={category.slug[currentLang]}
                    caption={category.name[currentLang]}
                    currentLang={currentLang}
                    onClick={() => {
                        // TODO: HOMEPAGE pass real data
                        Analytics.trackEvent(Analytics.general.searchBoxClick('category', category.name[currentLang]));
                        dispatch(setSideMenuOpened(false));
                        dispatch(setSideMenuRequest(''));
                    }}
                />
            );
        }

        return (
            <>
                {hasSearchResult && (
                    <>
                        <SearchResultList
                            heading="CATEGORIES_TAB_DID_FIND"
                            items={cats}
                            renderListItem={renderItem}
                            className={styles.hasSearchResultHeading}
                            extendMediaQuery={extendMediaQuery}
                        />
                    </>
                )}
            </>
        );
    })
);

function filterCategories(categories: string[], text): string[] {
    return categories.filter((it) => {
        return it.toLowerCase().includes(text.toLowerCase());
    });
}

// TODO: remove below code if no need
// function findRelatedCategory(
//     categories: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
//     firstFoundCategory,
//     currentLang
// ): string[] {
//     const relatedPages = Object.keys(CategoriesRelations)
//         .filter((c) => c !== 'failed')
//         .map((item) => PageService.getPageSEOByPageName(categories, item));
//     const relatedPagesFiltered = relatedPages.filter((item) =>
//         item ? item.name[currentLang] === firstFoundCategory : []
//     );
//     if (relatedPagesFiltered.length) {
//         const relatedCategoriesLocalized = CategoriesRelations[relatedPagesFiltered[0].pageName].map(
//             (item) => PageService.getPageSEOByPageName(categories, item).name[currentLang]
//         );
//
//         return relatedCategoriesLocalized.filter((n) => n);
//     }
//
//     return [];
// }
