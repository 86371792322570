import React, { useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { TextField, TextFieldTypes } from '../../../atoms/TextField/TextField';
import { IGame } from '../../../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../../models/PagesData';
import { Analytics } from '../../../services/Analytics/Analytics';
import { TranslationService } from '../../../services/TranslationService';
import { CategorySearchTab } from '../CategorySearchTab/CategorySearchTab';
import { GamesSearchTab } from '../GamesSearchTab/GamesSearchTab';
import styles from '../GamesSearchTab/GamesSearchTab.css';

let inputSearchTimer: any = -1;

function onChangeHandler(setSearchValue: (v: string) => void, value: string) {
    clearTimeout(inputSearchTimer);
    setSearchValue(value);
    inputSearchTimer = setTimeout(() => {
        if (value && value.length >= 2 && value.length <= 50) {
            Analytics.trackEvent(Analytics.general.searchBoxQuery('search', value));
        }
    }, 2000);
}

type SearchTabProps = {
    games: IGame[];
    pages: Array<GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO>;
    currentLang: string;
    currentRequest: string;
    extendMediaQuery: boolean;
    gamesSearchTab?: Array<string>;
};

export const SearchTab = React.memo((props: SearchTabProps) => {
    const [searchValue, setSearchValue] = useState('');
    const isSideMenuOpen = useSelector((state: any) => state.sideMenu.isOpened);
    const searchInputOnChange = (value: string): void => {
        onChangeHandler(setSearchValue, value);
    };

    return (
        <div
            className={classNames(styles.tabContainer, { [styles.extendMediaQuery]: props.extendMediaQuery })}
            role="region"
            aria-label="live filter"
        >
            <div className={styles.searchFieldContainer}>
                <TextField
                    className={styles.searchInput}
                    inputType={TextFieldTypes.SEARCH_INPUT}
                    value={searchValue}
                    onChange={searchInputOnChange}
                    placeholder={TranslationService.translateIt('SEARCH_GAME_AND_CATEGORIES_PLACEHOLDER')}
                    dataElementDescription="search-games"
                    focusOnLoad={true}
                    engageFocus={isSideMenuOpen}
                />
            </div>
            <div style={{ display: 'block' }}>
                <CategorySearchTab searchValue={searchValue} {...props} />
                <GamesSearchTab gamesSearchTab={props.gamesSearchTab} searchValue={searchValue} {...props} />
            </div>
        </div>
    );
});
