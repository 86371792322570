import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { detectIncognito } from 'detectincognitojs';

import { devConsole } from '../../../../utils/DevConsole';
import { LocalStorageService } from '../../LocalStorage';

class FingerprintServiceBase {
    lsPropName = 'FP_VISITOR';

    async init() {
        const result = JSON.stringify(await this.detect());
        LocalStorageService.setItem(this.lsPropName, result);
        return this.detected; // just to invoke dev log in console
    }

    async detect() {
        const detected = this.detected;
        const fingerprintId = await this.getFingerprint();
        const fpVisitorId =
            detected && detected?.fpVisitorId && detected.fpVisitorId !== fingerprintId // if already detected and stored
                ? detected.fpVisitorId // use old one
                : fingerprintId; // use new one as fallback
        const fpIncognito = await this.isIncognito();

        const result = { fpVisitorId, fpIncognito };
        devConsole('FingerprintService', 'detect!', result);
        return result;
    }

    get detected() {
        const detectedJSON = LocalStorageService.getItem(this.lsPropName);
        let detected = { fpVisitorId: 'unknown', fpIncognito: 'unknown' }; // fallback val
        try {
            detected = detectedJSON ? JSON.parse(detectedJSON) : null;
        } catch (e) {
            console.log(e);
        }
        devConsole('FingerprintService', 'get detected', detected);
        return detected;
    }

    async getFingerprint() {
        // Initialize an agent at application startup.
        const fingerprintPromise = FingerprintJS.load();
        const FP = await fingerprintPromise;
        const result = await FP.get();
        return result?.visitorId;
    }

    async isIncognito() {
        const result = await detectIncognito();
        return result?.isPrivate;
    }
}

export const FingerprintService = new FingerprintServiceBase();
