import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { FlagEn } from '../../atoms/Icons/Styleguide/Flags/FlagEnNew';
import { FlagFr } from '../../atoms/Icons/Styleguide/Flags/FlagFrNew';
import { PageTypes } from '../../constants/Pages';
import { GameService } from '../../services/GameService';
import { PageService } from '../../services/PageService';
import { UrlService } from '../../services/UrlService';
import { SrOnly } from '../SrOnly/SrOnly';
import styles from './LanguageSwitcher.css';

const switcherItems = {
    en: {
        label: 'en',
        icon: <FlagEn />,
    },
    fr: {
        label: 'fr',
        icon: <FlagFr />,
    },
};

export const LanguageSwitcher = () => {
    const currentLang = useSelector((state) => state.currentLang);
    const gameArkadiumSlug = useSelector((state) => state.gameArkadiumSlug);
    const games = useSelector((state) => state.games);
    const pages = useSelector((state) => state.pages);
    const categoryPageName = useSelector((state) => state.categoryPageName);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);
    const pageType = useSelector((state) => state.pageType);

    const [currentItem, setCurrentItem] = useState(null);
    const [isFrSupported, setIsFrSupported] = useState(true);

    const DEFAULT_LANG = 'en';
    const ADDITIONAL_LANG = 'fr';

    useEffect(() => {
        const keyValue = currentLang == ADDITIONAL_LANG ? ADDITIONAL_LANG : DEFAULT_LANG;
        setCurrentItem(switcherItems[keyValue]);
        //TODO: Note about game - for checking into GameService game prop should be IGame type
        if (pageType == PageTypes.Game && gameArkadiumSlug !== null) {
            const game = GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug);
            const gameFRLangSupported = GameService.isLanguageSupportedByGame(game, ADDITIONAL_LANG);
            setIsFrSupported(gameFRLangSupported);
        }
        if (
            pageType == PageTypes.Dynamic ||
            pageType == PageTypes.BlogArchive ||
            pageType == PageTypes.BlogPost ||
            categoryPageName === categoryPromoData?.categoryName
        ) {
            setIsFrSupported(false);
        }
    }, [categoryPageName]);

    /**
     * Get new URL after switch on/off and set URL
     *
     * @param {string} toggledLang
     */
    const switchLanguage = (toggledLang) => {
        let url = null;
        url = UrlService.createURL(toggledLang === ADDITIONAL_LANG ? `/${ADDITIONAL_LANG}/` : '/', true);
        const langPrefix = toggledLang === DEFAULT_LANG ? `` : `/${toggledLang}`;

        if ((pageType === PageTypes.Category || pageType === PageTypes.AllCategories) && categoryPageName) {
            const currentCategory = PageService.getPageSEOByPageName(pages, categoryPageName);
            const slug = currentCategory.slug[toggledLang];
            let path;
            const pathToCategory = UrlService.getPageLocalizedRoute(toggledLang, pageType);

            if (pageType === PageTypes.Category && categoryPageName !== 'All') {
                path = `${langPrefix}/${pathToCategory}/${slug}/`;
            } else {
                path = `${langPrefix}/${pathToCategory}/`;
            }

            url = UrlService.createURL(path, true);
        }
        if (pageType === PageTypes.Game) {
            const path = `${langPrefix}/games/${gameArkadiumSlug}/`;
            url = UrlService.createURL(path, true);
        }

        if (pageType === PageTypes.Profile) {
            const path = `${langPrefix}/profile/`;
            url = UrlService.createURL(path, true);
        }

        if (pageType === PageTypes.PrivacyPolicy) {
            const path = `${langPrefix}/privacy-policy/`;
            url = UrlService.createURL(path, true);
        }

        if (pageType === PageTypes.AboutUs) {
            const pageSeo = PageService.getPageSEOByPageName(pages, 'About Us');
            const path = `${langPrefix}/${pageSeo.slug[toggledLang]}/`;
            url = UrlService.createURL(path, true);
        }

        window.location.href = url;
    };
    /**
     * Main handler for switch language (if supported)
     *
     */
    const handleToggleLang = () => {
        if (isFrSupported) {
            const toggledLang = currentLang == ADDITIONAL_LANG ? DEFAULT_LANG : ADDITIONAL_LANG;
            setCurrentItem(switcherItems[toggledLang]);
            //Timeout for animation
            setTimeout(() => {
                switchLanguage(toggledLang);
            }, 200);
        }
    };

    return (
        <>
            <SrOnly />
            <div
                role="button"
                tabIndex={0}
                className={classNames(
                    styles.container,
                    currentItem?.label == ADDITIONAL_LANG && styles.switched,
                    !isFrSupported && styles.disabled
                )}
                onClick={() => {
                    handleToggleLang();
                }}
                onKeyDown={(event) => {
                    const { key } = event;
                    if (key === ' ' || key === 'Spacebar') {
                        event.preventDefault();
                        handleToggleLang();
                    }
                }}
            >
                <div className={styles.langLabel} lang={currentLang}>
                    {currentItem?.label}
                </div>
                <div className={styles.flagCircle}>{currentItem?.icon}</div>
            </div>
        </>
    );
};
