import { PlatformType, UserSubscription } from '../models/Subscription/SubscriptionData';
import { SubscriptionActions } from '../templates/Profile/Tabs/PaymentAndSubscription/PaymentAndSubscription';

export function getLastDateSubscription(subscriptions: UserSubscription[]): UserSubscription {
    const sortedSubscription = subscriptions.sort(function (a, b) {
        return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
    });
    return sortedSubscription[0];
}

export function getFirstDateSubscription(subscriptions: UserSubscription[]): UserSubscription {
    const sortedSubscription = subscriptions.sort(function (a, b) {
        return (new Date(a.startDate) as any) - (new Date(b.startDate) as any);
    });
    return sortedSubscription[0];
}

export function removeInternal(subscriptions: UserSubscription[]): UserSubscription[] {
    return subscriptions.filter((item) => item.platform.id !== PlatformType.INTERNAL);
}

export function getType(
    activeSubscriptions: UserSubscription[],
    expiredSubscriptions?: UserSubscription[]
): SubscriptionActions {
    let type: SubscriptionActions;
    // no active subscriptions
    if (activeSubscriptions?.length === 0) {
        if (expiredSubscriptions?.length > 0) {
            type = SubscriptionActions.RESTART;
        } else {
            type = SubscriptionActions.NONE;
        }
    }

    // 1 active subscription
    if (activeSubscriptions?.length === 1) {
        if (activeSubscriptions[0].platform.id === PlatformType.RECURLY) {
            if (activeSubscriptions[0].isAutoRenewable === true) {
                type = SubscriptionActions.STANDART;
            } else {
                type = SubscriptionActions.RENEW;
            }
        } else {
            type = SubscriptionActions.SUBSCRIBED_EXTERNAL;
        }
    }

    // more than one active subscriptions
    if (activeSubscriptions?.length > 1) {
        let numberOfRecurlySubscriptions = 0;
        let numberOfOtherSubscriptions = 0;
        activeSubscriptions.forEach(({ platform }) => {
            if (platform?.id === PlatformType.RECURLY) {
                numberOfRecurlySubscriptions++;
            } else {
                numberOfOtherSubscriptions++;
            }
        });
        if (numberOfRecurlySubscriptions > 0 && numberOfOtherSubscriptions > 0) {
            type = SubscriptionActions.SUBSCRIBED_BOTH;
        } else if (numberOfOtherSubscriptions === 0) {
            type = SubscriptionActions.STANDART;
        } else if (numberOfRecurlySubscriptions === 0) {
            type = SubscriptionActions.SUBSCRIBED_EXTERNAL;
        }
    }
    return type;
}
