import { IGame } from '../../../models/Game/Game';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';


export class GamesAnalyticsAi {
    async gameStart(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelContentStart');
        event.setNonInteraction(false);
        await event.setGame(game);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async funnelZero(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelPlayNow');
        event.setNonInteraction(false);
        await event.setGame(game);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async gameEnd(game: IGame, duration: number): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelContentEnd');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setCustom('eventDuration', duration);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async prerollStart(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelPrerollRequest');
        event.setNonInteraction(true);
        await event.setGame(game);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async prerollEnd(game: IGame, duration: number): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelPrerollEnd');
        event.setNonInteraction(true);
        await event.setGame(game);
        event.setCustom('eventDuration', duration);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async rewardVideoStart(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelRewardRequest');
        event.setNonInteraction(true);
        await event.setGame(game);
        return event.build();
    }

    async rewardVideoEnd(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelRewardEnd');
        event.setNonInteraction(true);
        await event.setGame(game);
        return event.build();
    }

    async interstitialVideoStart(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelInterstitialRequest');
        event.setNonInteraction(true);
        await event.setGame(game);
        return event.build();
    }

    async interstitialVideoEnd(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelInterstitialEnd');
        event.setNonInteraction(true);
        await event.setGame(game);
        return event.build();
    }

    async allGamesClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelEndButtonAllGames');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async adblockImpression(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('adBlockPopup');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        await event.setGame(game);
        return event.build();
    }

    async adblockClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('adBlockPopup');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async adblockDisabled(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('adBlockPopup');
        event.setEventAction(AnalyticsEventAction.ADBLOCKDISABLED);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async playAgainClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('funnelEndButtonPlayAgain');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        AnalyticsEventBuilder.recommendedEventBuilder(event);
        AnalyticsEventBuilder.playAgainStartEventBuilder(event);
        return event.build();
    }

    async saveScoreClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('saveScore');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async gameNavigationLeaderboardIconClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('Leaderboard');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async recommendedGamesImpression(game: IGame, games: any): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('recommendedGames');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(true);
        await event.setGame(game);
        event.setCustom('recommendedGamesImpression', games.join('|').toLowerCase());
        return event.build();
    }

    async gamePageFeedbackImpression(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gamepageFeedback');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        await event.setGame(game);
        event.setNonInteraction(true);
        return event.build();
    }

    async gamePageFeedbackClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gamepageFeedback');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async gamePageAdDividerImpression(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gameLisencing');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        await event.setGame(game);
        event.setNonInteraction(true);
        return event.build();
    }

    async gamePageAdDividerClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('gameLisencing');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async recommendedGamesImpressionNew(game: IGame, games: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('recommendedGames');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        await event.setGame(game);
        event.setCustom('recommendedGamesImpression', games);
        event.setNonInteraction(true);
        return event.build();
    }

    async recommendedGamesClickNew(game: IGame, clickedOnGame: string): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('recommendedGames');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setCustom('recommendedGamesClick', clickedOnGame);
        event.setNonInteraction(false);
        return event.build();
    }

    async earlyAccessJoinButton(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('earlyAccessJoinButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async earlyAccessJoinButtonImpression(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('earlyAccessJoinButton');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        await event.setGame(game);
        event.setNonInteraction(true);
        return event.build();
    }

    async numberTimesPlay(game: IGame, times): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('numberTimesPlay');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setCustom('times', times);
        return event.build();
    }

    async numberPlayVisits(game: IGame, visits): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('numberPlayVisits');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setCustom('visits', visits);
        return event.build();
    }

    async fullScreenButtonClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('fullScreenButton');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setEventAction(AnalyticsEventAction.CLICK);
        return event.build();
    }

    async fullScreenExitButtonClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('fullScreenExitButton');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setEventAction(AnalyticsEventAction.CLICK);
        return event.build();
    }

    async fullScreenToolTipImpression(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('fullScreenToolTip');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        return event.build();
    }

    async fullScreenToolTipClick(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('fullScreenToolTip');
        event.setNonInteraction(false);
        await event.setGame(game);
        event.setEventAction(AnalyticsEventAction.CLICK);
        return event.build();
    }

    async sendEmote(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('sendEmote');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async returnEmote(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('returnEmote');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async sendEmoteExpire(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('sendEmoteExpire');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        await event.setGame(game);
        event.setNonInteraction(true);
        return event.build();
    }

    async gameNavigationCollectionButton(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('collectionsButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async visitCollection(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('visitCollection');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async saveCollectible(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('saveCollectible');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async receivedCollectible(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('receivedCollectible');
        event.setEventAction(AnalyticsEventAction.IMPRESSION);
        event.setNonInteraction(false);
        await event.setGame(game);
        return event.build();
    }

    async commentsButton(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('commentsButton');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async commentSignInUp(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('commentSignInUp');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    async commentSubmit(game: IGame): Promise<AnalyticsEvent> {
        const event = await this.newEventBuilder();
        event.setName('commentSubmit');
        event.setEventAction(AnalyticsEventAction.CLICK);
        await event.setGame(game);
        event.setNonInteraction(false);
        return event.build();
    }

    private async newEventBuilder(): Promise<AnalyticsEventBuilder> {
        return await new AnalyticsEventBuilder().baseEvent();
    }
}
