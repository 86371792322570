import { PageTypes } from '../../constants/Pages';
import { GemsAnalyticsShopLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { GemsAnalyticsAi, GemsDimensionsMocks } from './AI/GemsAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { GemsAnalyticsGa } from './GA/GemsAnalyticsGa';

export class GemsAnalytics {
    aiAnalytics = new GemsAnalyticsAi();
    gaAnalytics = new GemsAnalyticsGa();

    async gemShopImpression(shopLocation: GemsAnalyticsShopLocations | PageTypes): Promise<AnalyticsEventsPair> {
        // when user sees gem shop in sidebar
        return await makeEventsPair(null, this.aiAnalytics.gemShopImpression(shopLocation));
    }
    async gemPurchaseClick(
        shopLocation: GemsAnalyticsShopLocations | PageTypes | string,
        internalPrice,
        purchasableItemId,
        purchasableItemAmount
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEventsPair> {
        // when user clicks on gem card to purchase
        return await makeEventsPair(
            null,
            this.aiAnalytics.gemPurchaseClick({
                shopLocation,
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            })
        );
    }
    async gemPurchaseClickImpression(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEventsPair> {
        // when user sees purchase step 1
        return await makeEventsPair(
            null,
            this.aiAnalytics.gemPurchaseClickImpression({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            })
        );
    }
    async gemPurchaseNextStepClick(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEventsPair> {
        // when user clicks on submit on step 1
        return await makeEventsPair(
            null,
            this.aiAnalytics.gemsPurchaseNextStepClick({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            })
        );
    }
    async gemPurchaseNextStepImpression(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEventsPair> {
        // when user sees purchase step 2
        return await makeEventsPair(
            null,
            this.aiAnalytics.gemsPurchaseNextStepImpression({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            })
        );
    }
    async gemPurchaseChangeGems(
        shopLocation: GemsAnalyticsShopLocations | PageTypes,
        internalPrice,
        purchasableItemId,
        purchasableItemAmount
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEventsPair> {
        // when user clicks on "update my gems pack" to change pack
        return await makeEventsPair(
            null,
            this.aiAnalytics.gemsPurchaseChangeGems({
                shopLocation,
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            })
        );
    }
    async gemPurchaseSuccess(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation,
        gameId?
    ): Promise<AnalyticsEventsPair> {
        // when user clicks on "confirm" to finalize purchase
        return await makeEventsPair(
            this.gaAnalytics.gemsPurchaseSuccess(purchasableItemId),
            this.aiAnalytics.gemsPurchaseSuccess({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                gameId,
            })
        );
    }
    async gemPurchaseSuccessImpression(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation,
        gameId?
    ): Promise<AnalyticsEventsPair> {
        // when user sees successful purchase screen
        return await makeEventsPair(
            null,
            this.aiAnalytics.gemsPurchaseSuccessImpression({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                gameId
            })
        );
    }

    // GAME SPENDING GEMS

    async gemSkipAdButton(internalPrice): Promise<AnalyticsEventsPair> {
        // when user clicks on btn to pass ad for gem
        return await makeEventsPair(null, this.aiAnalytics.gemSkipAdButton({ internalPrice }));
    }
    async gemSpendingSkipAdSuccess(internalPrice): Promise<AnalyticsEventsPair> {
        // when user spent gem and skipped ad
        return await makeEventsPair(null, this.aiAnalytics.gemSpendingSkipAdSuccess({ internalPrice }));
    }
    async gemSpendingPowerUp(virtualItemId, internalPrice): Promise<AnalyticsEventsPair> {
        // when user spent gem for in-game boost or power-up
        return await makeEventsPair(null, this.aiAnalytics.gemSpendingPowerUp({ virtualItemId, internalPrice }));
    }

    // Advantage subscriptions marketing

    async gemShopSignInUp(registrationLocation, isRegistration = false): Promise<AnalyticsEventsPair> {
        // when user signed in in GEM SHOP TAB... UPD: any register location
        return await makeEventsPair(null, this.aiAnalytics.gemShopSignInUp(registrationLocation, isRegistration));
    }
    async gemShopSubscribeBanner(): Promise<AnalyticsEventsPair> {
        // when user clicked AA promo banner in GEM SHOP TAB
        return await makeEventsPair(null, this.aiAnalytics.gemShopSubscribeBanner());
    }
    async manageGems(shopLocation: GemsAnalyticsShopLocations | PageTypes): Promise<AnalyticsEventsPair> {
        // when user clicked on 'manage gems' in GEM SHOP TAB => check purchases in profile for now
        return await makeEventsPair(null, this.aiAnalytics.manageGems(shopLocation));
    }
}
