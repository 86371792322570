export enum GameState {
    GAME,
    GAME_END,
    GAME_SCORE_SAVED,
    PENDING,
    PREROLL,
    COVER,
    ADBLOCK,
    REWARD,
    INTERSTITIAL,
    PREROLL_PLAYING,
}

export enum ARK_MESSAGES {
    END_PREROLL = 'arkadium:endPreroll',
    SET_GAME_TYPE = 'arkadium:setGameType',
}

export enum WIDTH_QUERY {
    MIN_WIDTH = 'min-width',
    MAX_WIDTH = 'max-width',
}

export enum LS_COOKIE_CONSTS {
    ADO_BUILD_ID = 'ado_build_id',
    IS_USER_ACCEPTED_PRIVACY_POLICY = 'is_user_accepted_privacy_policy',
    ARK_USER_AGREE_COOKIES_PRIVACY_POLICY = 'ark-user-agree-cookies-privacy-policy',
    FTUX_FIRST_VISIT = 'FTUXfirstvisit',
    GAME_APP_BANNER = 'GameAppBanner',
    ADBLOCK_TRACKER = 'adblock_tracker',
    TARGETING_CONSENT = 'targetingConsent',
    HIGH_SCORE = 'high_score',
    PE_SUB_DATA = 'PeSubData',
    RECENTLY_PLAYED = 'recently_played',
    TOKEN_EXPIRATION_TIME = 'ark_uup_token_expiration_time',
    GAME_PLAY_NUM = 'gamePlayNum',
    GAME_PLAY_VISIT = '_ark_gamePlayVisit',
    LAST_TIME_GAME_PLAYED = '_ark_gamePlayVisitTime',
    ARK_PC_OVERALL_GAMES_PLAYED = '_ark_pc_overallGamesPlayed',
    ARK_PC_LAST_GAME_PLAYED = '_ark_pc_lastGamePlayed',
    TIC_TAC_TOE_AB_TEST_SETTINGS = 'tic-tac-toe-ab-test-settings',
    USER_CHANGED_NAME = 'userChangedName',
    ARK_VISITOR_MAIN = 'ark_visitor_main',
    ARK_USER_FIRST_VISIT_2 = 'arkUserFirstVisit2',
    ARK_USER_FIRST_LOAD = 'arkUserFirstLoad',
    GA = '_ga',
    AD_FREE_VER = 'ark_adfree',
    AD_FREE_AVATAR = 'adFreeAv',
    AD_FREE_SPECIAL = 'adFreeSp',
    SUBSCRIPTION = 'ark_subscription',
    GAME_END_RESTART = 'ark_gameends',
    ARK_AFFILIATE = 'ark_affiliate',
    GAME_SLUG_FOR_SUBSCRIPTION_PURCHASE = 'game_slug_for_subscription_purchase',
    ARK_AFFILIATE_STORE_DAYS = 180, // how many days we store affiliate cookie
    SUBSCRIPTION_ID = 'ark_subscription_val',
    CATEGORY_PROMO_STATUS = 'promo_status',
}

export enum QUERY_STRING_CONSTS {
    GCLID = 'gclid',
    FB_CLID = 'fbclid',
    FB_ID = 'fbid',
    ARK_PROMO = 'arkpromo',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    FILENAME = 'filename',
    TYPE = 'type',
    ARKADS = '__arkads',
    ARKVER = '__arkver',
    ARKLOGIN = 'arklogin',
    ADD_FREE = 'ark_adfree',
    SUBSCRIPTION = 'ark_subscription',
    REDEMPTION_CODE = 'redemption_code',
    SUBSCRIPTION_PLAN = 'subscription_plan',
    FULL_SCREEN_TEST = 'fullscreen',
    ARK_AFFILIATE = 'affiliate',
    ARK_AFFILIATE_ID = 'affiliate_id',
    BLOG_ADS_TEST = 'blogAds',
}

export enum ADBLOCK_TRACKER_STATUSES {
    BLOCKED = 'blocked',
    EVENT_SENT = 'eventsent',
}

export enum IframeMessageTypes {
    PASS_GAME_DATA = 'PASS_GAME_DATA',
    UPDATE_LOCAL_STORAGE = 'UPDATE_LOCAL_STORAGE',
    REWARD_CALLBACK = 'REWARD_CALLBACK',
    INTERSTITIAL_CALLBACK = 'INTERSTITIAL_CALLBACK',
    RESIZE_CONTAINER = 'RESIZE_CONTAINER',
    SET_LS_ITEM = 'SET_LS_ITEM',
    REMOVE_LS_ITEM = 'REMOVE_LS_ITEM',
    AUTH_API_EVENT = 'AUTH_API_EVENT',
}

export enum IframeAuthApiCallbackActions {
    CHECK_AUTH = 'CHECK_AUTH',
}

export enum DIALOG_CONTENT_TYPES {
    EMPTY,
    SUMMER_SWEEPSTAKES,
    CATEGORY_PROMO_DIALOG,
}

export enum LOCALSTORAGE_ITEMS {
    AUTH_PROVIDER = 'authProvider',
    VIRTUAL_ITEM_SPENT = 'virtualItemSpent'
}