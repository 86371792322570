import React, { ElementType } from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { Helmet } from 'react-helmet-async';
import { batch, connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { HelpTab } from './HelpTab/HelpTab';
import { HelpTabSuccess } from './HelpTabSuccess/HelpTabSuccess';
import styles from './NewHeaderSideMenu.css';
import { SearchTab } from './SearchTab/SearchTab';
import { ShopTab } from './ShopTab/ShopTab';
import { GRM } from '../../../modules/GameRenderingModule';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { ArrowRightIcon } from '../../atoms/Icons/Styleguide/ArrowRightIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { SearchIcon } from '../../atoms/Icons/Styleguide/SearchIcon';
import { ShopIcon } from '../../atoms/Icons/Styleguide/ShopIcon';
import { SupportIcon } from '../../atoms/Icons/Styleguide/SupportIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { NavBtnSideMenu } from '../../atoms/NavBtnSideMenu/NavBtnSideMenu';
import { TextField, TextFieldTypes } from '../../atoms/TextField/TextField';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageTypes } from '../../constants/Pages';
import { SubscriptionTabIcon } from '../../FigmaStyleguide/Icons/SubscriptionTabIcon';
import { IGame } from '../../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from '../../models/PagesData';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { TabRouter } from '../../molecules/TabRouter/TabRouter';
import { ABTestContext } from '../../services/ABTests/ABTestReact';
import { Analytics } from '../../services/Analytics/Analytics';
import { GameService } from '../../services/GameService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps, lsDispatchGamePurchaseRequestFail } from '../../services/LocalStorageListenerLogic';
import UserService from '../../services/UserService';
import { setSideMenuActivePage, setSideMenuOpened, setSideMenuRequest } from '../../store/ducks/layout';
import { activePageSelector } from '../../store/ducks/layoutSelectors';
import { GemsAnalyticsShopLocations, setGemsShopLocation } from '../../store/ducks/leanplum/lpAnalytics';
import { setSubscriptionSource } from '../../store/ducks/subscription/common';
import { setMobileNavigationUxRedesignedActiveButton } from '../MobileNavigationUxRedesigned/MobileNavigationUxRedesign.ducks';

const LogInTab = loadable(() => MiscUtils.loadableRetry(() => import('./LogInTab/LogInTab'), { retries: 3 }));

const PagesMap = new Map<string, ElementType>();
PagesMap.set(HeaderSideMenuTabs.SHOP_TAB, ShopTab);
PagesMap.set(HeaderSideMenuTabs.SEARCH_TAB, SearchTab);
PagesMap.set(HeaderSideMenuTabs.HELP_TAB, HelpTab);
PagesMap.set(HeaderSideMenuTabs.SUBSCRIPTION_TAB, ShopTab);
PagesMap.set(HeaderSideMenuTabs.HELP_TAB_SUCCESS, HelpTabSuccess);
PagesMap.set(HeaderSideMenuTabs.LOG_IN_TAB, LogInTab);

type HeaderSideMenuProps = {
    isOpenedSideMenu: boolean;
    isOpenedMobileProgressBar: boolean;
    activePage: HeaderSideMenuTabs;
    currentRequest: string;
    games: IGame[];
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    currentLang: string;
    pageType: PageTypes;
    dispatch: any;
    gamesSearchTab: Array<string>;
    userRegistrationCheckbox: boolean;
    opensModalElementRef: HTMLButtonElement | null;
};

type HeaderSideMenuBaseState = {
    hydrateRerender: number;
    isOpenedMenu: boolean;
    isChatBotOpened: boolean;
    visibleTabs: HeaderSideMenuTabs[];
    isFocusTrapActive: boolean;
};

let inputSearchTimer: any = -1;

function onChangeHandler(value: string) {
    clearTimeout(inputSearchTimer);
    inputSearchTimer = setTimeout(() => {
        if (value && value.length >= 2 && value.length <= 50) {
            Analytics.trackEvent(Analytics.general.searchBoxQuery('search', value));
        }
    }, 2000);
}

function hideDocumentBodyScrollBar() {
    document.body.style.overflow = 'hidden';
}

function showDocumentBodyScrollBar() {
    document.body.style.overflow = 'visible';
}

const ANIMATION_DURATION = 500;

class NewHeaderSideMenuBase extends React.PureComponent<HeaderSideMenuProps, HeaderSideMenuBaseState> {
    readonly state = {
        hydrateRerender: 0,
        isFocusTrapActive: false,
        isOpenedMenu: false, // workaround for csstransitiongroup
        isChatBotOpened: false,
        visibleTabs: [
            HeaderSideMenuTabs.SHOP_TAB,
            HeaderSideMenuTabs.SUBSCRIPTION_TAB,
            HeaderSideMenuTabs.SEARCH_TAB,
            HeaderSideMenuTabs.HELP_TAB,
        ],
    };

    static contextType = ABTestContext;

    private contentContainerRef = React.createRef<HTMLElement>();
    private navigationRef = React.createRef<HTMLDivElement>();
    private mediaQueryWatcher: MediaQueryList;

    UNSAFE_componentWillReceiveProps(nextProps: HeaderSideMenuProps) {
        const { isOpenedSideMenu, activePage } = this.props;

        if (!isOpenedSideMenu && nextProps.isOpenedSideMenu) {
            if (
                [HeaderSideMenuTabs.HELP_TAB, HeaderSideMenuTabs.HELP_TAB_SUCCESS].indexOf(nextProps.activePage) === -1
            ) {
                Analytics.trackEvent(Analytics.general.searchBoxImpression(String(nextProps.activePage)));
            } else {
                Analytics.trackEvent(Analytics.newHome.searchNavSupportImpression());
            }
        }

        if (activePage !== nextProps.activePage || (!isOpenedSideMenu && nextProps.isOpenedSideMenu)) {
            if (this.contentContainerRef.current) {
                this.contentContainerRef.current.scrollTop = 0;
            }
        }
    }

    handleMediaQuery = (event) => {
        this.setState({ isFocusTrapActive: event.matches });
    };

    handleChatBotClose = (): void => {
        if (!window.fcWidget) return;

        window.fcWidget.close();
    };

    handleEscapeClick = ({ key }: KeyboardEvent) => {
        if (key !== 'Escape') return;

        this.handleChatBotClose();
    };

    componentDidMount() {
        this.mediaQueryWatcher = window.matchMedia(`(min-width:${ArkCssBreakpoints.ARK_SMALL_DESKTOP}px)`);
        this.setState({ isFocusTrapActive: this.mediaQueryWatcher.matches });

        this.mediaQueryWatcher.addEventListener('change', this.handleMediaQuery);

        const isUserLoggedIn = UserService.isUserLoggedIn();

        if (!isUserLoggedIn) {
            this.setState((prevState) => {
                return {
                    visibleTabs: [...prevState.visibleTabs, HeaderSideMenuTabs.LOG_IN_TAB],
                };
            });
        }
    }

    componentDidUpdate(prevProps: HeaderSideMenuProps) {
        const { isOpenedSideMenu, isOpenedMobileProgressBar, userRegistrationCheckbox } = this.props;

        if (window.fcWidget) {
            window.fcWidget.on('widget:opened', () => {
                this.setState({ isChatBotOpened: true });
                window.document.documentElement.classList.add(styles.noOverflowY);

                const privacyPolicyLabel = document.getElementById('qc-cmp2-persistent-link');
                if (!privacyPolicyLabel) {
                    return;
                }
                privacyPolicyLabel.setAttribute('style', 'z-index: 0 !important');
            });
            window.fcWidget.on('widget:closed', () => {
                this.setState({ isChatBotOpened: false });
                window.document.documentElement.classList.remove(styles.noOverflowY);

                const privacyPolicyLabel = document.getElementById('qc-cmp2-persistent-link');
                if (!privacyPolicyLabel) {
                    return;
                }
                privacyPolicyLabel.removeAttribute('style');
            });
        }

        if (isOpenedSideMenu || isOpenedMobileProgressBar || userRegistrationCheckbox) {
            hideDocumentBodyScrollBar();
        } else {
            showDocumentBodyScrollBar();
        }
        // The reason of that construction is this component is not unmounted.
        // So we need to catch a moment, when side modal will be closed,
        // and before it was opened. So this condition could be read as:
        // SIDE MENU IS CLOSED && BEFORE IT WAS OPENED
        // When we caught that moment, we remove eventListener and focus
        // element that triggered modal opening.
        if (isOpenedSideMenu === false && prevProps.isOpenedSideMenu !== isOpenedSideMenu) {
            this.navigationRef.current?.removeEventListener('keydown', this.handleArrowClick);
        }
        this.navigationRef.current?.addEventListener('keydown', this.handleArrowClick);
    }

    componentWillUnmount() {
        this.mediaQueryWatcher.removeEventListener('change', this.handleMediaQuery);
        this.unmountTrap();
    }

    searchInputOnChange = (value: string): void => {
        onChangeHandler(value);
    };

    handleArrowClick = ({ key }: KeyboardEvent) => {
        const { dispatch, activePage } = this.props;
        const { visibleTabs } = this.state;

        const currentTabIndex = visibleTabs.findIndex((item) => item === activePage);

        if (key === 'ArrowLeft') {
            if (currentTabIndex === 0) {
                return;
            }
            dispatch(setSideMenuActivePage(visibleTabs[currentTabIndex - 1]));
        } else if (key === 'ArrowRight') {
            if (currentTabIndex === visibleTabs.length - 1) {
                return;
            }
            dispatch(setSideMenuActivePage(visibleTabs[currentTabIndex + 1]));
        }
    };

    isTabActive = (tabName: string) => tabName === this.props.activePage;

    unmountTrap = () => this.setState({ isFocusTrapActive: false });

    /*
     * We need to wait till side panel animation will be finished.
     * Only after that it is safe to send focus event.
     * In another way, side panel will be opened without animation at all.
     * Check focus-trap-react docs for more details.
     */
    checkWhenFocusTrap = (): Promise<void> => {
        return new Promise((resolve) => {
            setTimeout(resolve, ANIMATION_DURATION);
        });
    };

    closeSideMenu = () => {
        const { dispatch, pageType } = this.props;

        if (pageType === PageTypes.Game) {
            GRM.makeGameResume();
            lsDispatchGamePurchaseRequestFail();
            LocalStorageService.removeStorageListening(LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
        }
        batch(() => {
            dispatch(setSideMenuOpened(false));
            setMobileNavigationUxRedesignedActiveButton(null);
        });
    };

    openShopOnClick = () => {
        const { dispatch } = this.props;

        batch(() => {
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
            dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.COMMON_HEADER_ICON));
        });
        LocalStorageService.setItem('shopOpenRequest', GemsAnalyticsShopLocations.COMMON_HEADER_ICON);
    };

    openSubscriptionOnClick = () => {
        const { dispatch } = this.props;

        batch(() => {
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SUBSCRIPTION_TAB));
            dispatch(setSubscriptionSource(SubscriptionSource.SIDEBAR_TOP));
        });
    };

    render() {
        const isUserLoggedIn = UserService.isUserLoggedIn();
        const {
            isOpenedSideMenu,
            activePage,
            currentRequest,
            games,
            pages,
            currentLang,
            pageType,
            dispatch,
            gamesSearchTab,
            opensModalElementRef,
        } = this.props;

        const focusTrapOptions: FocusTrap.Props['focusTrapOptions'] = {
            initialFocus: `[data-element-description=side-menu-hide]`,
            checkCanFocusTrap: this.checkWhenFocusTrap,
            setReturnFocus: opensModalElementRef || undefined,
            allowOutsideClick: true,
        };
        return (
            <>
                <Helmet>
                    <style>
                        {`
                                body {
                                    overflow: ${isOpenedSideMenu ? 'hidden' : 'initial'}
                                }
                            `}
                    </style>
                </Helmet>
                <div
                    className={classNames(styles.darkOverlay, {
                        [styles.show]: isOpenedSideMenu,
                        [styles.chatBotOverlay]: this.state.isChatBotOpened,
                    })}
                    onClick={this.handleChatBotClose}
                    onKeyDown={() => this.handleEscapeClick}
                />
                <CSSTransition
                    in={isOpenedSideMenu}
                    timeout={{
                        appear: ANIMATION_DURATION,
                        enter: 0,
                        exit: ANIMATION_DURATION,
                    }}
                    classNames={{
                        enter: styles.myNodeEnter,
                        enterActive: styles.myNodeEnterActive,
                        enterDone: styles.myNodeEnterDone,
                        exit: styles.myNodeExit,
                        exitActive: styles.myNodeExitActive,
                        exitDone: styles.myNodeExitDone,
                    }}
                    onEntered={() => this.setState({ isOpenedMenu: true })}
                    onExited={() => this.setState({ isOpenedMenu: false })}
                    unmountOnExit
                >
                    <FocusTrap active={this.state.isFocusTrapActive} focusTrapOptions={focusTrapOptions}>
                        <section
                            data-testid="header-side-menu"
                            className={classNames(styles.headerSideMenu, {
                                [styles.isOpened]: isOpenedSideMenu,
                                [styles.isHelpTab]:
                                    activePage === HeaderSideMenuTabs.HELP_TAB ||
                                    activePage === HeaderSideMenuTabs.SUBSCRIPTION_TAB,
                                [styles.isLogInTab]: this.isTabActive(HeaderSideMenuTabs.LOG_IN_TAB),
                                [styles.isShopTab]:
                                    this.isTabActive(HeaderSideMenuTabs.SHOP_TAB) ||
                                    this.isTabActive(HeaderSideMenuTabs.SUBSCRIPTION_TAB),
                                [styles.extendMediaQuery]: pageType === PageTypes.Game,
                                [styles.isOpenedHome]: pageType === PageTypes.Home,
                                [styles.myNodeEnterDone]: isOpenedSideMenu && this.state.isOpenedMenu,
                                [styles.signOpen]: activePage === HeaderSideMenuTabs.LOG_IN_TAB,
                            })}
                            role="dialog"
                            aria-modal="true"
                            aria-label="side menu"
                        >
                            {pageType !== PageTypes.Home && this.isTabActive(HeaderSideMenuTabs.SEARCH_TAB) && (
                                <Responsive maxWidth={1024}>
                                    <TextField
                                        value={currentRequest}
                                        onFocus={() => {
                                            if (!isOpenedSideMenu) {
                                                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SEARCH_TAB));
                                            }
                                        }}
                                        onChange={(v) => {
                                            dispatch(setSideMenuRequest(v));
                                            this.searchInputOnChange(v);
                                        }}
                                        inputType={TextFieldTypes.SEARCH_INPUT}
                                        placeholder="Search"
                                        className={styles.headerSearch}
                                        ariaLabel="Search"
                                    />
                                </Responsive>
                            )}
                            <section className={styles.navigationContainer}>
                                <Responsive minWidth={1024}>
                                    <div role="tablist" ref={this.navigationRef} className={styles.navigation}>
                                        <NavBtnSideMenu
                                            className={classNames(styles.navButton, {
                                                [styles.activePage]: this.isTabActive(HeaderSideMenuTabs.SEARCH_TAB),
                                            })}
                                            isActive={this.isTabActive(HeaderSideMenuTabs.SEARCH_TAB)}
                                            onClick={() =>
                                                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SEARCH_TAB))
                                            }
                                            dataElementDescription="side-menu-search"
                                        >
                                            <SearchIcon className={styles.navButtonIcon} />
                                            <I18nText keyName="SIDEBAR_SEARCH" className={styles.iconCaption} />
                                        </NavBtnSideMenu>

                                        <NavBtnSideMenu
                                            className={classNames(styles.navButton, {
                                                [styles.activePage]: this.isTabActive(
                                                    HeaderSideMenuTabs.SUBSCRIPTION_TAB
                                                ),
                                            })}
                                            isActive={this.isTabActive(HeaderSideMenuTabs.SUBSCRIPTION_TAB)}
                                            onClick={this.openSubscriptionOnClick}
                                            dataElementDescription="side-menu-subscription"
                                        >
                                            <SubscriptionTabIcon
                                                className={classNames(styles.navButtonIcon, styles.navButtonIconAlt)}
                                            />
                                            <I18nText keyName="SUBSCRIPTION_TAB" className={styles.iconCaption} />
                                        </NavBtnSideMenu>

                                        <NavBtnSideMenu
                                            className={classNames(styles.navButton, {
                                                [styles.activePage]: this.isTabActive(HeaderSideMenuTabs.SHOP_TAB),
                                            })}
                                            isActive={this.isTabActive(HeaderSideMenuTabs.SHOP_TAB)}
                                            onClick={this.openShopOnClick}
                                            dataElementDescription="side-menu-shop"
                                        >
                                            <ShopIcon className={styles.navButtonIcon} />
                                            <I18nText keyName="SIDEBAR_SHOP" className={styles.iconCaption} />
                                        </NavBtnSideMenu>

                                        <NavBtnSideMenu
                                            className={classNames(
                                                styles.navButton,
                                                {
                                                    [styles.activePage]: this.isTabActive(HeaderSideMenuTabs.HELP_TAB),
                                                },
                                                styles.supportButton
                                            )}
                                            isActive={this.isTabActive(HeaderSideMenuTabs.HELP_TAB)}
                                            onClick={() => dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB))}
                                            dataElementDescription="side-menu-help"
                                        >
                                            <SupportIcon className={styles.navButtonIcon} />
                                            <I18nText keyName="SIDEBAR_SUPPORT" className={styles.iconCaption} />
                                        </NavBtnSideMenu>

                                        <button
                                            className={styles.navButton}
                                            onClick={this.closeSideMenu}
                                            data-element-description="side-menu-hide"
                                        >
                                            <ArrowRightIcon className={styles.navButtonIcon} />
                                            <I18nText keyName="SIDEBAR_HIDE" className={styles.iconCaption} />
                                        </button>
                                    </div>
                                </Responsive>
                            </section>
                            <section className={styles.signNavigationContainer}>
                                <button
                                    onClick={this.closeSideMenu}
                                    data-element-description="side-menu-hide"
                                    aria-label="Close sidebar"
                                >
                                    <CloseIcon />
                                </button>
                            </section>
                            <section
                                ref={this.contentContainerRef}
                                className={classNames(styles.content, {
                                    [styles.noOverflowY]: this.state.isChatBotOpened,
                                })}
                                id={'rightSideMenu'}
                                key={this.state.hydrateRerender}
                            >
                                {this.isTabActive(HeaderSideMenuTabs.LOG_IN_TAB) && (
                                    <Responsive maxWidth={1024}>
                                        <div className={styles.logoWrapper} style={{ display: 'flex' }}>
                                            <ArkadiumFullLogo textHoverColor="#dc1e34" arrowHoverColor="#dc1e34" />
                                        </div>
                                        <CloseIcon className={styles.closeIcon} onClick={this.closeSideMenu} />
                                    </Responsive>
                                )}
                                {(this.isTabActive(HeaderSideMenuTabs.SHOP_TAB) ||
                                    this.isTabActive(HeaderSideMenuTabs.SUBSCRIPTION_TAB)) && (
                                    <Responsive maxWidth={1024}>
                                        <CloseIcon
                                            className={classNames(styles.closeIcon, styles.closeIconWhite)}
                                            onClick={this.closeSideMenu}
                                        />
                                    </Responsive>
                                )}
                                <TabRouter
                                    activeState={activePage}
                                    currentRequest={currentRequest}
                                    pages={pages}
                                    currentLang={currentLang}
                                    componentsMap={PagesMap}
                                    games={games}
                                    extendMediaQuery={pageType === PageTypes.Game}
                                    gamesSearchTab={gamesSearchTab}
                                    dispatch={dispatch}
                                />
                            </section>
                        </section>
                    </FocusTrap>
                </CSSTransition>
            </>
        );
    }
}

export default connect((state) => ({
    isOpenedSideMenu: state.sideMenu.isOpened,
    isOpenedMobileProgressBar: state.mobileProgressBar.isOpened,
    games: GameService.gameModelToGame(state.games).filter((game) => game.name !== null),
    pages: PagesData.getPages(state.pages),
    currentLang: state.currentLang,
    activePage: activePageSelector(state),
    currentRequest: state.sideMenu.currentRequest,
    pageType: state.pageType,
    gamesSearchTab: state.gameLists?.gamesSearchTabPopularGames,
    userRegistrationCheckbox: state.userRegistrationCheckbox,
    opensModalElementRef: state.opensModalElementRef,
}))(NewHeaderSideMenuBase);
