export const DefaultQuestionsList = {
    en: [
        {
            title: "My game isn't loading. What's the problem?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44001200564-my-game-isn-t-loading-what-s-the-problem-',
        },
        {
            title: 'I’m an Arkadium Advantage subscriber. Why did I just see an ad?',
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002269639-i-m-an-arkadium-advantage-subscriber-why-did-i-just-see-an-ad-',
        },
        {
            title: 'Arkadium Account Frequently Asked Questions',
            pathFull: 'https://support.arkadium.com/en/support/solutions/folders/44001231960',
        },
        {
            title: "My score wasn't added to the leaderboard. Why?", //
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338625-my-score-wasn-t-added-to-the-leaderboard-why-',
        },
        {
            title: "I've lost my progress. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338346-i-ve-lost-my-progress-why-',
        },
        {
            title: 'HELP_MORE',
            pathFull: 'https://support.arkadium.com/en/support/home',
        },
    ],
    fr: [
        {
            title: "Je n'arrive pas à charger le jeu. D'où vient le problème ?",
            pathFull:
                'https://support.arkadium.com/fr/support/solutions/articles/44001200564-my-game-isn-t-loading-what-s-the-problem-',
        },
        {
            title: "J'ai reçu un message concernant mon bloqueur de publicités. Que dois-je faire ?",
            pathFull:
                'https://support.arkadium.com/fr/support/solutions/articles/44001739800-i-received-a-message-about-my-ad-blocker-what-should-i-do-',
        },
        {
            title: 'Mes jeux sont lents. Comment puis-je remédier à ça ?',
            pathFull:
                'https://support.arkadium.com/fr/support/solutions/articles/44001739808-my-games-are-running-slow-how-can-i-fix-this-',
        },
        {
            title: 'Comment supprimer les fichiers cookies dans Google Chrome',
            pathFull:
                'https://support.arkadium.com/fr/support/solutions/articles/44001803150-clearing-cookie-files-in-the-browser-chrome',
        },
        {
            title: "I've lost my progress. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338346-i-ve-lost-my-progress-why-',
        },
        {
            title: 'HELP_MORE',
            pathFull: 'https://support.arkadium.com/fr/support/home',
        },
    ],
    au: [
        {
            title: "My game isn't loading. What's the problem?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44001200564-my-game-isn-t-loading-what-s-the-problem-',
        },
        {
            title: 'I’m an Arkadium Advantage subscriber. Why did I just see an ad?',
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002269639-i-m-an-arkadium-advantage-subscriber-why-did-i-just-see-an-ad-',
        },
        {
            title: 'Arkadium Account Frequently Asked Questions',
            pathFull: 'https://support.arkadium.com/en/support/solutions/folders/44001231960',
        },
        {
            title: "My score wasn't added to the leaderboard. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338625-my-score-wasn-t-added-to-the-leaderboard-why-',
        },
        {
            title: "I've lost my progress. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338346-i-ve-lost-my-progress-why-',
        },
        {
            title: 'HELP_MORE',
            pathFull: 'https://support.arkadium.com/en/support/home',
        },
    ],
    nz: [
        {
            title: "My game isn't loading. What's the problem?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44001200564-my-game-isn-t-loading-what-s-the-problem-',
        },
        {
            title: 'I’m an Arkadium Advantage subscriber. Why did I just see an ad?',
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002269639-i-m-an-arkadium-advantage-subscriber-why-did-i-just-see-an-ad-',
        },
        {
            title: 'Arkadium Account Frequently Asked Questions',
            pathFull: 'https://support.arkadium.com/en/support/solutions/folders/44001231960',
        },
        {
            title: "My score wasn't added to the leaderboard. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338625-my-score-wasn-t-added-to-the-leaderboard-why-',
        },
        {
            title: "I've lost my progress. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338346-i-ve-lost-my-progress-why-',
        },
        {
            title: 'HELP_MORE',
            pathFull: 'https://support.arkadium.com/en/support/home',
        },
    ],
    gb: [
        {
            title: "My game isn't loading. What's the problem?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44001200564-my-game-isn-t-loading-what-s-the-problem-',
        },
        {
            title: 'I’m an Arkadium Advantage subscriber. Why did I just see an ad?',
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002269639-i-m-an-arkadium-advantage-subscriber-why-did-i-just-see-an-ad-',
        },
        {
            title: 'Arkadium Account Frequently Asked Questions',
            pathFull: 'https://support.arkadium.com/en/support/solutions/folders/44001231960',
        },
        {
            title: "My score wasn't added to the leaderboard. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338625-my-score-wasn-t-added-to-the-leaderboard-why-',
        },
        {
            title: "I've lost my progress. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338346-i-ve-lost-my-progress-why-',
        },
        {
            title: 'HELP_MORE',
            pathFull: 'https://support.arkadium.com/en/support/home',
        },
    ],
    cn: [
        {
            title: "My game isn't loading. What's the problem?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44001200564-my-game-isn-t-loading-what-s-the-problem-',
        },
        {
            title: 'I’m an Arkadium Advantage subscriber. Why did I just see an ad?',
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002269639-i-m-an-arkadium-advantage-subscriber-why-did-i-just-see-an-ad-',
        },
        {
            title: 'Arkadium Account Frequently Asked Questions',
            pathFull: 'https://support.arkadium.com/en/support/solutions/folders/44001231960',
        },
        {
            title: "My score wasn't added to the leaderboard. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338625-my-score-wasn-t-added-to-the-leaderboard-why-',
        },
        {
            title: "I've lost my progress. Why?",
            pathFull:
                'https://support.arkadium.com/en/support/solutions/articles/44002338346-i-ve-lost-my-progress-why-',
        },
        {
            title: 'HELP_MORE',
            pathFull: 'https://support.arkadium.com/en/support/home',
        },
    ],
};

export type DefaultQuestionsListType = {
    title: string;
    pathFull?: string;
    path?: string;
};
