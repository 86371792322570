import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const SupportedLangs = ['en', 'nz', 'cn', 'au', 'gb', 'fr', 'es', 'de', '__empty__'];
export const SupportedLangsEnDialects = ['nz', 'cn', 'au', 'gb'];
export const SupportedLangsSitemap = ['en', 'fr'];

export const LangsMapping = {
    en: 'en',
    nz: 'en-nz',
    cn: 'en-ca',
    au: 'en-au',
    gb: 'en-gb',
    fr: 'fr',
    es: 'es',
    de: 'de',
};

export const HrefLangsMappingNonUs = {
    fr: 'fr-fr',
    es: 'es-es',
    de: 'de-de',
};

export const i18nOptions = {
    debug: false,
    whitelist: SupportedLangs,
    fallbackLng: '',
    lowerCaseLng: true,
    interpolation: {
        escapeValue: false,
    },
};

// client init
export const i18nClientInit = () => {
    return i18n.use(initReactI18next).init(i18nOptions);
};
