import { MiscUtils } from '../../utils/MiscUtils';
import { AnalyticsEventBuilder } from './Analytics/AnalyticsEventBuilder';
import { UrlService } from './UrlService';
import UserService from './UserService';

export class AppInsightService {
    static init() {
        this.trackPageView();
    }

    static trackAppError(error, info = {}, isErrorBoundary = false) {
        const problemPagePath = window ? window.location.pathname : undefined;
        this.trackEvent('js-error', {
            error,
            info: JSON.stringify(info),
            page: problemPagePath,
            adBlockEnabled: MiscUtils.isAdBlockEnabled(),
            isUserLoggedIn: UserService.isUserLoggedIn(),
            isUserSubscribed: UserService.isUserSubscriber(),
            type: isErrorBoundary ? 'Something went wrong' : 'common',
        });
    }

    static async trackPageView() {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackPageView({
                name: document.title,
                uri: UrlService.convertEntities(window.location.href),
                properties: (await new AnalyticsEventBuilder().pageViewEvent()).options,
                screenResolution: window.innerWidth + 'x' + window.innerHeight,
            });
        }
    }

    static trackEvent(name: string, data: any) {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackEvent({
                name,
                properties: data,
            });
        }
    }
}
