import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { mobileNavigationUxRedesignedActiveButtonReducer } from '../organisms/MobileNavigationUxRedesigned/MobileNavigationUxRedesign.ducks';
import { abTestsReducer, abTestsValueReducer } from './ducks/abtests';
import { arkConfigReducer } from './ducks/arkConfig/arkConfig';
import { authErrorsReducer, authValuesReducer, isUserExistsReducer } from './ducks/authData/authData';
import { blogArchiveDataReducer } from './ducks/blog/blogArchieve';
import { blogPostDataReducer } from './ducks/blog/blogPost';
import { categoryPromoDataReducer, categoryPromoStateReducer, strapiPromoDataReducer } from './ducks/categoryPromo/categoryPromo';
import { userHasCollectionNotification } from './ducks/collections/collections';
import { deviceTypeReducer } from './ducks/device';
import { dialogDataReducer } from './ducks/dialog/dialog';
import { userFavoritesListReducer } from './ducks/favorites/favorites';
import {
    gameObservableReducer,
    gamePageViewStateReducer,
    gameReducer,
    gameArkadiumSlugReducer,
    gameStateReducer,
    homepageGameRedesignReducer,
    homepageGameReducer,
    iframeGamesListReducer,
    gameArenaSlugReducer,
    gameNameReducer,
    grmGamesListReducer,
} from './ducks/games';
import {
    chosenGemsPackItemNameReducer,
    gemsAmountLoadingReducer,
    gemsAmountReducer,
    trySpendGemsReducer,
    prerollSkipPriceReducer,
    prerollSkipPriceLoadingReducer,
    billingDataForTaxReducer,
} from './ducks/gems/gems';
import { giftCardPurchaseReducer, giftCardStepReducer } from './ducks/giftCard';
import {
    framesPanelReducer,
    fullScreenLoading,
    leaderboardNotificationTypeReducer,
    mobileProgressBarReducer,
    opensModalElementRefReducer,
    pageNameReducer,
    prevRegistrationScreenReducer,
    profilePanelReducer,
    sideMenuReducer,
    signInUpStateReducer,
    snackbarReducer,
    userLoaderReducer,
} from './ducks/layout';
import { leanplumCarouselData } from './ducks/leanplum/homepageCarousel';
import { gemsShopLocationReducer, socialRegistrationLocationReducer } from './ducks/leanplum/lpAnalytics';
import { i18nCurrentLangReducer, i18nTextsReducer } from './ducks/localization';
import { pageSeoReducer, pageTypeReducer, shouldTrackPageviewReducer } from './ducks/pages';
import { preLoadDataReducer } from './ducks/preLoadData';
import { strapiPagesReducer, strapiPageSlugsReducer } from './ducks/strapi/strapi';
import {
    activeSubscriptionPlanCodeReducer,
    activeUserSubscriptionsReducer,
    expiredUserSubscriptionsReducer,
    isValidFormReducer,
    paymentMethodReducer,
    recurlyTokenReducer,
    stepIndexReducer,
    subscriptionPlansReducer,
    subscriptionSourceReducer,
    subscriptionSecondStepContentReducer,
} from './ducks/subscription/common';
import { billingInfoValuesReducer } from './ducks/subscription/paymentForm';
import {
    hostedLoginTokenReducer,
    publicProfileReducer,
    userProcessedReducer,
    userReducer,
    userRegistrationCheckboxReducer,
} from './ducks/user';

export default (history: History) =>
    combineReducers({
        //router
        router: connectRouter(history),

        //games
        games: gameReducer,
        iframeGamesList: iframeGamesListReducer,
        grmGamesList: grmGamesListReducer,
        gameLists: homepageGameReducer,
        gameListsRedesign: homepageGameRedesignReducer,
        gameState: gameStateReducer,
        gameArkadiumSlug: gameArkadiumSlugReducer,
        gameArena5Slug: gameArenaSlugReducer,
        gameName: gameNameReducer,
        gameObservable: gameObservableReducer,
        gamePageViewState: gamePageViewStateReducer,

        //user
        user: userReducer,
        userProcessed: userProcessedReducer,
        userRegistrationCheckbox: userRegistrationCheckboxReducer,
        hostedLoginToken: hostedLoginTokenReducer,
        publicProfile: publicProfileReducer,

        //localization
        currentLang: i18nCurrentLangReducer,
        texts: i18nTextsReducer,

        //abtests
        abTests: abTestsReducer,
        abTestsValue: abTestsValueReducer,

        //ArkConfig
        arkConfig: arkConfigReducer,

        //gift card
        giftCardStep: giftCardStepReducer,
        giftCardPurchase: giftCardPurchaseReducer,

        //pages
        strapiPages: strapiPagesReducer,
        strapiPageSlugs: strapiPageSlugsReducer,
        pages: pageSeoReducer,
        pageType: pageTypeReducer,
        shouldTrackPageview: shouldTrackPageviewReducer,

        //layout
        sideMenu: sideMenuReducer,
        signInUpState: signInUpStateReducer,
        profilePanel: profilePanelReducer,
        mobileProgressBar: mobileProgressBarReducer,
        loginLoader: userLoaderReducer,
        snackbarData: snackbarReducer,
        prevRegistrationScreen: prevRegistrationScreenReducer,
        leaderboardNotificationType: leaderboardNotificationTypeReducer,
        categoryPageName: pageNameReducer,
        fullScreenLoading: fullScreenLoading,
        framesPanel: framesPanelReducer,
        opensModalElementRef: opensModalElementRefReducer,
        mobileNavigationUxRedesignedActiveButton: mobileNavigationUxRedesignedActiveButtonReducer,

        //device type
        deviceType: deviceTypeReducer,

        //preLoadData
        preLoadData: preLoadDataReducer,

        // subscription
        billingInfoValues: billingInfoValuesReducer,
        authValues: authValuesReducer,
        authErrors: authErrorsReducer,
        recurlyToken: recurlyTokenReducer,
        stepIndex: stepIndexReducer,
        paymentMethod: paymentMethodReducer,
        subscriptionStep2Content: subscriptionSecondStepContentReducer,
        isUserExists: isUserExistsReducer,
        isValidForm: isValidFormReducer,
        activeUserSubscriptions: activeUserSubscriptionsReducer,
        expiredUserSubscriptions: expiredUserSubscriptionsReducer,
        subscriptionPlans: subscriptionPlansReducer,
        subscriptionSource: subscriptionSourceReducer,
        activeSubscriptionPlan: activeSubscriptionPlanCodeReducer,

        // favorites
        userFavoritesList: userFavoritesListReducer,

        // gems
        chosenGemsPackItemName: chosenGemsPackItemNameReducer,
        gemsAmount: gemsAmountReducer,
        prerollSkipPrice: prerollSkipPriceReducer,
        isGemsAmountLoading: gemsAmountLoadingReducer,
        gemsShopLocation: gemsShopLocationReducer,
        socialRegistrationLocation: socialRegistrationLocationReducer,
        isPrerollSkipPriceLoading: prerollSkipPriceLoadingReducer,
        billingDataForTax: billingDataForTaxReducer,

        // blog
        blogPostData: blogPostDataReducer,
        blogArchiveData: blogArchiveDataReducer,

        //summerPromotion
        categoryPromoState: categoryPromoStateReducer,

        // category promo
        categoryPromoData: categoryPromoDataReducer,

        // strapi promo
        strapiPromoData: strapiPromoDataReducer,

        // dialog
        dialogData: dialogDataReducer,

        // leanplum
        leanplumCarouselData: leanplumCarouselData,

        // collections
        userHasCollectionNotification: userHasCollectionNotification,

        // user tries to spend gems will store source later
        trySpendGems: trySpendGemsReducer,
    });
