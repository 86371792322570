import React, { FC, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { environment } from '../../../../../config/environment';
import { AppInsightService } from '../../../../../services/AppInsight';
import PaymentService from '../../../../../services/PaymentService';
import { setSnackbarData } from '../../../../../store/ducks/layout';
import { setHostedLoginToken } from '../../../../../store/ducks/user';
import styles from './HistoryBlock.css';

export const HistoryBlock: FC = React.memo(() => {
    const hostedLoginToken = useSelector((state) => state.hostedLoginToken);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!hostedLoginToken) {
            PaymentService.getRecurlyData()
                .then((res) => {
                    dispatch(setHostedLoginToken(res.hostedLoginToken));
                })
                .catch((err) => {
                    console.log(' PaymentService.getRecurlyData()', err);
                    AppInsightService.trackAppError(err, { data: 'getRecurlyData()' });
                });
        }
    }, []);

    const showWarningSnackbar = () => {
        dispatch(
            setSnackbarData({
                isOpened: true,
                message: `If you've recently updated your subscription, please refresh this page.`,
                type: 'success',
                static: true,
            })
        );
    };

    return (
        <div className={styles.container}>
            <p>
                Access your order history and update your payment information{' '}
                <a
                    href={`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    onClick={showWarningSnackbar}
                >
                    here
                </a>
                .
            </p>
            <p className={styles.small}>A new tab will open with your subscription details.</p>
        </div>
    );
});
