import React, { useEffect } from 'react';

import UserService from '../../services/UserService';
import styles from './LoginWidgetContainer.css';

export const LoginWidgetContainer = React.memo(() => {
    useEffect(() => {
        const targetNode = document.getElementById('login-widget-container-wrapper');
        const observer = new MutationObserver(function () {
            if (targetNode.style.display !== 'none') {
                UserService.openSignInPanel();
                targetNode.style.display = 'none';
            }
        });
        observer.observe(targetNode, { attributes: true, childList: true });
    }, []);

    return (
        <div id="login-widget-container-wrapper">
            <div id="login-widget-container" className={styles.LoginWidgetContainer} />
        </div>
    );
});
