import { createAction, createReducer } from 'redux-act';

export type LeanplumCarouselSlide = {
    //deprecated background image
    backgroundImage?: string;
    desktopBackgroundImage: string;
    tabletBackgroundImage?: string;
    mobileBackgroundImage?: string;
    buttonLink: string;
    buttonText: string;
    title: string;
    subtitle: string;
    mobileTitle?: string;
    mobileSubtitle?: string;
    mobileContent?: boolean;
};

export const homepageCarouselInitialData: LeanplumCarouselSlide[] = [
    {
        title: 'Welcome to Arkadium',
        subtitle: 'Your destination for the best online games',
        buttonText: 'Play now',
        buttonLink: '',
        desktopBackgroundImage:
            'https://assets.prod.leanplum.com/app_rqFURnfZa50YE8vo3g3YnDLgaPNKNEFbMtlGFogTL7o/R3JvdXAgMTkwICgxKS5wbmc=',
    },
];

export const leanplumCarouselData = createReducer<LeanplumCarouselSlide[]>({}, homepageCarouselInitialData);
export const setLeanplumCarouselData = createAction<LeanplumCarouselSlide[]>('set carousel data');
leanplumCarouselData.on(setLeanplumCarouselData, (_state, payload) => payload);
