import { IGame } from '../../models/Game/Game';
import { NewHomeAnalyticsAi } from './AI/NewHomeAnalytics';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';

export class NewHomeAnalytics {
    aiAnalytics = new NewHomeAnalyticsAi();

    async searchNavClick(navType: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.searchNavClick(navType));
    }

    async seoImpression(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.seoImpression());
    }

    async seoClick(seo: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.seoClick(seo));
    }

    async footerSocialNav(socialNetwork: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.footerSocialNav(socialNetwork));
    }

    async searchNavSupportImpression(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.searchNavSupportImpression());
    }

    async searchNavSupportClick(clickTarget: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.searchNavSupportClick(clickTarget));
    }

    async searchNavFeedbackClick(clickTarget: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.searchNavFeedbackClick(clickTarget));
    }

    async chatBotButtonClick(clickTarget: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.chatBotButtonClick(clickTarget));
    }

    async promoboxImpression(promoTarget: string, promoType: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.promoboxImpression(promoTarget, promoType));
    }

    async promoboxImpressionHero(
        promoTarget: string,
        promoType: string,
        gamesList?: string
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.promoboxImpression(promoTarget, promoType));
    }

    async promoboxSlotClick(
        promoTarget: string,
        promoType: string,
        position: string,
        slugOrCategory = ''
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.promoboxSlotClick(promoTarget, promoType, position, slugOrCategory)
        );
    }

    async promoboxSlotClickHero(
        promoTarget: string,
        promoType: string,
        position: string,
        slugOrCategory = ''
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            null,
            this.aiAnalytics.promoboxSlotClick(promoTarget, promoType, position, slugOrCategory)
        );
    }

    async gameBox(game: IGame, homeCategory: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.gameBox(game, homeCategory));
    }

    async seeAllButton(homeCategory: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.seeAllButton(homeCategory));
    }

    async backButton(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.backButton());
    }
}
