import { environment } from '../config/environment';

class SessionStorageProvider {
    'arkcom:';

    private data = {};

    private readonly prefix: string = environment.STORAGE_PREFIX;

    public clear() {
        if (typeof window === 'undefined') {
            this.data = {};
        } else {
            window.sessionStorage.clear();
        }
    }

    public setItem(key: string, value: any) {
        key = `${this.prefix}${key}`;
        if (typeof window === 'undefined') {
            this.data[key] = String(value);
        } else {
            try {
                window.sessionStorage.setItem(key, String(value));
            } catch (e) {
                console.log(e);
            }
        }
    }

    public getItem(key: string): string | null {
        key = `${this.prefix}${key}`;
        if (typeof window === 'undefined') {
            return this.data[key];
        }
        const value = window.sessionStorage.getItem(key);

        if (!value || value === 'undefined' || value === 'null') {
            return null;
        }

        return value;
    }

    public removeItem(key: string) {
        key = `${this.prefix}${key}`;
        if (typeof window === 'undefined') {
            delete this.data[key];
        } else {
            window.sessionStorage.removeItem(key);
        }
    }

    public getSessionData(dataKey: string, key: string) {
        if (SessionStorageService.getItem(dataKey)) {
            try {
                const formData = JSON.parse(SessionStorageService.getItem(dataKey));
                return formData[key] ? formData[key] : '';
            } catch (e) {
                console.log(e);
            }
        }
        return '';
    }

    public setSessionData(dataKey: string, key: string, value: string | boolean) {
        let formData = {};
        if (SessionStorageService.getItem(dataKey)) {
            try {
                formData = JSON.parse(SessionStorageService.getItem(dataKey));
            } catch (e) {
                console.log(e);
            }
        }
        formData[key] = value;
        SessionStorageService.setItem(dataKey, JSON.stringify(formData));
    }
}

export const SessionStorageService = new SessionStorageProvider();
