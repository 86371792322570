import React, { useCallback } from 'react';

import classNames from 'classnames';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { Avatar, AvatarTypes } from '../../atoms/Avatar/Avatar';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageTypes } from '../../constants/Pages';
import { LeanplumAnalytics } from '../../services/Analytics/LeanplumAnalytics';
import { useVisibilityAPI } from '../../services/hooks/useVisibilityAPI';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { gemsAmountSelector, isGemsAmountLoadingSelector } from '../../store/ducks/gems/gemsSelectors';
import { setSideMenuActivePage, setSideMenuOpened } from '../../store/ducks/layout';
import { GemsAnalyticsShopLocations, setGemsShopLocation } from '../../store/ducks/leanplum/lpAnalytics';
import { GemsEffects } from '../../store/effects/gems.effects';
import styles from './AvatarGemsCounter.css';

function reduceNumbers(number: number): string {
    return Math.floor(number / 10000) > 0
        ? new Intl.NumberFormat('en-En', { notation: 'compact' }).format(number)
        : number.toString();
}

export const AvatarGemsCounter = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state) => state.user);
    const gemsAmount = useSelector(gemsAmountSelector);
    const isGemsAmountLoading = useSelector(isGemsAmountLoadingSelector);
    const currentPage = useSelector((state) => state.pageType);
    const isSidePanelOpened = useSelector((state) => state.sideMenu.isOpened);

    //const [hover, setHover] = useState(false);

    const avatar = user?.avatar || user?.subscriberAvatar?.avatar;
    const frame = (UserService.isUserSubscriber() && user?.subscriberAvatar?.frame) || '';
    const email = user?.email;

    const openShopTab = () =>
        batch(() => {
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
            dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.AVATAR_HEADER_ICON));
        });
    const openProfilePage = () => {
        if (isSidePanelOpened) {
            dispatch(setSideMenuOpened(false));
        }

        if (currentPage === PageTypes.Game) {
            typeof window !== 'undefined' && (window.location.href = UrlService.createURL('/profile/'));
        } else {
            history.push(UrlService.createURL('/profile/'));
        }
    };

    const visibilitychangeListener = useCallback(() => {
        dispatch(GemsEffects.UpdateGemsAmount());
        // for handling subscription canceled / reactivated in recurly's separate tab + TO for eagle to proceed it
        setTimeout(async () => await LeanplumAnalytics.setUserAttributes(true), 3000);
    }, [dispatch]);

    useVisibilityAPI({ onVisible: visibilitychangeListener });

    const GetAvatar = () => {
        return (
            <div className={styles.avatarWithGemsAdj} onClick={openProfilePage}>
                <Avatar size={AvatarTypes.SMALL} image={avatar} email={email} frame={frame} />
            </div>
        );
    };

    return (
        <div className={styles.splitStyle}>
            <div data-element-description="nav-user-profile-link" className={classNames(styles.avatarWithGems)}>
                <div className={styles.gemCounter} onClick={openShopTab}>
                    {isGemsAmountLoading ? (
                        <div className={styles.loaderWrapper}>
                            <AppLoader />
                        </div>
                    ) : (
                        <>
                            <img className={styles.avatarWithGemsImg} src="/images/gems/gem28x22.png" alt="Gem" />
                            {reduceNumbers(gemsAmount)}
                        </>
                    )}
                </div>
            </div>
            <GetAvatar />
        </div>
    );
});
