import React from 'react';

import classNames from 'classnames';

import { WarningIcon } from '../Icons/WarningIcon';
import styles from './ErrorMessage.css';

type ErrorMessageProps = {
    message: string;
    id: string;
    className?: string;
};

export const ErrorMessage = ({ message, id, className }: ErrorMessageProps) => (
    <div className={classNames(styles.validationBlock, className)}>
        <WarningIcon />
        <span id={id} role="alert">
            {message}
        </span>
    </div>
);
