import React, { FC, useEffect } from 'react';

import { push } from 'connected-react-router';
import { batch, useDispatch, useSelector } from 'react-redux';

import { AppLoader } from '../../../../atoms/AppLoader/AppLoader';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../../atoms/Link/Link';
import { PaymentType, RecurlyPlans } from '../../../../constants/RecurlyPurchase';
import { getPlanByCode, SubscriptionPlans } from '../../../../constants/SubscriptionPlan';
import { ArkadiumAdvantageLogo } from '../../../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { ListCheckCircleIcon } from '../../../../FigmaStyleguide/Icons/ListCheckCircleIcon';
import { AnnualCard } from '../../../../molecules/AnnualCard/AnnualCard';
import { MonthlyCard } from '../../../../molecules/MonthlyCard/MonthlyCard';
import subsStyles from '../../../../molecules/Subscription/SubscriptionStyles.css';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { UrlService } from '../../../../services/UrlService';
import { setActiveSubscriptionPlan } from '../../../../store/ducks/subscription/common';
import { getSubscriptionPlans } from '../../../../templates/Profile/Tabs/PaymentAndSubscription/JoinBlock/JoinBlock';
import styles from './SelectSubscription.css';

export const SelectSubscription: FC = React.memo(() => {
    const plans = useSelector((state) => state.subscriptionPlans);
    const subscriptionSource = useSelector((state) => state.subscriptionSource);
    const annualPlanName = RecurlyPlans[PaymentType.subscription][SubscriptionPlans.ANNUAL].plan;
    const monthlyPlanName = RecurlyPlans[PaymentType.subscription][SubscriptionPlans.MONTHLY].plan;

    const dispatch = useDispatch();

    SelectSubscription.displayName = 'SelectSubscription';
    const SUBSCRIBE_BTN_LABEL = 'Subscribe';
    const COLOR_CIRCLE_COLOR = 'rgba(11, 16, 61, 1)'; // HEX #0B103D

    const handleSubscribe = (plan) => {
        Analytics.trackEvent(
            Analytics.subscription.entryPointClick(
                subscriptionSource,
                plan === SubscriptionPlans.MONTHLY ? 'monthly' : 'annual'
            )
        );
        batch(() => {
            dispatch(setActiveSubscriptionPlan(plan));
            dispatch(push('/subscription'));
        });
    };

    useEffect(() => {
        if (!plans) {
            getSubscriptionPlans(dispatch, null);
        }
    }, [plans]);

    return (
        <>
            <div className={styles.topBlock}>
                <div className={styles.mainInfo}>
                    <div className={styles.leftSide}>
                        <ArkadiumAdvantageLogo />
                    </div>
                    <div className={styles.rightSide}>
                        <ul className={styles.advantagesList}>
                            <li>
                                <ListCheckCircleIcon goldCheck checkColor={COLOR_CIRCLE_COLOR} />
                                <p>Play games without disruptive ads</p>
                            </li>
                            <li>
                                <ListCheckCircleIcon goldCheck checkColor={COLOR_CIRCLE_COLOR} />
                                <p>
                                    <span className={styles.advantageLabel}>15%</span>&nbsp;off gem purchases
                                </p>
                            </li>
                            <li>
                                <ListCheckCircleIcon goldCheck checkColor={COLOR_CIRCLE_COLOR} />
                                <p>
                                    Show your personality with premium{' '}
                                    <span className={styles.advantageLabel}>avatars </span>
                                    and <span className={styles.advantageLabel}>frames</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.advantagesRow}>
                {plans && plans.length !== 0 ? (
                    <>
                        <AnnualCard
                            plan={getPlanByCode(annualPlanName, plans)}
                            monthlyPlan={getPlanByCode(monthlyPlanName, plans)}
                            btnLabel={SUBSCRIBE_BTN_LABEL}
                            handlerBtn={() => handleSubscribe(SubscriptionPlans.ANNUAL)}
                        />
                        <MonthlyCard
                            plan={getPlanByCode(monthlyPlanName, plans)}
                            btnLabel={SUBSCRIBE_BTN_LABEL}
                            handlerBtn={() => handleSubscribe(SubscriptionPlans.MONTHLY)}
                        />
                    </>
                ) : (
                    <AppLoader />
                )}
            </div>

            <div className={styles.aboutRow}>
                <div className={styles.aboutSubscription}>
                    <p>
                        Your subscription will renew automatically – manage or cancel your
                        <br /> subscription any time through your profile. <br />
                        <br />
                        <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
                            <I18nText keyName="PLAYER_AGREEMENT" />
                        </NavLink>
                        {', '}
                        <NavLink to="/arkadium-advantage-agreement.pdf" target="_blank" rel="noopener">
                            <I18nText keyName="SUBSCRIPTION_AGREEMENT" />
                        </NavLink>{' '}
                        and
                        <span className={subsStyles.link}> </span>
                        <NavLink to={UrlService.createURL('privacy-policy')} target="_blank" rel="noopener">
                            <I18nText keyName="FOOTER_PRIVACY_POLICY" />
                        </NavLink>
                    </p>
                </div>
            </div>
        </>
    );
});
