// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Api, ApiGateway, ApiGatewayInitParams, UserApiErrorV1 } from '@arkadium/eagle-user-client';
import { PasswordComplexityDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/password-complexity.dto';
import { UserAdditionalFieldsDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-additional-fields.dto';
import { UserCheckName } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-check-name.dto';
import { UserProfileForSocialDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile-for-social.dto';
import { batch } from 'react-redux';

import { SupportedLangsEnDialects } from '../../i18n';
import { HttpUtils } from '../../utils/HttpUtils';
import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { getAvatarAnalyticsKey } from '../constants/AvatarImagesData';
import { HeaderSideMenuTabs } from '../constants/HeaderSideMenuTabs';
import { PageTypes, ProfileRightSideRoutes } from '../constants/Pages';
import { SignInUpComponents } from '../constants/SignInUpComponents';
import { getPlanName, SUBSCRIPTION_PLAN_NAME } from '../constants/SubscriptionPlan';
import { GameState, LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../models/Enums';
import {
    PlatformType,
    SubscriptionAutoRenew,
    SubscriptionLifeCycle,
    SubscriptionSource,
    SubscriptionStatus,
} from '../models/Subscription/SubscriptionData';
import { RegistrationSource } from '../models/User/RegistrationSource';
import { UserModel } from '../models/User/UserModel';
import { reduxStore } from '../store';
import { setGameState } from '../store/ducks/games';
import { setGemsAmount } from '../store/ducks/gems/gems';
import { setGiftCardStep } from '../store/ducks/giftCard';
import {
    setLoaderStart,
    setProfilePanelState,
    setSideMenuActivePage,
    setSideMenuOpened,
    setSignInUpCopyState,
    setSignInUpState,
} from '../store/ducks/layout';
import { setPageType } from '../store/ducks/pages';
import { setPreLoadData } from '../store/ducks/preLoadData';
import {
    setActiveUserSubscriptions,
    setExpiredUserSubscriptions,
    setSubscriptionSource,
} from '../store/ducks/subscription/common';
import { setHostedLoginToken, setPublicUser, setUser, setUserProcessed } from '../store/ducks/user';
import AdFreeService from './AdFreeService';
import adFreeService from './AdFreeService';
import { Analytics } from './Analytics/Analytics';
import { asyncStoreStateWhenReady } from './Analytics/AnalyticsEventBuilder';
import { LeanplumAnalytics } from './Analytics/LeanplumAnalytics';
import { AppInsightService } from './AppInsight';
import { BaseApiService } from './BaseApiService';
import { CookieService } from './CookieService';
import { setAiIsVirtualItemBuy, setAiIsVirtualItemSpent } from './GemsService';
import HighScoreService from './HighScoreService';
import RecentlyPlayedService from './RecentlyPlayedService';
import { TranslationService } from './TranslationService';
import { UrlService } from './UrlService';

enum Events {
    onWidgetStateChanged = 1,
    onAuthStateChanged = 2,
}

export enum AuthType {
    EmailPassword = 'EmailPassword',
    Facebook = 'Facebook',
    Google = 'Google',
}

export enum EmailCodes {
    CONFIRMATION_CODE = 'account_confirmation_code',
    EMAIL_CHANGE_CODE = 'email_change_confirmation_code',
    RESET_PASSWORD_CODE = 'request_reset_password_code',
}

export enum ERRORS_DESCRIPTION {
    InvalidRequestData = 'Your request couldn’t be processed.',
    UserNotFound = "Sorry, we can't locate that email address.",
    UserAlreadyExist = 'This email address is already in use.',
    UserIsNotActive = 'Your request couldn’t be processed.',
    UserIsBanned = 'Your request couldn’t be processed.',
    UserIsSoftDeleted = 'Your request couldn’t be processed.',
    UserRemovedViaGDPR = 'Your request couldn’t be processed.',
    EmailDoesNotSpecified = 'Sorry, your email has not been confirmed.',
    EmailAlreadyUsed = 'This email address is already in use.',
    RefreshTokenIsInvalid = 'Sorry, an error occurred.',
    EmailOrPasswordIsIncorrect = 'Sorry, your email or password is incorrect.',
    PasswordTooEasy = "Sorry, this password isn't secure.",
    CountryIsInvalid = 'Your request couldn’t be processed.',
    LanguageIsInvalid = 'Your request couldn’t be processed.',
    UserOldPasswordIsIncorrect = 'Sorry, that password is incorrect.',
    NewAndOldPasswordAreEquals = 'NewAndOldPasswordAreEquals',
    UserPasswordsAreNotEqual = 'Sorry, that password is incorrect.',
    UserNotConfirmedError = 'Sorry, your email has not been confirmed.',
    UserEmailConfirmationCodeIsInvalid = 'Sorry, that link has expired.',
    UserPasswordResetCodeIsInvalid = 'Sorry, that link has expired.',
    TooManyFailedLoginAttempts = 'Too many unsuccessful sign in attempts.',
    EmailSenderGeneralError = 'Sorry, an error occurred.',
    EmailSenderEmailContentIsEmpty = 'Sorry, an error occurred.',
    ExternalProviderError = 'Sorry, an error occurred.',
    UserNameAlreadyUsed = 'That screen name is already taken – please try another',
    NoEmailChangeRequest = 'NoEmailChangeRequest',
    EmailChangeRequestCodeIsInvalid = 'EmailChangeRequestCodeIsInvalid',
    CaptchaValidationFailed = 'Please try again or refresh the page.',
    CaptchaScoreIsTooLow = "A sign in error occurred. Please <a href='https://support.arkadium.com/en/support/solutions/articles/44002422983--your-activity-got-a-low-score-what-can-i-do-' target='_blank'>click here</a> for more info, or <a target='_self' href='/?menuTab=help'>contact</a> support.",
}

const { store } = reduxStore;
const AUTH_TOKEN = 'eagle-access-token';

interface UserServiceInterface {
    userLogin(email: string, password: string, captchaToken: string): void;
    loginViaFacebook(
        token: string,
        email: string,
        emailRetrievalConsent: boolean,
        registrationPlaceUrl: URL,
        password?: string,
        user?: UserAdditionalFieldsDto,
        captchaToken?: string
    ): Promise<{ errorCode: number; errorEmail: string }>;
    loginViaGoogle(
        token: string,
        email: string,
        emailRetrievalConsent: boolean,
        registrationPlaceUrl: URL,
        password?: string,
        user?: UserAdditionalFieldsDto,
        captchaToken?: string
    ): Promise<{ errorCode: number; errorEmail: string }>;
    userRegister(email: string, password: string, checkBox: boolean): void;
    userLogout(): void;
    userUpdate(user: UserModel): void;
    isUserLoggedIn(): boolean;
    getUserFromStore(): UserModel;
    handleLoginRedirect(): void;
    changePassword(newPassword, oldPassword);
    requestResetPassword(email: string): void;
    resendConfirmationEmail(email: string, authType): void;
    changePasswordByRecoveryCode(password: string, code: string): void;
    gameSignListenerAdd(): void;
    gameSignListenerRemove(): void;
    openSignInPanel(): void;
    openResetPanel(): void;
    openRequestEmail(): void;
    openSignUpPanel(): void;
    closePanel(): void;
    getGoogleButton(): Promise<string>;
    getFacebookButton(): Promise<string>;
    getToken(): Promise<string>;
    errorCodeToText(errorCode: number): string;
    errorCodeToEnum(errorCode: number): string;
    manageErrorsEmailVsPass(res: number): void;
}

type SocialLoginProps = {
    errorCode: number;
    errorEmail: string;
    isRegistered: boolean;
};

export class UserServiceClass extends BaseApiService implements UserServiceInterface {
    public authApiService;

    private adFreeEmail = 'adfree@arkadium.com';

    constructor() {
        super();
        if (!MiscUtils.isServer) {
            this.initAPI();
        }
    }

    public isUserLoggedIn(): boolean {
        if (MiscUtils.isServer) {
            return false;
        }
        return this.getSessionStorage().isAuthorised();
    }

    public getUserFromStore(): UserModel {
        if (MiscUtils.isServer) {
            return null;
        }
        return store.getState().user as UserModel;
    }

    public getSubscriptionFromStore() {
        if (MiscUtils.isServer) {
            return null;
        }
        return store.getState().subscriptionPlans;
    }

    public userLogin = async (email: string, password: string, captchaToken: string) => {
        const data = {
            email,
            password,
            registrationPlaceUrl: new URL(this.generateUrl()),
            captchaToken: captchaToken,
        };

        let res;
        const api = await this.authApiService.getAuthApi(Api.v1);

        await api.auth
            .loginViaEmail(data)
            .then(async () => {
                res = 200;
                api.managment
                    .getUserProfile()
                    .then((profile: UserModel) => {
                        store.dispatch(setUser(profile));
                        this.setAdfree(profile);
                        LeanplumAnalytics.setUserAttributesCustom({ subscriber: this.isUserSubscriber() });
                    })
                    .catch((err) => {
                        console.error(err);
                        AppInsightService.trackAppError(err, { data: 'getUserProfile()' });
                    });
                window.history.replaceState(null, '', encodeURI(this.generateUrl()));
                await this.syncLocalWithDB();
            })
            .catch((err) => {
                console.error(err);
                res = this.extractErrorCode(err);
                AppInsightService.trackAppError(err, { data: 'loginViaEmail()', errorCode: res });
            });

        return res;
    };

    public userRegister = async (
        email: string,
        password: string,
        checkBox: boolean,
        subscription = false,
        user?: UserAdditionalFieldsDto,
        captchaToken?: string
    ) => {
        const urlForEmail = new URL(subscription ? this.generateHostUrl() : this.generateUrl());
        const data = {
            emailRetrievalConsent: checkBox,
            email,
            password,
            registrationPlaceUrl: urlForEmail,
            user,
            captchaToken,
        };
        let res;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth
            .registerViaEmail(data)
            .then((resData) => {
                const uid = resData?.uid;
                res = { code: 200, uid };
            })
            .catch((err) => {
                console.error(err.body);
                res = this.extractErrorCode(err);
                AppInsightService.trackAppError(err, { data: 'registerViaEmail()', errorCode: res });
            });

        return res;
    };

    public async requestResetPassword(email: string, captchaToken?: string) {
        const url = new URL(this.generateUrl());

        const data = {
            email,
            resetPasswordConfirmationFormUrl: url,
            registrationPlaceUrl: url,
            captchaToken,
        };
        let res = 200;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth.requestResetPassword(data).catch((err) => {
            console.error(err);
            res = this.extractErrorCode(err);
            AppInsightService.trackAppError(err, { data: 'requestResetPassword()', errorCode: res });
        });
        return res;
    }

    public async changePassword(newPassword, oldPassword) {
        const data = {
            oldPassword,
            newPassword,
        };
        let res = 200;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth.changePassword(data).catch((err) => {
            console.error(err.body);
            res = this.extractErrorCode(err);
            AppInsightService.trackAppError(err, { data: 'changePassword()', errorCode: res });
        });
        return res;
    }

    public async getUserByEmail(email: string, captchaToken?: string): Promise<UserProfileForSocialDto> {
        const api = await this.authApiService.getAuthApi(Api.v1);
        return api.managment.checkUserEmail({ email, captchaToken });
    }

    public async generateUserName(): Promise<string> {
        const api = await this.authApiService.getAuthApi(Api.v1);
        return api.managment.generateUserName();
    }

    public async checkUserName(name: UserCheckName): Promise<{
        result: boolean;
    }> {
        const api = await this.authApiService.getAuthApi(Api.v1);
        return api.managment.checkUserName(name);
    }

    public async checkPasswordComplexity(data: PasswordComplexityDTO): Promise<{
        result: boolean;
    }> {
        const api = await this.authApiService.getAuthApi(Api.v1);
        return api.auth.checkPasswordComplexity(data);
    }

    public async confirmUser(code) {
        // Not used in fact for user confirmation, despite captchaToken is supported as not required in type
        const data = {
            code,
        };
        let res = 200;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth.confirmUser(data).catch((err) => {
            console.error(err.body);
            res = this.extractErrorCode(err);
            AppInsightService.trackAppError(err, { data: 'confirmUser()', errorCode: res });
        });
        return res;
    }

    public async emailChangeConfirm(code) {
        const data = {
            code,
        };
        let resCode = 200;
        let errorText;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.managment.changeEmailConfirm(data).catch((err) => {
            console.error(err.body);
            resCode = this.extractErrorCode(err);
            errorText = err.body?.details;
            AppInsightService.trackAppError(err, { data: 'changeEmailConfirm()', errorCode: resCode });
        });
        return { resCode, errorText };
    }

    public async changePasswordByRecoveryCode(newPassword, code): Promise<number> {
        const data = {
            newPassword,
            resetCode: code,
        };
        let res = 200;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth.confirmResetPassword(data).catch((err) => {
            console.error(err.body);
            res = this.extractErrorCode(err);
            AppInsightService.trackAppError(err, { data: 'confirmResetPassword()', errorCode: res });
        });
        return res;
    }

    public async resendConfirmationEmail(email, captchaToken, authType = AuthType.EmailPassword) {
        const data = {
            email,
            authType,
            captchaToken,
        };
        let res = 200;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth.resendConfirmation(data).catch((err) => {
            console.error(err.body);
            res = this.extractErrorCode(err);
            AppInsightService.trackAppError(err, { data: 'resendConfirmation()', errorCode: res });
        });
        return res;
    }

    public async userUpdate(user): Promise<number> {
        const lang = store.getState().currentLang;
        const languageId = (SupportedLangsEnDialects.indexOf(lang) !== -1 ? 'en' : lang).toUpperCase();
        const data = {
            ...(user.name && { name: user.name }),
            ...(user.firstName && { firstName: user.firstName }),
            ...(user.lastName && { lastName: user.lastName }),
            ...(user.birthday && { birthday: user.birthday }),
            ...(user.gender && { gender: user.gender }),
            ...(user.phone && { phone: user.phone }),
            ...(user.countryId && { countryId: user.countryId }),
            languageId,
            ...(user.avatar && { avatar: user.avatar }),
            ...(user.onboardingProgress && { onboardingProgress: user.onboardingProgress }),
            ...(user.emailRetrievalConsent !== null && { emailRetrievalConsent: user.emailRetrievalConsent }),
            ...(user.avatar && { avatar: user.avatar }),
            ...(user.subscriberAvatar && { subscriberAvatar: { ...user.subscriberAvatar } }),
            ...(user.avatarBackground && { avatarBackground: user.avatarBackground }),
        };
        let res = 200;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.managment
            .updateUser(data)
            .then(() => {})
            .catch((err) => {
                console.error(err);
                res = this.extractErrorCode(err);
                AppInsightService.trackAppError(err, { data: 'updateUser()', errorCode: res });
            });
        return res;
    }
    public getCarouselGiftCardParam(): string {
        return UrlService.getQSParam(window.location.search, 'giftcard-carousel');
    }

    public async handleLoginRedirect() {
        const arkpromoCode = UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.ARK_PROMO);
        const isLoggedIn = this.isUserLoggedIn();

        if (arkpromoCode === 'registration' && !isLoggedIn) {
            this.openSignUpPanel();
        }

        if (arkpromoCode === 'house_ad') {
            store.dispatch(setSubscriptionSource(SubscriptionSource.HOUSE_AD));
            this.openSubscriptionPanel();
        }

        store.dispatch(setLoaderStart(true));

        const confirmationCode = UrlService.getQSParam(window.location.search, EmailCodes.CONFIRMATION_CODE);
        if (confirmationCode) {
            this.userLogout();
            this.openSignInPanel();

            const state = await asyncStoreStateWhenReady();
            Analytics.trackEvent(
                Analytics.profile.registrationEmailConfirm(
                    state?.categoryPageName,
                    state?.gameArkadiumSlug,
                    RegistrationSource.EMAIL,
                    getAvatarAnalyticsKey(state?.user?.avatar),
                    state?.user?.avatar?.avatarBackground,
                    state?.socialRegistrationLocation
                )
            );
        }
        const resetCode = UrlService.getQSParam(window.location.search, EmailCodes.RESET_PASSWORD_CODE);
        if (resetCode) {
            this.userLogout();
            this.openResetPanel();
        }

        const hasAuthToken = localStorage.getItem(AUTH_TOKEN);

        hasAuthToken && (await this.userLoad());

        // Open SignIn Panel
        const subscriptionLogout = UrlService.getQSParam(window.location.search, 'subsLogout');
        if (subscriptionLogout) {
            this.openSignInPanel();
        }

        const subscriptionParam = UrlService.getQSParam(window.location.search, 'subscription');
        if (subscriptionParam) {
            store.dispatch(setSubscriptionSource(SubscriptionSource.EXTERNAL));
            this.openSubscriptionPanel();
        }

        const giftCardParam = this.getCarouselGiftCardParam();
        if (giftCardParam) {
            store.dispatch(setSubscriptionSource(SubscriptionSource.HOME_CAROUSEL));
        }
        // TODO: test For subscriber
        // const subscriberParam = UrlService.getQSParam(window.location.search, 'subscriber');
        // const userFull = store.getState().user;
        // let userSubscriber = false;
        // if (subscriberParam === '1') {
        //     userSubscriber = true;
        // }
        // if (userFull) {
        //     store.dispatch(
        //         setUser({
        //             ...userFull,
        //             subscriber: userSubscriber,
        //         })
        //     );
        // }

        /** ******** */

        store.dispatch(setLoaderStart(false));
    }

    public async userLoad() {
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.managment
            .getUserProfile()
            .then((data) => {
                store.dispatch(setUser(data));
                this.setAdfree(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    public async getPublicUserProfile(userId: string): Promise<void> {
        try {
            const url = `${environment.EAGLE_API_BASE_URL}user/${userId}`;
            const options = {
                method: 'GET',
            };
            const publicUser = await HttpUtils.fetch(url, options);
            store.dispatch(setPublicUser(publicUser));
        } catch (error) {
            store.dispatch(setPageType(PageTypes.NotFound));
            this.trackError(
                error,
                'An error occurred while try to update user profile. See app insight logs for details.'
            );
            throw error;
        }
    }

    public userLogout(): void {
        this.authApiService.logout();
        batch(() => {
            store.dispatch(setUser(null));
            store.dispatch(setGemsAmount(0));
            store.dispatch(setActiveUserSubscriptions(null));
            store.dispatch(setExpiredUserSubscriptions(null));
            store.dispatch(setSubscriptionSource(null));
            store.dispatch(setHostedLoginToken(null));
            // These additional dispatches fixes bug #142914, when after logout from google/facebook
            store.dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
        });
        CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, false);
        CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, false);
        CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID, '');
        store.dispatch(
            setPreLoadData({
                adFree: adFreeService.adFreeTurnOffFilter(false),
                subscription: false,
            })
        );
        setAiIsVirtualItemBuy();
        setAiIsVirtualItemSpent();
        LeanplumAnalytics.setUserAttributesCustom({ subscriber: this.isUserSubscriber() });
    }
    // TODO: need to test ALL methods related to opening the panel, a hack is now added, because react.csstransition does not work correctly
    public openSignInPanel() {
        setTimeout(() => {
            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            store.dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
            store.dispatch(setSignInUpCopyState(true));
        }, 300);
    }

    /** *******TODO: create universal method  */
    public switchAdvantageToSignIn(tab: HeaderSideMenuTabs) {
        if (window.matchMedia('(min-width: 1025px)').matches) {
            store.dispatch(setSideMenuActivePage(tab));
            store.dispatch(setSideMenuOpened(true));
        } else {
            store.dispatch(setSideMenuOpened(false));
            store.dispatch(
                setProfilePanelState({
                    isOpened: true,
                    caption: TranslationService.translateIt('REGISTER_SIGN_IN'),
                    target: ProfileRightSideRoutes.SIGN_IN,
                })
            );
        }
    }
    /** ******* */

    public openResetPanel() {
        setTimeout(() => {
            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            store.dispatch(setSignInUpState(SignInUpComponents.CONFIRM_RESET_PASSWORD));
        }, 300);
    }

    public openRequestEmail() {
        setTimeout(() => {
            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            store.dispatch(setSignInUpState(SignInUpComponents.REQUEST_EMAIL));
        }, 300);
    }

    public openSignUpPanel() {
        setTimeout(() => {
            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            store.dispatch(setSignInUpState(SignInUpComponents.SIGN_UP));
        }, 300);
    }

    public openSubscriptionPanel() {
        setTimeout(() => {
            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SUBSCRIPTION_TAB));
        }, 300);
    }

    public openAfterSignPanel() {
        setTimeout(() => {
            store.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            store.dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
        }, 300);
    }

    public closePanel() {
        store.dispatch(setSideMenuOpened(false));
        store.dispatch(
            setProfilePanelState({
                isOpened: false,
                target: ProfileRightSideRoutes.NOOP,
                caption: '',
            })
        );
    }

    public async getGoogleButton(): Promise<string> {
        // const api = await this.authApiService.getAuthApi(Api.v1);
        // return api.auth.getGoogleButton();
        return 'https://login.arkadium.com/arenax-connect/azure.google.next.index-arkcom20230216.htmlapi2.html';
    }

    public async getFacebookButton(): Promise<string> {
        // const api = await this.authApiService.getAuthApi(Api.v1);
        // return api.auth.getFacebookButton();
        return 'https://login.arkadium.com/arenax-connect/azure.facebook.next.new.index0203.html';
    }

    public async loginViaFacebook(
        token: string,
        email: string,
        emailRetrievalConsent: boolean,
        registrationPlaceUrl: URL,
        password?: string,
        user?: UserAdditionalFieldsDto,
        captchaToken?: string
    ): Promise<SocialLoginProps> {
        const data = {
            token,
            user,
            ...(email && { email }),
            ...(emailRetrievalConsent && { emailRetrievalConsent }),
            ...(registrationPlaceUrl && { registrationPlaceUrl }),
            ...(password && { password }),
            captchaToken,
        };
        let res;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth
            .loginViaFacebook(data)
            .then((loginViaFacebookResult) => {
                res = { errorCode: 200, errorEmail: '', isRegistered: loginViaFacebookResult?.isRegistered };
                this.syncLocalWithDB();
            })
            .catch((err) => {
                console.error(err);
                res = {
                    errorCode: this.extractErrorCode(err),
                    errorEmail: this.extractErrorEmail(err),
                    isRegistered: {},
                };
                AppInsightService.trackAppError(err, { data: 'loginViaFacebook()', errorCode: res });
            });
        return res;
    }

    public async loginViaGoogle(
        token: string,
        email: string,
        emailRetrievalConsent: boolean,
        registrationPlaceUrl: URL,
        password?: string,
        user?: UserAdditionalFieldsDto,
        captchaToken?: string
    ): Promise<SocialLoginProps> {
        const data = {
            token,
            user,
            ...(email && { email }),
            ...(emailRetrievalConsent && { emailRetrievalConsent }),
            ...(registrationPlaceUrl && { registrationPlaceUrl }),
            ...(password && { password }),
            captchaToken,
        };
        let res;
        const api = await this.authApiService.getAuthApi(Api.v1);
        await api.auth
            .loginViaGoogle(data)
            .then((loginViaGoogleResult) => {
                res = {
                    errorCode: 200,
                    errorEmail: '',
                    isRegistered: loginViaGoogleResult?.isRegistered,
                };
                this.syncLocalWithDB();
            })
            .catch((err) => {
                console.error(err);
                res = {
                    errorCode: this.extractErrorCode(err),
                    errorEmail: this.extractErrorEmail(err),
                    isRegistered: {},
                };
                AppInsightService.trackAppError(err, { data: 'loginViaGoogle()', errorCode: res });
            });
        return res;
    }

    public getToken = async (): Promise<string> => {
        return this.authApiService.getToken();
    };

    public errorCodeToText(errorCode: number): string {
        const errorLabel = this.errorCodeToEnum(errorCode);

        if (ERRORS_DESCRIPTION[errorLabel]) {
            return ERRORS_DESCRIPTION[errorLabel];
        }

        return 'Unknown Server error';
    }

    public errorCodeToEnum(errorCode: number): string {
        return Object.keys(UserApiErrorV1).find((key) => UserApiErrorV1[key] === errorCode);
    }

    public manageErrorsEmailVsPass(res: number) {
        const passwordEnums = ['PasswordTooEasy'];
        const codeEnum = this.errorCodeToEnum(res);
        const message = { email: '', password: '' };
        if (passwordEnums.indexOf(codeEnum) !== -1) {
            message.password = this.errorCodeToText(res);
        } else {
            message.email = this.errorCodeToText(res);
        }
        return message;
    }

    public gameSignListenerAdd() {
        this.authApiService.addEventListener(Events.onWidgetStateChanged, this.handleGameSignListener);
    }

    public gameSignListenerRemove() {
        this.authApiService.removeEventListener(Events.onWidgetStateChanged, this.handleGameSignListener);
    }

    public authStateChangedListenerAdd() {
        this.authApiService.addEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
    }

    public authStateChangedListenerRemove() {
        this.authApiService.removeEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
    }

    public generateUrl() {
        return `${window.location.origin + window.location.pathname}`;
    }

    public generateHostUrl() {
        return `${window.location.origin}`;
    }

    public getSessionStorage() {
        return this.authApiService.getSessionStorage();
    }

    public isUserSubscriber() {
        const { activeUserSubscriptions } = store.getState();
        if (activeUserSubscriptions?.length === 1 && activeUserSubscriptions[0].platform.id !== PlatformType.INTERNAL) {
            return true;
        }
        if (activeUserSubscriptions?.length > 1) {
            const sortedSubscription = activeUserSubscriptions.sort(function (a, b) {
                return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
            });
            if (sortedSubscription[0].platform.id !== PlatformType.INTERNAL) {
                return true;
            }
        }
        return false;
    }

    public getSubscriptionId() {
        const { activeUserSubscriptions } = store.getState();
        if (activeUserSubscriptions?.length === 1 && activeUserSubscriptions[0].platform.id !== PlatformType.INTERNAL) {
            return activeUserSubscriptions[0].subscriptionId;
        }
        if (activeUserSubscriptions?.length > 1) {
            const sortedSubscription = activeUserSubscriptions.sort(function (a, b) {
                return (new Date(b.endDate) as any) - (new Date(a.endDate) as any);
            });
            if (sortedSubscription[0].platform.id !== PlatformType.INTERNAL) {
                return activeUserSubscriptions[0].subscriptionId;
            }
        }
        return '';
    }

    public getCurrentSubscription() {
        const { activeUserSubscriptions } = store.getState();
        if (activeUserSubscriptions?.length) {
            return activeUserSubscriptions[0];
        }
        return null;
    }

    public getSubscriptionType(currentUserSubscription?): SUBSCRIPTION_PLAN_NAME {
        const currentSubscription =
            currentUserSubscription !== undefined ? currentUserSubscription : this.getCurrentSubscription();
        const currentPlanId = currentSubscription?.planId;
        const type = getPlanName(currentPlanId);
        return type;
    }

    public getSubscriptionStatus(activeUserSubscriptions, expiredUserSubscriptions): SubscriptionStatus {
        const active = activeUserSubscriptions || [];
        const expired = expiredUserSubscriptions || [];
        let status = SubscriptionStatus.NONE;

        if (active.length > 0) {
            status = SubscriptionStatus.ACTIVE;
        } else if (expired.length > 0) {
            status = SubscriptionStatus.EXPIRED;
        }
        return status;
    }

    public checkSubscriptionAutorenew(currentUserSubscription): SubscriptionAutoRenew {
        const isAutoRenewable = currentUserSubscription?.isAutoRenewable;
        let status;

        if (!currentUserSubscription) {
            status = SubscriptionAutoRenew.NONE;
        } else if (isAutoRenewable) {
            status = SubscriptionAutoRenew.AUTORENEW;
        } else {
            status = SubscriptionAutoRenew.CANCELLED;
        }

        return status;
    }

    public getSubscriptionLifecycleParam(currentUserSubscription): SubscriptionLifeCycle | string {
        let lifecycleParam = '';
        const lastPartOfParam = SubscriptionLifeCycle.RENEW;
        const renewalCount = currentUserSubscription?.renewalCount;

        if (renewalCount === 0) {
            lifecycleParam = SubscriptionLifeCycle.NEW;
        } else {
            const subscription_type_letter = this.getSubscriptionType(currentUserSubscription)?.[0].toLowerCase();
            lifecycleParam = `${renewalCount}_${subscription_type_letter}_${lastPartOfParam}`;
        }
        return lifecycleParam;
    }

    public isUserHasBonusOnly() {
        const { activeUserSubscriptions } = store.getState();

        if (activeUserSubscriptions?.length === 1 && activeUserSubscriptions[0].platform.id === PlatformType.INTERNAL) {
            return true;
        }

        return false;
    }

    private handleGameSignListener = (event) => {
        if (event?.isOpened) {
            this.openSignInPanel();
        } else {
            this.closePanel();
        }
    };

    // workaround for correct getting tokens from local storage

    private authStateChangedCounter = 0;

    public handleAuthStateChanged = () => {
        this.authStateChangedCounter += 1; // the first time always shows false
        // we need to track the second value
        if (this.authStateChangedCounter === 2) {
            this.handleLoginRedirect().finally(() => {
                store.dispatch(setUserProcessed(true));
                this.authStateChangedListenerRemove(); // and then remove the event listener
            });
        }
    };

    private initAPI() {
        const data: ApiGatewayInitParams = {
            server: new URL(
                environment.EAGLE_API_BASE_URL || 'https://arenacloud.cdn.arkadiumhosted.com/eagle-user-api-dev/'
            ),
            sessionStorageInitParams: {
                broadcastToIframe: true,
                receiveInIframe: false,
            },
        };
        this.authApiService = new ApiGateway(data);
        this.authStateChangedListenerAdd();
        this.authApiService.checkAuthorization();
    }

    private setAdfree(user: UserModel) {
        let adFree = false;
        const subscription = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';
        const adFreeCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';

        if (adFreeCookie || subscription) {
            adFree = true;
        } else if (user && user.email === this.adFreeEmail) {
            CookieService.setArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER, adFree);
            store.dispatch(
                setPreLoadData({
                    adFree: adFreeService.adFreeTurnOffFilter(adFree),
                })
            );
            adFree = true;
        }

        if (adFree) {
            AdFreeService.vignetteAdsFix();
        }

        if (adFree && UrlService.getPageType() === 'game page') {
            store.dispatch(setGameState(GameState.GAME));
        }
    }

    private extractErrorCode(error): number {
        let result = 400;
        if (error.body?.details && error.body?.details[0] && error.body?.details[0]?.ErrorCode) {
            result = error.body?.details[0]?.ErrorCode;
        } else if (error.body?.statusCode) {
            result = error.body?.statusCode;
        }
        return result;
    }

    private extractErrorEmail(error): string {
        let result = '';
        if (error.body?.details && error.body?.details[0] && error.body?.details[0]?.Data?.email) {
            result = error.body.details[0].Data.email;
        }
        return result;
    }

    private getErrorMessage(error) {
        return this.errorCodeToText(this.extractErrorCode(error));
    }

    private async syncLocalWithDB() {
        await RecentlyPlayedService.recentlyPlayedSyncLocalWithDB(this.getToken);
        await HighScoreService.scoreSyncLocalWithDB(this.getToken);
    }

    //TODO: You could add here any resets for smth steps flow
    public resetPurchaseFlow() {
        const giftCardStep = store.getState().giftCardStep;
        if (giftCardStep !== 0) {
            store.dispatch(setGiftCardStep(0));
        }
    }
}

export default new UserServiceClass();
