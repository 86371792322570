import ReactGA from 'react-ga4';

import { environment } from '../config/environment';
import { EnvironmentName } from '../constants/Environment';

export class GoogleAnalyticsService {
    static init() {
        ReactGA.initialize([
            {
                trackingId: environment.Name === EnvironmentName.PRODUCTION ? 'G-VK37QVQGG5' : 'G-GVJG3CJ0E3', // format: https://support.google.com/analytics/answer/11091026#zippy=%2Cin-this-article
            },
        ]);

        ReactGA.ga('set', 'anonymizeIp', true);

        // set never allowed for now (Jared recommendation)
        ReactGA.ga('set', 'allowAdPersonalizationSignals', false);

        // const localConsent = LocalStorageService.getItem(LS_COOKIE_CONSTS.TARGETING_CONSENT);
        // GoogleAnalyticsService.setGaConsent(localConsent);
        //
    }

    static setGaConsent(consent) {
        if (consent) {
            ReactGA.ga('set', 'allowAdPersonalizationSignals', true);
        } else {
            ReactGA.ga('set', 'allowAdPersonalizationSignals', false);
        }
    }

    static trackEvent(data: any = {}) {
        if (data.name) {
            ReactGA.event(data.name, data);
        } else {
            ReactGA.event(data);
        }
    }
}
