export const ArkCssBreakpoints = {
    ARK_EXTRA_SMALL_MOBILE: 320,
    ARK_ADS_SMALL_MOBILE: 368,
    ARK_SMALL_MOBILE_BELOW: 575,
    ARK_SMALL_MOBILE: 576,
    ARK_SMALL_MOBILE_FIGMA: 600,
    ARK_SMALL_MOBILE_FIGMA_ABOVE: 601,
    ARK_LARGE_MOBILE_BELOW: 767,
    ARK_LARGE_MOBILE: 768,
    ARK_EXTRA_LARGE_MOBILE: 1024,

    ARK_SMALL_DESKTOP_BELOW: 1024,
    ARK_SMALL_DESKTOP: 1025,
    ARK_MEDIUM_DESKTOP_BELOW: 1199,
    ARK_MEDIUM_DESKTOP: 1200,
    ARK_MEDIUM_LG_DESKTOP_BELOW: 1359,
    ARK_MEDIUM_LG_DESKTOP: 1360,
    ARK_IPAD_PRO_LANDSCAPE: 1366,
    ARK_IPAD_PRO_LANDSCAPE_ABOVE: 1367,
    ARK_LARGE_DESKTOP_BELOW: 1799,
    ARK_LARGE_DESKTOP: 1800,
    ARK_LARGE_DESKTOP_ABOVE: 1801,
};
