import { PageTypes } from '../client/constants/Pages';
import { AD_BLOCKER_LAST_CHECK_SESSION_STORAGE_KEY, adBlockDetector } from '../client/services/AdBlockerService';
import { DeviceDetector, DeviceType } from '../client/services/DeviceDetector';
import { SessionStorageService } from '../client/services/SessionStorage';
import { UrlService } from '../client/services/UrlService';
import { reduxStore } from '../client/store';
import { HttpUtils } from './HttpUtils';
import { MiscUtils } from './MiscUtils';

const { store } = reduxStore;

export class DeviceUtils {
    public static isPhoneDevice(): boolean {
        return this.deviceType === DeviceType.MOBILE;
    }

    public static isTabletDevice(): boolean {
        return this.deviceType === DeviceType.TABLET;
    }

    public static isPhoneOrTabletDevice(): boolean {
        const type = this.deviceType;
        return type === DeviceType.MOBILE || type === DeviceType.TABLET;
    }

    public static isDesktopDevice(): boolean {
        return this.deviceType === DeviceType.DESKTOP;
    }

    public static isStyleguideMobileMode(): boolean {
        if (MiscUtils.isServer) {
            return false;
        }
        return window.matchMedia(`(max-width: 1024px)`).matches;
    }

    public static isRetina(): boolean {
        if (MiscUtils.isServer) {
            return false;
        }
        return window.matchMedia(`(min-resolution: 2dppx)`).matches;
    }

    public static isOrientationPortrait(): boolean {
        if (MiscUtils.isServer) {
            return false;
        }
        return window.matchMedia(`(orientation: portrait)`).matches;
    }

    public static isOrientationPortraitByJS(): boolean {
        if (MiscUtils.isServer) {
            return false;
        }

        const isAndroid = /(android)/i.test(navigator.userAgent);
        let isPortrait = false;

        if (isAndroid) {
            if (screen.width < screen.height) {
                // portrait mode on Android
                isPortrait = true;
            }
        } else if (window.orientation === 0) {
            // TODO: window.orientation was deprecated, need to find another solution
            // portrait mode iOS and other devices
            isPortrait = true;
        }

        return isPortrait;
    }

    static async getEnvInfo() {
        const deviceDetector = new DeviceDetector();
        // ad blocker
        const adBlockersLastCheck: any =
            SessionStorageService.getItem(AD_BLOCKER_LAST_CHECK_SESSION_STORAGE_KEY) || null;
        let adBlockersLastCheckUsed = false;
        try {
            adBlockersLastCheckUsed = adBlockersLastCheck ? JSON.parse(adBlockersLastCheck)?.isAdBlockerUsed : false;
        } catch (e) {}
        const isAdBlockerActive = adBlockersLastCheckUsed || adBlockDetector.adBlockerUsed;
        // browser
        const browserInfo = deviceDetector.DetectBrowser();
        let browserVersion = browserInfo.version;

        try {
            const v = browserInfo.version;
            browserVersion = v.substring(0, v.indexOf('.'));
        } catch (e) {}

        // is mobile device (phone or tablet)
        const deviceType = deviceDetector.DetectDevice();
        const mobile = deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET;

        // operating system
        const osInfo = deviceDetector.DetectOS();
        const os = osInfo.name;
        const osVersion = osInfo.version;

        // TODO: consider to grab this data from redux store
        // getting game name
        let gameName = '';
        if (window.location.pathname.indexOf('/games/') !== -1) {
            const parts = window.location.pathname.split('/games/');
            gameName = parts[parts.length - 1].replace('/', '');
        }
        if (window.location.pathname.indexOf('/contact/') !== -1) {
            gameName = 'Contact page';
        }
        if (
            window.location.pathname.indexOf(
                `/${UrlService.getPageLocalizedRoute(UrlService.currentLang, PageTypes.Category)}/`
            ) !== -1
        ) {
            gameName = 'Games page';
        }

        //get geoData - IP and GEO location
        const getGeoData = async () => {
            return HttpUtils.fetch('https://geolocation-db.com/json/').catch((err) => {
                console.log(err);
            });
        };

        const geoData = await getGeoData();

        // adding info to the global object
        return {
            isAdBlockerActive,
            browser: browserInfo.name,
            browserVersion,
            mobile,
            deviceType,
            os,
            osVersion,
            url: window.location.href,
            hostName: window.location.host,
            gameName,
            geoData,
        };
    }

    private static dt: DeviceType = null;

    private static get deviceType(): DeviceType {
        if (!this.dt) {
            this.dt = store.getState().deviceType;
        }
        return this.dt;
    }
}
