import { StringUtils } from '../../utils/StringUtils';
import { defaultSupportedLocales } from '../constants/Locales';
import { Game, IGame } from '../models/Game/Game';
import { GameModel } from '../models/Game/GameModel';
import { CategoryPageSEO } from '../models/PagesData';
import { TranslationService } from './TranslationService';

export class GameService {
    public static findGameByArena5Slug(games: IGame[], arena5slug: string): IGame {
        const game = games.find((g) => StringUtils.equalIgnoreCase(g.slug, arena5slug));
        return game;
    }

    public static findGameByArkadiumSlug(games: IGame[], arkadiumSlug: string): IGame {
        const game = games.find((g) => StringUtils.equalIgnoreCase(g.alias, arkadiumSlug));
        return game;
    }

    public static findGameRecommendedGames(games: IGame[], arkadiumSlug: string): IGame[] {
        const game = games.find((g) => StringUtils.equalIgnoreCase(g.alias, arkadiumSlug));
        const result: IGame[] = [];
        if (game) {
            game.recommendedGames.forEach((rec) => {
                const recGame = games.find((g) => StringUtils.equalIgnoreCase(g.slug, rec));
                if (recGame) {
                    result.push(recGame);
                }
            });
        }
        return result;
    }

    public static gameModelToGame(games: GameModel[]): IGame[] {
        return games.map((g) => new Game(g));
    }

    // covert Igame in GameModel
    public static gameToGameModel(game: IGame): GameModel {
        return game.rawGameData;
    }

    public static isLanguageSupportedByGame(game: IGame, lang: string): boolean {
        if (game?.locales?.find?.((locale) => StringUtils.equalIgnoreCase(locale, lang))) {
            return true;
        }
        return false;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public static isLanguageSupportedByCategory(
        category: CategoryPageSEO,
        games: IGame[],
        currentLang: string,
        lang: string
    ): boolean {
        return true;
        // TODO: until we have category names as entities to compare we do not have ability
        // to detect whether category support language, so for now just return true

        // const gamesWithCurrentCategory = games.filter (g => {
        //     return g.categories.find(c => StringUtils.equalIgnoreCase (c, category.name[currentLang]));
        // });
        // // tslint:disable-next-line: prefer-for-of
        // for (let index = 0; index < gamesWithCurrentCategory.length; index++) {
        //     const game = games[index];
        //     if (game.locales.find(locale => StringUtils.equalIgnoreCase(locale, lang))){
        //         return true;
        //     }
        // }
        // return false;
    }

    public static filterGamesByLang(games: IGame[], lang: string): IGame[] {
        let langFilter: string = lang;
        if (defaultSupportedLocales.find((l) => StringUtils.equalIgnoreCase(l, lang))) {
            langFilter = `en`;
        }
        const result = games.filter((g) => g.locales.find((locale) => StringUtils.equalIgnoreCase(locale, langFilter)));

        return result;
    }

    public static getNewCategoryGames(games: IGame[]): IGame[] {
        let result = games.filter((g) =>
            g.categories.find((c) => StringUtils.equalIgnoreCase(c, TranslationService.translateIt('NEW_CAT')))
        );

        result = result.sort((a, b) => b.gameReleaseDate - a.gameReleaseDate);

        return result;
    }

    public static getByCategoryGames(games: IGame[], category: string): IGame[] {
        let result = games.filter((g) => g.categories.find((c) => StringUtils.equalIgnoreCase(c, category)));

        result = result.sort((a, b) => b.gameReleaseDate - a.gameReleaseDate);

        return result;
    }
}
