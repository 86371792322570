import { ArenaEnvironmentType } from '@arkadium/modules';
import Cookies from 'js-cookie';

import { EnvironmentName } from '../client/constants/Environment';
import { pushSupportedPages } from '../client/constants/PushSupportedPages';
import { WIDTH_QUERY } from '../client/models/Enums';
import { RegistrationSource } from '../client/models/User/RegistrationSource';
import { RECAPTCHA_ACTIONS_TYPE } from '../client/services/hooks/useRecaptchaSimple';

enum AuthType {
    EmailPassword = 'EmailPassword',
    Facebook = 'Facebook',
    Google = 'Google',
}

type Options = {
    retries?: number;
    interval?: number;
    exponentialBackoff?: boolean;
};

export type SdkInfo = {
    GameKey: string;
    GameVersion: string;
    GameRelease: string;
    NestVersion: string;
    SdkVersion: string;
};

export class MiscUtils {
    public static get isServer(): boolean {
        return typeof window === 'undefined';
    }

    public static matchWidth(query: WIDTH_QUERY, value: string, ssrResult = false): boolean {
        if (MiscUtils.isServer) {
            return ssrResult;
        }
        const queryString = `(${query}: ${value}px)`;
        return window.matchMedia(queryString).matches;
    }

    public static clone(obj): any {
        return JSON.parse(JSON.stringify(obj));
    }

    public static isNumber(x: any): x is number {
        return typeof x === 'number';
    }

    public static isString(x: any): x is string {
        return typeof x === 'string';
    }

    public static isBoolean(x: any): x is boolean {
        return typeof x === 'boolean';
    }

    public static random(min, max): number {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    public static isAdBlockEnabled(): boolean {
        return (window as any).__ark_ads__ === undefined || typeof (window as any).__ark_ads__.AdVideo !== 'function';
    }

    public static pushNotificationsValidator(urlkey: string) {
        return !!pushSupportedPages.find(function (item) {
            return item === urlkey;
        });
    }

    public static getNowTimestampUTC(): number {
        return new Date().getTime();
    }

    public static fixMissingLang(lang: string): string {
        if (lang === 'es' || lang === 'de') {
            return 'en';
        }
        return lang;
    }

    public static debounce(wrappedFunction: () => any, delayInMilliSeconds: number): () => any {
        let debounceTimerId: number;

        return (...args) => {
            clearTimeout(debounceTimerId);
            debounceTimerId = window.setTimeout(() => {
                wrappedFunction.apply(this, args);
            }, delayInMilliSeconds);
        };
    }

    public static numberWithCommas(num: string | number): string {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    public static async getRecaptchaToken(key: string, action: RECAPTCHA_ACTIONS_TYPE): Promise<string> {
        return await window.grecaptcha.enterprise.execute(key, { action: action });
    }

    public static loadableRetry<R>(
        fn: () => Promise<R>,
        { retries = 3, interval = 500, exponentialBackoff = true }: Options = {}
    ) {
        return new Promise<R>((resolve, reject) => {
            fn()
                .then(resolve)
                .catch((error) => {
                    setTimeout(() => {
                        if (retries === 0) {
                            reject(error);
                            return;
                        }

                        // Passing on "reject" is the important part
                        MiscUtils.loadableRetry(fn, {
                            retries: retries - 1,
                            interval: exponentialBackoff ? interval * 2 : interval,
                        }).then(resolve, reject);
                    }, interval);
                });
        });
    }

    public static IsEnvUsingMsal(env: EnvironmentName): boolean {
        // check if automation cookie is on the browser to be able to skip this authentication
        const cookie = Cookies.get('arkAutomation');

        if (
            env === EnvironmentName.PRODUCTION ||
            env === EnvironmentName.LOCAL ||
            env === EnvironmentName.BRANCH ||
            env === EnvironmentName.DEV ||
            env === EnvironmentName.DEV2 ||
            env === EnvironmentName.DEV3 ||
            env === EnvironmentName.STAGING ||
            env === EnvironmentName.GAMESDEV ||
            env === EnvironmentName.GAMESSTAGING ||
            env === EnvironmentName.CANARY ||
            cookie === 'true'
        ) {
            return false;
        }
        return true;
    }

    public static getAuthSource(authProvider): RegistrationSource {
        let source = RegistrationSource.EMAIL; // default
        if (authProvider) {
            authProvider === AuthType.Facebook && (source = RegistrationSource.FACEBOOK);
            authProvider === AuthType.Google && (source = RegistrationSource.GOOGLE);
        }
        return source;
    }

    public static async initArenaApi(): Promise<SdkInfo> {
        if (MiscUtils.isServer) {
            return {} as SdkInfo;
        }

        const arenaApi = (window as any).__ArenaApi__;

        if (arenaApi) {
            const sdkApi = await arenaApi.getInstance(
                ['production', 'canary', 'staging', 'games-staging'].includes(BUILD_ENV) ? 'PROD' : 'DEV'
            );

            return sdkApi.analytics.getEnvVersion();
        }
        return {} as SdkInfo;
    }

    public static async getSdKInfo(): Promise<SdkInfo> {
        return MiscUtils.initArenaApi().then((info) => {
            return info;
        });
    }

    public static adaptEnvNameToMatchArenaEnvs(env: EnvironmentName): ArenaEnvironmentType {
        switch (env) {
            case EnvironmentName.STAGING:
            case EnvironmentName.GAMESSTAGING:
            case EnvironmentName.CANARY:
            case EnvironmentName.PRODUCTION:
                return 'prod';
            default:
                return 'dev';
        }
    }
}
