import { Api, ApiGateway, ApiGatewayInitParams, ApiSet } from '@arkadium/eagle-virtual-items-api-client';
import { CollectionItemDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/collection-item.dto';
import { CollectionDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/collection.dto';
import { UserCollectionUpdatedDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/user-collection-updated.dto';
import { UserCollectionDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/user-collection.dto';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';

import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { SessionStorageService } from './SessionStorage';
import UserService from './UserService';

export type MergedCollectionItemType = CollectionItemDto & { amount?: number };
export type MergedCollectionType = Omit<CollectionDto, 'items'> & { items: MergedCollectionItemType[] };

const ANONYMOUS_USER_UID = 'anonymousUserUid';

export type CollectionPrize = {
    amount: number;
    sku?: string;
    isDuplicate?: boolean;
    imageSrcEnabled?: string;
};

interface CollectionsServiceInterface {
    collectionsApiService: ApiGateway;
    generateCollectionPrize: (gameArena5Slug: string) => Promise<CollectionPrize | null>;
    generateAnonymousCollectionPrize: (gameArena5Slug: string) => Promise<CollectionPrize | null>;
    setAnonymousUserIdToSessionStorage: () => string;
    synchronizeAnonymousUserCollections: () => Promise<undefined | UserCollectionUpdatedDto[]>;
    getUserCollectionState: (collectionId: string) => Promise<UserCollectionDto>;
    getUserCollectionsForGame: (gameArena5Slug: string) => Promise<UserCollectionDto[]>;
    getDiscoveredUserCollections: () => Promise<CollectionDto[]>;
    getUndiscoveredUserCollections: () => Promise<UserCollectionDto[]>;
    getCollectionListForGame: (gameArena5Slug: string) => Promise<CollectionDto[]>;
    getMergedCollections: (
        gameCollectionList: CollectionDto[],
        userCollectionList: UserCollectionDto[]
    ) => MergedCollectionType[];
}

class CollectionsService implements CollectionsServiceInterface {
    public collectionsApiService: ApiGateway;

    constructor() {
        if (!MiscUtils.isServer) {
            this.initAPI();
        }
    }

    /**
     * Merge game collections with user's collections to show discovered and undiscovered simultaneously
     * @param gameCollectionList - game collection list for some game
     * @param userCollectionList - user collection list for some game
     * @return mergedCollectionList - array of merged collections
     */
    public getMergedCollections = (gameCollectionList: CollectionDto[], userCollectionList: UserCollectionDto[]) => {
        const newMergedCollectionList: MergedCollectionType[] = cloneDeep(gameCollectionList);

        //prettier-ignore
        userCollectionList
            .forEach((userCollection) => {
                const sameCollectionFromGameCollections = newMergedCollectionList
                    .find((gameCollection) => gameCollection.sku === userCollection.sku);

                sameCollectionFromGameCollections
                    ?.items
                    .forEach((gameCollectionItem) => {
                        const sameItemFromUserCollection = userCollection
                            .items
                            .find((userCollectionItem) => gameCollectionItem.sku === userCollectionItem.sku);

                        if (sameItemFromUserCollection) {
                            gameCollectionItem.amount = sameItemFromUserCollection.amount;
                        }
            });
        });

        return newMergedCollectionList;
    };

    /**
     * Generate collection prize for user.
     * In case of win returns prize object, else it returns null.
     * @param gameArena5Slug - ID of game to filter available collections
     * @return prizeObject - object containing info about prize
     */
    public generateCollectionPrize = async (gameArena5Slug: string): Promise<CollectionPrize | null> => {
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        const timeOffset = new Date().getTimezoneOffset();
        const updatedUserCollections = await api.userCollection.issueUserCollection({
            gameKey: gameArena5Slug,
            application: 'arkadium.com',
            timeOffset,
        });

        if (updatedUserCollections.length === 0) {
            return null;
        } else {
            const newItemSku = updatedUserCollections[0].newItems[0].sku;
            const newItemInCollection = updatedUserCollections[0].items.find((item) => item.sku === newItemSku);
            const isGranted = newItemInCollection && newItemInCollection.amount > 0;
            const prizeObject: CollectionPrize | null = isGranted
                ? {
                      sku: newItemInCollection.sku,
                      isDuplicate: newItemInCollection.amount > 1,
                      imageSrcEnabled: newItemInCollection.image,
                      amount: newItemInCollection.amount,
                  }
                : null;

            return prizeObject;
        }
    };

    /**
     * Generate collection prize for guest(anonymous) user.
     * In case of win returns prize object, else it returns null.
     * @param gameArena5Slug - ID of game to filter available collections
     * @return prizeObject - object containing info about prize
     */
    public generateAnonymousCollectionPrize = async (gameArena5Slug: string): Promise<CollectionPrize | null> => {
        const dataFromSessionStorage = SessionStorageService.getItem(ANONYMOUS_USER_UID);
        const anonymousUserUid = dataFromSessionStorage || this.setAnonymousUserIdToSessionStorage();

        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        const updatedInventory = await api.userCollection.issueAnonymousUserCollection({
            anonymousUserUid,
            gameKey: gameArena5Slug,
        });
        const updatedItems = updatedInventory.length > 0 ? updatedInventory[0].items : [];
        const amount = updatedItems.length;
        const prizeObject: CollectionPrize | null = amount > 0 ? { amount } : null;

        return prizeObject;
    };

    /**
     * Generate unique id for anonymous user and set it to session storage
     * @return anonymousUserUid - unique user's ID
     */
    public setAnonymousUserIdToSessionStorage = () => {
        const anonymousUserUid = uuid();
        SessionStorageService.setItem(ANONYMOUS_USER_UID, anonymousUserUid);
        return anonymousUserUid;
    };

    /**
     * Synchronize anonymous user's prizes with his registered ID
     * @return updatedInventory - updated user's inventory.
     * Contains items that user won as anonymous and items that he won as registered user,
     * if he has such
     */
    public synchronizeAnonymousUserCollections = async () => {
        const dataFromSessionStorage = SessionStorageService.getItem(ANONYMOUS_USER_UID);
        if (!dataFromSessionStorage) {
            return;
        }
        const anonymousUserUid = dataFromSessionStorage;
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        const updatedInventory = await api.userCollection.synchronizeAnonymous({ anonymousUserUid });
        return updatedInventory;
    };

    /**
     * Get current collection state for user.
     * @param collectionId - ID of collection to get state for
     * @return userCollectionState - state of collection
     */
    public getUserCollectionState = async (collectionId: string) => {
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        const userCollectionState = await api.userCollection.getUserCollection({ sku: collectionId });
        return userCollectionState;
    };

    /**
     * Get user inventory (all collections states) for current game.
     * @param gameArena5Slug - ID of game to filter available collections,
     * eagle api waits that game.arena5Slug will be used for this
     * @return userInventory - array of user's collections
     */
    public getUserCollectionsForGame = async (gameArena5Slug: string) => {
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        const userInventory = await api.userCollection.getUserCollections({ gameKey: gameArena5Slug });
        return userInventory;
    };

    /**
     * Get list of names of discovered collections
     * @return discoveredList - list of user's discovered collections
     * TODO: should be updated for pagination
     */
    public getDiscoveredUserCollections = async () => {
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        // skip & take need for pagination
        const requestResult = await api.userCollection.getDiscoveredList({ skip: 0, take: 999 });
        const discoveredList: CollectionDto[] = requestResult.data;
        return discoveredList;
    };

    /**
     * Get list of undiscovered collections
     * @return undiscoveredList - list of user's undiscovered collections
     * TODO: should be updated for pagination
     */
    public getUndiscoveredUserCollections = async () => {
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        // skip & take need for pagination
        const requestResult = await api.userCollection.getUndiscoveredList({ skip: 0, take: 999 });
        const undiscoveredList = requestResult.data;
        return undiscoveredList;
    };

    /**
     * Get list of available collections for current game
     * @param gameArena5Slug - ID of game to filter available collections,
     * eagle api waits that game.arena5Slug will be used for this
     * @return collectionList - array of all available collections for current game
     */
    public getCollectionListForGame = async (gameArena5Slug: string) => {
        const api: ApiSet = await this.collectionsApiService.getApi(Api.v1);
        const collectionList = await api.collection.getCollectionList(gameArena5Slug);
        return collectionList;
    };

    /**
     * Initialize top level API
     * @private
     */
    private initAPI() {
        const params: ApiGatewayInitParams = {
            server: new URL(
                environment.EAGLE_API_COLLECTIONS_URL ||
                    'https://eagle-virtual-item-api.uup-aks-dev.arkadiumhosted.com/'
            ),
            sessionStorage: UserService.getSessionStorage(),
        };

        this.collectionsApiService = new ApiGateway(params);
    }
}

export default new CollectionsService();
