import { PaymentMethod } from '../../models/Subscription/PaymentForm';
import { SubscriptionSourceType } from '../../models/Subscription/SubscriptionData';
import UserService from '../UserService';
import { SubscriptionAnalyticsAi } from './AI/SubscriptionAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { SubscriptionAnalyticsGa } from './GA/SubscriptionAnalyticsGa';

export class SubscriptionAnalytics {
    aiAnalytics = new SubscriptionAnalyticsAi();

    gaAnalytics = new SubscriptionAnalyticsGa();

    getPlanCode = () => {
        return UserService.getSubscriptionType();
    };

    async entryPointImpression(location: SubscriptionSourceType): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.entryPointImpression(location),
            this.aiAnalytics.entryPointImpression(location)
        );
    }

    async entryPointClick(location: SubscriptionSourceType, type: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.entryPointClick(location, type),
            this.aiAnalytics.entryPointClick(location, type)
        );
    }

    async removeAdsGameScreen(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.removeAdsGameScreen());
    }

    async removeAdsAdBlockScreen(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(null, this.aiAnalytics.removeAdsAdBlockScreen());
    }

    async subscriptionNextStep(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.subscriptionNextStep(location, type, payment),
            this.aiAnalytics.subscriptionNextStep(location, type, payment)
        );
    }

    async subscriptionPurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.subscriptionPurchaseButton(location, type, payment),
            this.aiAnalytics.subscriptionPurchaseButton(location, type, payment)
        );
    }

    async subscriptionPurchase(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod,
        subscriptionId: string,
        gameSlug?: string | boolean
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.subscriptionPurchase(location, type, payment),
            this.aiAnalytics.subscriptionPurchase(location, type, payment, subscriptionId, gameSlug)
        );
    }

    async manageSubscription(type: string = this.getPlanCode()): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.manageSubscription(type),
            this.aiAnalytics.manageSubscription(type)
        );
    }

    async renewSubscription(type: string = this.getPlanCode()): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.renewSubscription(type), this.aiAnalytics.renewSubscription(type));
    }

    async cancelAutorenewal(type: string = this.getPlanCode()): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.cancelAutorenewal(type), this.aiAnalytics.cancelAutorenewal(type));
    }

    async subscriptionUpgrade(type: string = this.getPlanCode()): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.subscriptionUpgrade(type),
            this.aiAnalytics.subscriptionUpgrade(type)
        );
    }

    async subscriptionDowngrade(type: string = this.getPlanCode()): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.subscriptionDowngrade(type),
            this.aiAnalytics.subscriptionDowngrade(type)
        );
    }

    async giftStart(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftStart(location, type, payment),
            this.aiAnalytics.giftStart(location, type, payment)
        );
    }

    async giftNextStep(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftNextStep(location, type, payment),
            this.aiAnalytics.giftNextStep(location, type, payment)
        );
    }

    async giftPurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftPurchaseButton(location, type, payment),
            this.aiAnalytics.giftPurchaseButton(location, type, payment)
        );
    }

    async giftEmailCheck(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftEmailCheck(location, type, payment),
            this.aiAnalytics.giftEmailCheck(location, type, payment)
        );
    }

    async giftRedeemed(
        location: SubscriptionSourceType,
        type: string,
        payment: PaymentMethod,
        subscriptionId: string,
        newRegister: boolean
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftRedeemed(location, type, payment, subscriptionId, newRegister),
            this.aiAnalytics.giftRedeemed(location, type, payment, subscriptionId, newRegister)
        );
    }

    async giftPaymentFieldOnBlur(
        name: string,
        location: SubscriptionSourceType,
        type: string
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftPaymentFieldOnBlur(name, location, type),
            this.aiAnalytics.giftPaymentFieldOnBlur(name, location, type)
        );
    }

    async giftPayPal(location: SubscriptionSourceType, type: string): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.giftPayPal(location, type),
            this.aiAnalytics.giftPayPal(location, type)
        );
    }

    async gifterPayPalConfirm(): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(this.gaAnalytics.gifterPayPalConfirm(), this.aiAnalytics.gifterPayPalConfirm());
    }

    async gifterPurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        paymentMethod: string
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.gifterPurchaseButton(location, type, paymentMethod),
            this.aiAnalytics.gifterPurchaseButton(location, type, paymentMethod)
        );
    }

    async gifterPrePurchaseButton(
        location: SubscriptionSourceType,
        type: string,
        paymentMethod: string
    ): Promise<AnalyticsEventsPair> {
        return await makeEventsPair(
            this.gaAnalytics.gifterPrePurchaseButton(location, type, paymentMethod),
            this.aiAnalytics.gifterPrePurchaseButton(location, type, paymentMethod)
        );
    }
}
