import { MiscUtils } from '../../utils/MiscUtils';
import { Environment } from '../constants/Environment';
import { environment as branchEnv } from './environment.branch';
import { environment as devEnv } from './environment.dev';
import { environment as devEnv2 } from './environment.dev2';
import { environment as devEnv3 } from './environment.dev3';
import { environment as localEnv } from './environment.local';
import { environment as prodEnv } from './environment.production';
import { environment as gamesDevEnv } from './environment.games-dev';
import { environment as gamesStagingEnv } from './environment.games-staging';
import { environment as canaryEnv } from './environment.canary';
import { environment as stageEnv } from './environment.staging';

let env: Environment;

declare var STORYBOOK: boolean;

if (MiscUtils.isServer) {
    switch (BUILD_ENV) {
        case 'production':
            env = prodEnv;
            break;
        case 'dev':
            env = devEnv;
            break;
        case 'dev2':
            env = devEnv2;
            break;
        case 'dev3':
            env = devEnv3;
            break;
        case 'branch':
            env = branchEnv;
            break;
        case 'local':
            env = localEnv;
            break;
        case 'games-dev':
            env = gamesDevEnv;
            break;
        case 'games-staging':
            env = gamesStagingEnv;
            break;
        case 'staging':
            env = stageEnv;
            break;
        case 'canary':
            env = canaryEnv;
            break;
        default:
            env = devEnv;
            break;
    }
} else {
    env = window.__ENVIRONMENT_STATE__;
}
console.log(`BUILD_ENV=${BUILD_ENV}`);
export const environment: Environment = STORYBOOK === true ? localEnv : env;
