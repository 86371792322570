import 'cross-fetch/polyfill';

export class HttpUtils {
    public static loadScript(url, token?: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script: any = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            if (token) {
                script['data-ad-client'] = token;
            }
            if (script.readyState) {
                // IE
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        resolve();
                    }
                };
            } else {
                // Others
                script.onload = resolve;
                script.onerror = reject;
            }
            script.src = url;
            document.head.appendChild(script);
        });
    }

    public static async fetch(url, options?, jsonResponse = true): Promise<any> {
        options = options || {};
        options.headers = options.headers || new Headers();
        // options.headers.set('Content-type', 'application/json');

        const response = await fetch(url, options);
        HttpUtils.assertFetchStatus(response);
        return jsonResponse ? response.json() : response.text();
    }

    private static assertFetchStatus(res): void {
        if (!(res.status >= 200 && res.status < 300)) {
            throw new Error(`Request failed: ${res.status} - ${res.statusText}`);
        }
    }
    // retry 3 times with exponential backoff (delay doubles after each attempt)
    // initial attempt -- immediately
    // 1st retry after 1s
    // 2nd retry after 2s
    // 3rd retry after 4s
    // in case of exhausted attempts, or 404 at any time, throw error and retry in 60 minutes
    // resume retrying after 60 minutes until successful
    public static async fetchWithRetry(fn, maxAttempts = 3, initialDelay = 1000, retryWaitPeriod = 1000 * 60 * 60) {
        let attempt = 0;
        let delay = initialDelay;

        while (true) {
            try {
                return await fn();
            } catch (error) {
                attempt++;
                if (error.status === 404 || attempt >= maxAttempts) {
                    console.log(`All ${maxAttempts} attempts failed; retrying again in ${retryWaitPeriod} minutes...`);
                    await new Promise((resolve) => setTimeout(resolve, retryWaitPeriod)); // wait for 60 minutes
                    attempt = 0;
                    delay = initialDelay;
                } else {
                    console.log(`Attempt ${attempt} failed; retrying in ${delay}ms...`);
                    await new Promise((resolve) => setTimeout(resolve, delay));
                    delay *= 2;
                }
            }
        }
    }
}
