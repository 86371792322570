import { environment } from '../../../config/environment';
import { IGame } from '../../../models/Game/Game';
import { Analytics } from '../Analytics';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';

export class GamesAnalyticsGa {
    gameStart(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_Content_Start');
        event.setEventGaAction(game.slug);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug));
        event.setGaNonInteraction(false);
        return event.build();
    }

    funnelEnd(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_End_Screen');
        event.setEventGaAction(game.slug);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game end', game.slug));
        event.setGaNonInteraction(false);
        return event.build();
    }

    prerollStart(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_PreRoll_Request');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug));
        event.setGaNonInteraction(true);
        return event.build();
    }

    rewardVideoStart(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_Reward_Request');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug));
        event.setGaNonInteraction(true);
        return event.build();
    }

    interstitialVideoStart(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_Interstitial_Request');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug));
        event.setGaNonInteraction(true);
        return event.build();
    }

    playAgainImpression(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_End_Button_PlayAgain');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug));
        event.setGaNonInteraction(true);
        return event.build();
    }

    playAgainClick(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('Funnel_End_Button_PlayAgain');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(Analytics.generateLabel(environment.ARENA_DOMAIN, 'game start', game.slug));
        event.setGaNonInteraction(false);
        return event.build();
    }

    earlyAccessJoinButton(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('earlyAccessJoinButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(game.slug);
        event.setGaNonInteraction(false);
        return event.build();
    }

    earlyAccessJoinButtonImpression(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('earlyAccessJoinButton');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(game.slug);
        event.setGaNonInteraction(true);
        return event.build();
    }

    numberTimesPlay(game: IGame, times): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('numberTimesPlay');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(
            Analytics.generateLabel(environment.ARENA_DOMAIN, `numberTimesPlay ${times}`, game.slug)
        );
        event.setGaNonInteraction(true);
        return event.build();
    }

    numberPlayVisits(game: IGame, visits): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('numberPlayVisits');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(
            Analytics.generateLabel(environment.ARENA_DOMAIN, `numberPlayVisits ${visits}`, game.slug)
        );
        event.setGaNonInteraction(true);
        return event.build();
    }

    fullScreenButtonClick(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('fullScreenButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(game.slug);
        event.setGaNonInteraction(false);
        return event.build();
    }

    fullScreenExitButtonClick(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('fullScreenExitButton');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(game.slug);
        event.setGaNonInteraction(false);
        return event.build();
    }

    fullScreenToolTipImpression(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('fullScreenToolTip');
        event.setEventGaAction(AnalyticsEventAction.IMPRESSION);
        event.setEventGaLabel(game.slug);
        event.setGaNonInteraction(false);
        return event.build();
    }

    fullScreenToolTipClick(game: IGame): AnalyticsEvent {
        const event = this.newEventBuilder();
        event.setEventGaCategory('fullScreenToolTip');
        event.setEventGaAction(AnalyticsEventAction.CLICK);
        event.setEventGaLabel(game.slug);
        event.setGaNonInteraction(false);
        return event.build();
    }

    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder();
    }
}
