import React, { FC, useEffect } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Avatar, AvatarTypes, BorderTypes } from '../../../../../atoms/Avatar/Avatar';
import { environment } from '../../../../../config/environment';
import { MonthsShort } from '../../../../../constants/Months';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { ArkadiumAdvantageLogo } from '../../../../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { SubscriptionSource } from '../../../../../models/Subscription/SubscriptionData';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { AppInsightService } from '../../../../../services/AppInsight';
import PaymentService from '../../../../../services/PaymentService';
import UserService from '../../../../../services/UserService';
import { setSnackbarData } from '../../../../../store/ducks/layout';
import { setSubscriptionSource } from '../../../../../store/ducks/subscription/common';
import { setHostedLoginToken } from '../../../../../store/ducks/user';
import { SubscriptionActions } from '../PaymentAndSubscription';
import styles from './AvatarCardBlock.css';

interface AvatarCardBlockProps {
    type: SubscriptionActions;
    date: string;
}

export const AvatarCardBlock: FC<AvatarCardBlockProps> = React.memo(({ type, date }: AvatarCardBlockProps) => {
    const user = useSelector((state) => state.user);
    const userAvatar = user?.avatar || user?.subscriberAvatar?.avatar || '';
    const hostedLoginToken = useSelector((state) => state.hostedLoginToken);
    const dispatch = useDispatch();

    const d = new Date(date);
    const dateNum = d.getDate();
    const month = MonthsShort[d.getMonth()];
    const year = d.getFullYear();

    useEffect(() => {
        if (!hostedLoginToken) {
            PaymentService.getRecurlyData()
                .then((res) => {
                    dispatch(setHostedLoginToken(res.hostedLoginToken));
                })
                .catch((err) => {
                    console.log(' PaymentService.getRecurlyData()', err);
                    AppInsightService.trackAppError(err, { data: 'getRecurlyData()' });
                });
        }
    }, []);

    const renewSubscription = () => {
        Analytics.trackEvent(Analytics.subscription.renewSubscription());
        dispatch(
            setSnackbarData({
                isOpened: true,
                message: `If you've recently updated your subscription, please refresh this page.`,
                type: 'success',
                static: true,
            })
        );
    };
    return (
        <div
            className={classNames(styles.containerExtended, {
                [styles.restart]: type === SubscriptionActions.RESTART,
                [styles.renew]: type === SubscriptionActions.RENEW,
            })}
        >
            <div className={styles.container}>
                <div className={styles.containerBg} />
                <div className={styles.avatarBlock}>
                    <Avatar
                        image={userAvatar}
                        selectable={false}
                        selected={false}
                        size={AvatarTypes.REGULAR_ADVANTAGE2}
                        onSelect={() => null}
                        disabled
                        border={borderMapping(type)}
                        noFrame
                    />
                </div>
                <ArkadiumAdvantageLogo className={styles.logoImg} />
                <div className={styles.bottomText}>
                    {[
                        SubscriptionActions.STANDART,
                        SubscriptionActions.SUBSCRIBED_BOTH,
                        SubscriptionActions.SUBSCRIBED_EXTERNAL,
                    ].includes(type) && <p>Subscriber since</p>}
                    {type === SubscriptionActions.RENEW && <p>Your subscription ends</p>}
                    {type === SubscriptionActions.RESTART && <p>Your subscription ended on</p>}
                    <p className={styles.date}>
                        {month} {dateNum}, {year}
                    </p>
                    <div className={styles.bottomBlock}>
                        {type === SubscriptionActions.RENEW && (
                            <LinkBlock hostedLoginToken={hostedLoginToken} handleRenew={renewSubscription} />
                        )}
                        {type === SubscriptionActions.RESTART && <ButtonRestart dispatch={dispatch} />}
                    </div>
                </div>
            </div>
        </div>
    );
});

const LinkBlock: FC<{ hostedLoginToken: string; handleRenew: () => void }> = React.memo(
    ({ hostedLoginToken, handleRenew }) => {
        return (
            <a
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
                href={`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`}
                onClick={handleRenew}
            >
                Renew Subscription
            </a>
        );
    }
);

const ButtonRestart: FC<{ dispatch: any }> = React.memo(({ dispatch }) => {
    return (
        <Button
            className={styles.btnSubscribe}
            onClick={() => {
                dispatch(setSubscriptionSource(SubscriptionSource.AVATAR_PANEL));
                UserService.openSubscriptionPanel();
            }}
        >
            Restart subscription
        </Button>
    );
});

const borderMapping = (type: SubscriptionActions) => {
    return {
        [SubscriptionActions.NONE]: BorderTypes.DEFAULT,
        [SubscriptionActions.STANDART]: BorderTypes.GOLD,
        [SubscriptionActions.RENEW]: BorderTypes.GOLD,
        [SubscriptionActions.SUBSCRIBED_BOTH]: BorderTypes.GOLD,
        [SubscriptionActions.SUBSCRIBED_EXTERNAL]: BorderTypes.GOLD,
        [SubscriptionActions.RESTART]: BorderTypes.DISABLED,
    }[type];
};
