import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import EmailValidator from 'email-validator';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import styles from './SupportForm.css';
import { DeviceUtils } from '../../../../../utils/DeviceUtils';
import { HttpUtils } from '../../../../../utils/HttpUtils';
import { AppLoader } from '../../../../atoms/AppLoader/AppLoader';
import { Button } from '../../../../atoms/Buttons/Base/Base';
import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Checkbox } from '../../../../atoms/Checkbox/Checkbox';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { CheckboxTypes } from '../../../../atoms/Icons/Styleguide/CheckboxIcon';
import { CloseIcon } from '../../../../atoms/Icons/Styleguide/CloseIcon';
import { Block } from '../../../../atoms/Layout/Block/Block';
import { DesktopQuery, MobileQuery, Responsive } from '../../../../atoms/Layout/Responsive';
import { Textarea } from '../../../../atoms/Textarea/Textarea';
import { TextField, TextFieldTypes } from '../../../../atoms/TextField/TextField';
import { environment } from '../../../../config/environment';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { FileUploadConstants } from '../../../../constants/FileUpload';
import { PageTypes } from '../../../../constants/Pages';
import { QUERY_STRING_CONSTS } from '../../../../models/Enums';
import { IGame } from '../../../../models/Game/Game';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { SessionStorageService } from '../../../../services/SessionStorage';
import { TranslationService } from '../../../../services/TranslationService';
import UserService from '../../../../services/UserService';
import { widgetSizeService } from '../../../../services/WidgetSizeService';
import { DefaultQuestionsList } from '../HelpTab.data';

type SupportFormProps = {
    pageType: PageTypes;
    game: IGame;
    onSuccess: () => void;
    onCancel: () => void;
    setRelatedArticles: (v) => void;
    currentLang: string;
};

export const SupportForm = React.memo(
    ({ pageType, game, onSuccess, onCancel, currentLang, setRelatedArticles }: SupportFormProps) => {
        const isUserSubscriber = UserService.isUserSubscriber();
        const isUserHasBonusOnly = UserService.isUserHasBonusOnly();
        const expiredUserSubscriptions = useSelector((state) => state.expiredUserSubscriptions)?.length > 0;
        const userEmail = useSelector((state) => state.user)?.email;

        const formDataKey = 'formData';
        const errorTimeout = 14000;
        const messageAttachment = (
            <>
                <MobileQuery>
                    <div>
                        <I18nText as="p" keyName="ATTACH_FILES" />
                        <span>
                            1 {TranslationService.translateIt('FILE')} ({FileUploadConstants.filesizeString}{' '}
                            {TranslationService.translateIt('MAX')})
                        </span>
                    </div>
                </MobileQuery>
                <DesktopQuery>
                    <div>
                        <I18nText as="p" keyName="CLICK_HERE_TO_ATTACH_FILE_OR_DRAG_AND_DROP" />
                        <span className={styles.maxSizeText}>
                            1 {TranslationService.translateIt('FILE')} ({FileUploadConstants.filesizeString}{' '}
                            {TranslationService.translateIt('MAX')})
                        </span>
                    </div>
                </DesktopQuery>
            </>
        );

        let nameStatus = true;
        let subjectStatus = true;
        let emailStatus = true;
        let agreementAcceptance = true;

        useEffect(() => {
            if (getSubjectState()) searchRelatedArticles(getSubjectState());
            SessionStorageService.setItem('attachedFile', '');
        }, []);

        useEffect(() => {
            if (!nameText.trim()) {
                nameStatus = false;
            }

            if (!subjectText.trim()) {
                subjectStatus = false;
            }

            if (!EmailValidator.validate(emailText as string)) {
                emailStatus = false;
            }

            if (!agreeToProcessData) {
                agreementAcceptance = false;
            }
        });

        const getSubjectState = () => {
            const storage = SessionStorageService.getSessionData(formDataKey, 'subjectText');
            const subject = pageType !== PageTypes.Game && storage ? storage : (game as any)?.gameData?.name || '';
            return subject;
        };

        const [subjectText, onSubjectChange] = useState(getSubjectState());
        const [nameText, onNameChange] = useState(SessionStorageService.getSessionData(formDataKey, 'nameText') || '');
        const [descriptionText, onDescriptionChange] = useState(
            SessionStorageService.getSessionData(formDataKey, 'descriptionText')
        );
        const [emailText, onEmailChange] = useState(SessionStorageService.getSessionData(formDataKey, 'emailText'));
        const [agreeToProcessData, onAgreeToProcessDataChange] = useState(
            !!SessionStorageService.getSessionData(formDataKey, 'agreeToProcessData')
        );
        const [formValidationObject, setFormValidStatus] = useState({
            nameStatus: true,
            subjectStatus: true,
            emailStatus: true,
            agreementAcceptance: true,
        });
        const [articles, onSubjectChangeSearch] = useState({
            recommended: [],
            popular: DefaultQuestionsList[currentLang],
        });
        const [attachments, setAttachments] = useState(null);
        const [dropZone, onDropZoneChange] = useState(false);
        const [loader, onLoaderChange] = useState(false);
        const [attachmentFormMessage, onAttachmentFormMessageChange] = useState(1);
        const [errorMessage, onErrorMessageChange] = useState(<div />);

        useEffect(() => {
            setRelatedArticles(articles);
        }, [articles]);

        const validateForm = () => {
            setFormValidStatus({
                nameStatus,
                subjectStatus,
                emailStatus,
                agreementAcceptance,
            });
            return nameStatus && subjectStatus && emailStatus && agreementAcceptance;
        };

        const onSubmit = (e) => {
            e.preventDefault();
            // this hack was added for accessibility, need to re-implement the entire form on react-hook-form in the future
            // timeout added to catch invalid input
            setTimeout(() => {
                const firstInvalidInput = document.querySelector('input[aria-invalid="true"]') as HTMLElement;
                if (firstInvalidInput) firstInvalidInput.focus();
            }, 0);
            if (validateForm()) {
                SessionStorageService.removeItem(formDataKey);
                submitForm({
                    name: nameText.replace(/<\/?[^>]+(>|$)/g, ''),
                    subject: subjectText.replace(/<\/?[^>]+(>|$)/g, ''),
                    description: descriptionText.replace(/<\/?[^>]+(>|$)/g, ''),
                    email: emailText.replace(/<\/?[^>]+(>|$)/g, ''),
                    file: attachments,
                    agreeToProcessData,
                });
            }
        };

        const submitForm = async (data) => {
            Analytics.trackEvent(Analytics.newHome.searchNavFeedbackClick(`send`));
            onSuccess();

            const env = { ...(await DeviceUtils.getEnvInfo()) };

            const fd = new FormData();

            fd.append(
                'req',
                JSON.stringify({
                    ...data,
                    custom_fields: {
                        cf_os: `${env.os} ${env.osVersion}`,
                        cf_browser: `${env.browser} ${env.browserVersion}`,
                        cf_game_tag: env.gameName,
                        cf_arena_url: env.hostName,
                        cf_full_url: env.url,
                        cf_is_mobile: env.mobile,
                        cf_device_type: env.deviceType,
                        cf_is_adblock_active: env.isAdBlockerActive,
                        cf_screen_size: widgetSizeService.getScreenSize(),
                        cf_window_size: widgetSizeService.getWindowSize(),
                        cf_game_size: widgetSizeService.getGameSize(),
                        cf_language: getLanguage(),
                        cf_advantage: isUserSubscriber ? 'Yes' : 'No',
                        cf_bonus: isUserHasBonusOnly ? 'Yes' : 'No',
                        cf_expired_subscriptions: expiredUserSubscriptions ? 'Yes' : 'No',
                        cf_user_email: userEmail,
                        cf_ip: env.geoData?.IPv4,
                        cf_geo: `${env.geoData?.country_name} - GEO: ${env.geoData?.latitude},${env.geoData?.longitude}`,
                    },
                })
            );

            if (data.file && data.file[0]) {
                fd.append('file', data.file[0]);
            }

            HttpUtils.fetch('/support-ticket/', {
                method: 'POST',
                body: fd,
            }).then(() => {
                console.log('ticket sent');
            });
        };

        const resetValidationField = (field: string) => {
            setFormValidStatus({
                ...formValidationObject,
                [field]: true,
            });
        };

        async function searchRelatedArticles(subject: string) {
            const defaultResponse = {
                recommended: [],
                popular: DefaultQuestionsList[currentLang].slice(0, 9),
            };
            if (!subject?.length) {
                return onSubjectChangeSearch(defaultResponse);
            }
            const siteUrl = environment.SITE_BASE_URL.replace(/\/$/, '').replace('localhost', 'www-dev3.arkadium.com'); // for localhost url support
            const getReqUrl = (subject) =>
                `${siteUrl}/get-freshdesk-articles?${QUERY_STRING_CONSTS.ARKVER}=${encodeURI(subject)}`;
            let subjectsFinalList;
            const subjectGeneral = subject;
            const subjectWords = subjectGeneral.split(' ');
            const subjects = [subjectGeneral, ...subjectWords];
            const questionsRequested = await Promise.all([
                ...subjects.map((subject) => HttpUtils.fetch(getReqUrl(subject), null, true)),
            ]).catch((err) => {
                console.log('Help articles fetch error', err);
                return [];
            });
            const questionsList = questionsRequested.reduce((acc, curr) => [...acc, ...curr], []);
            const questionsUnique = new Set(questionsList);
            const questionsAll = Array.from(questionsUnique);
            /* SORTING */
            const recommended = [];
            const popular = new Set();
            // 1-st iteration - best matches iteration (full subject in title)
            questionsAll.forEach((topic) => {
                if ((topic as any)?.title?.match?.(subjectGeneral)) {
                    recommended.push({
                        topic,
                        weight: 999,
                    });
                }
            });
            // 2-nd iteration - word in title matches iteration (subject word in title or folder name)
            questionsAll.forEach((topic) => {
                subjectWords.forEach((subject) => {
                    if ((topic as any)?.title?.match?.(subject)) {
                        recommended.push({
                            topic,
                            weight: 1,
                        });
                    } else if (!popular.has(topic)) {
                        popular.add(topic);
                    }
                });
                subjectWords.forEach((subject) => {
                    if ((topic as any)?.folder_name?.match?.(subject)) {
                        recommended.push({
                            topic,
                            weight: 2,
                        });
                    } else if (!popular.has(topic)) {
                        popular.add(topic);
                    }
                });
            });
            // 3-rd iteration - word in text matches iteration (subject word in article text)
            questionsAll.forEach((topic) => {
                [subjectGeneral, ...subjectWords].forEach((subject) => {
                    if ((topic as any)?.description_text?.match?.(subject)) {
                        recommended.push({
                            topic,
                            weight: 3,
                        });
                    } else if (!popular.has(topic)) {
                        popular.add(topic);
                    }
                });
            });
            const maxItemsForBoth = 10;
            const popularArrLengthMax = 5;
            const popularArr = Array.from(new Set([...DefaultQuestionsList[currentLang]])) // to avoid duplicates
                .filter(
                    (el) => el.title !== 'HELP_MORE' // to remove "More" item from default list as discussed
                );
            const popularFilter = [];
            const popularFiltered = popularArr.filter((el) => {
                const title = (el as any)?.title;
                const keep = !popularFilter.includes(title);
                popularFilter.push(title);
                return keep; // to avoid duplicates differing, but having same title
            });
            const popularSliced = popularFiltered.slice(0, popularArrLengthMax);
            const recommendedArr = Array.from(
                new Set(
                    Array.from(recommended)
                        .sort((a, b) => (b as any).weight - (a as any).weight) // sort by weight
                        .map((el) => (el as any).topic) // get rid of weight
                        .filter((el) => Boolean(el)) // get rid of empty
                        .filter(
                            (recommendedOne) =>
                                !popularSliced
                                    .map((popularOne) => popularOne.title)
                                    .includes((recommendedOne as any)?.title)
                        ) // get rid of duplicates of popular topics
                )
            );
            const recommendedFilter = [];
            const recommendedFiltered = recommendedArr.filter((el) => {
                const title = (el as any)?.title;
                const keep = !popularFilter.includes(title) && !recommendedFilter.includes(title);
                recommendedFilter.push(title);
                return keep; // to avoid duplicates differing, but having same title
            });
            const recommendedArrLengthMax = maxItemsForBoth - popularSliced.length;
            const recommendedSliced = recommendedFiltered.slice(0, recommendedArrLengthMax + 1);
            subjectsFinalList = {
                recommended: recommendedSliced,
                popular: popularSliced,
            };
            onSubjectChangeSearch(subjectsFinalList);
        }

        function getLanguage() {
            const browserLang: string = (window as any).navigator.userLanguage || (window as any).navigator.language;
            const short = browserLang?.split('-')?.[0];
            const availableLanguages = { en: 'English', fr: 'French', de: 'German', it: 'Italian', es: 'Spanish' };
            return availableLanguages[short];
        }
        // dragndrop
        const maxSize = FileUploadConstants.filesize;

        const onDrop = useCallback((acceptedFiles) => {
            setAttachments(acceptedFiles);
            onDropZoneChange(false);
            onLoaderChange(false);
            onAttachmentFormMessageChange(0);
            SessionStorageService.setItem('attachedFile', '1');
        }, []);

        const onDropRejected = useCallback((rejectFiles) => {
            setAttachments('');
            const errorsData = {};

            rejectFiles[0].errors.forEach((error) => {
                errorsData[error.code] = 1;
            });

            const errorMessageTemplate = (text1: string, text2: string) => {
                return (
                    <div className={styles.rejectedUploadText}>
                        <p>{text1}</p>
                        <span>{text2}</span>
                    </div>
                );
            };

            if (errorsData['file-invalid-type'] === 1 && errorsData['file-too-large'] === 1) {
                onErrorMessageChange(
                    errorMessageTemplate(
                        `${TranslationService.translateIt('FILE_TYPE_NOT_SUPPORTED_AND_IS_LARGER_THAN')} ${
                            FileUploadConstants.filesizeString
                        }`,
                        `${TranslationService.translateIt('FILE_MUST_BE')} ${FileUploadConstants.filetypes.join(', ')}`
                    )
                );
            } else if (errorsData['file-invalid-type'] === 1) {
                onErrorMessageChange(
                    errorMessageTemplate(
                        TranslationService.translateIt('FILE_TYPE_NOT_SUPPORTED'),
                        `${TranslationService.translateIt('FILE_MUST_BE')} ${FileUploadConstants.filetypes.join(', ')}`
                    )
                );
            } else if (errorsData['file-too-large'] === 1) {
                onErrorMessageChange(
                    errorMessageTemplate(
                        TranslationService.translateIt('FILE_SIZE_TOO_LARGE'),
                        `${TranslationService.translateIt('FILE_IS_LARGER_THAN')} ${FileUploadConstants.filesizeString}`
                    )
                );
            }

            hideErrorTimeout();
            onAttachmentFormMessageChange(2);
            setAttachments('');
            removeFile();
        }, []);

        const { isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, open } = useDropzone({
            multiple: undefined,
            onDragEnter: () => {
                onDropZoneChange(true);
                onAttachmentFormMessageChange(0);
            },
            onDragLeave: () => {
                onDropZoneChange(false);
                if (!attachments) onAttachmentFormMessageChange(1);
            },
            onDragOver: undefined,
            onDrop: (e) => {
                onLoaderChange(true);
                onDrop(e);
            },
            accept: FileUploadConstants.filetypesMime.join(','),
            minSize: 0,
            maxSize,
            onDropRejected,
            preventDropOnDocument: false,
            noDragEventsBubbling: false,
            noClick: true,
        });

        const removeFile = (replaceMessage = false) => {
            setAttachments(null);
            SessionStorageService.setItem('attachedFile', '');
            if (replaceMessage) {
                onAttachmentFormMessageChange(1);
            }
        };

        function hideErrorTimeout() {
            const timer = setTimeout(() => {
                if (SessionStorageService.getItem('attachedFile') !== '') {
                    onAttachmentFormMessageChange(1);
                }
                SessionStorageService.setItem('attachedFile', '');
            }, errorTimeout);
            return () => clearTimeout(timer);
        }

        function handleEnter(event, inputLength, isEmail?: boolean) {
            if (event.key === 'Enter') {
                const step = inputLength >= 3 ? 2 : 1;
                const { form } = event.target;
                const index = Array.prototype.indexOf.call(form, event.target);
                form.elements[index + step].focus();
                if (isEmail) {
                    setFormValidStatus({
                        nameStatus: true,
                        subjectStatus: true,
                        emailStatus,
                        agreementAcceptance: true,
                    });
                }
                event.preventDefault();
            }
        }

        const onClickAttachment = () => {
            if (SessionStorageService.getItem('attachedFile') !== '1') {
                open();
            }
        };

        const getFormWrapperProps = () => {
            const props: DropzoneRootProps = getRootProps();
            // We delete 'role' attribute, because
            // but in terms of a11y role attribute shouldn't be used here.
            delete props.role;
            return props;
        };

        return (
            <div {...getFormWrapperProps()} className={styles.formWrapper} tabIndex={-1}>
                <h2 className={styles.formH4}>{TranslationService.translateIt('HELP_TAB_FORM_HEADING')}</h2>
                <Responsive maxWidth={1024}>
                    <button className={styles.closeButton} onClick={onCancel}>
                        <CloseIcon />
                    </button>
                </Responsive>
                <form noValidate className={styles.form} onSubmit={onSubmit}>
                    <div
                        className={classNames(
                            styles.dropZoneOverlay,
                            { [styles.visible]: dropZone },
                            { [styles.rejectedDrop]: isDragReject }
                        )}
                    >
                        {isDragActive && !isDragReject && (
                            <div>
                                <I18nText as="p" keyName="DRAG_AND_DROP_FILE_HERE" />
                                <span>
                                    1 {TranslationService.translateIt('FILE')} ({FileUploadConstants.filesizeString}{' '}
                                    {TranslationService.translateIt('MAX')})
                                </span>
                                {acceptedFiles.length > 0 && (
                                    <I18nText
                                        as="p"
                                        keyName="THIS_NEW_FILE_WILL_REPLACE"
                                        className={styles.replaceFile}
                                    />
                                )}
                            </div>
                        )}
                        {isDragReject && (
                            <div>
                                <I18nText
                                    as="p"
                                    keyName="FILE_TYPE_NOT_SUPPORTED"
                                    className={styles.rejectedDropText}
                                />
                                <span>
                                    {TranslationService.translateIt('FILE_MUST_BE')}{' '}
                                    {FileUploadConstants.filetypes.join(', ')}
                                </span>
                            </div>
                        )}
                    </div>
                    <TextField
                        inputFieldId={'subject-field'}
                        required={true}
                        className={styles.formField}
                        value={subjectText}
                        onChange={(v) => {
                            resetValidationField('subjectStatus');
                            onSubjectChange(v);
                            SessionStorageService.setSessionData(formDataKey, 'subjectText', v);
                            if (v.length === 0) {
                                setRelatedArticles(DefaultQuestionsList[currentLang]);
                                onSubjectChangeSearch(DefaultQuestionsList[currentLang]);
                            }
                        }}
                        errorText={TranslationService.translateIt('HELP_TAB_FORM_ERROR')}
                        inputType={TextFieldTypes.SIMPLE_INPUT}
                        placeholder={TranslationService.translateIt('HELP_TAB_FORM_SUBJECT')}
                        isValid={formValidationObject.subjectStatus}
                        onBlurEnabled
                        onBlur={() => {
                            searchRelatedArticles(subjectText);
                        }}
                        onKeyDown={(e) => handleEnter(e, subjectText.length)}
                        dataElementDescription="help-subject"
                        maxLength={50}
                    />
                    <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
                        <RelatedArticlesSection articles={articles} className={styles.formField} />
                    </Responsive>
                    <Textarea
                        containerClassName={styles.formField}
                        className={classNames(styles.descriptionText, { [styles.frFix]: currentLang === 'fr' })}
                        value={descriptionText}
                        label={TranslationService.translateIt('HELP_TAB_FORM_COMMENT')}
                        placeholder={TranslationService.translateIt('HELP_TAB_FORM_COMMENT_PLACEHOLDER')}
                        onChange={(v) => {
                            onDescriptionChange(v);
                            SessionStorageService.setSessionData(formDataKey, 'descriptionText', v);
                        }}
                        dataElementDescription="comment-field"
                        maxLength={1000}
                    />
                    <TextField
                        inputFieldId={'name-field'}
                        required={true}
                        autocompleteAttrValue="name"
                        className={styles.formField}
                        value={nameText}
                        onChange={(v) => {
                            resetValidationField('nameStatus');
                            onNameChange(v);
                            SessionStorageService.setSessionData(formDataKey, 'nameText', v);
                        }}
                        errorText={TranslationService.translateIt('HELP_TAB_FORM_NAME_ERROR')}
                        inputType={TextFieldTypes.SIMPLE_INPUT}
                        placeholder={TranslationService.translateIt('HELP_TAB_FORM_NAME')}
                        isValid={formValidationObject.nameStatus}
                        onKeyDown={(e) => handleEnter(e, subjectText.length)}
                        dataElementDescription="help-name"
                        maxLength={50}
                    />
                    <TextField
                        inputFieldId={'email-field'}
                        required={true}
                        autocompleteAttrValue="email"
                        className={styles.formField}
                        value={emailText}
                        type="email"
                        onChange={(v) => {
                            resetValidationField('emailStatus');
                            onEmailChange(v);
                            SessionStorageService.setSessionData(formDataKey, 'emailText', v);
                        }}
                        errorText={TranslationService.translateIt('HELP_TAB_FORM_EMAIL_ERROR')}
                        inputType={TextFieldTypes.SIMPLE_INPUT}
                        placeholder={TranslationService.translateIt('HELP_TAB_FORM_EMAIL')}
                        isValid={formValidationObject.emailStatus}
                        onKeyDown={(e) => handleEnter(e, subjectText.length, true)}
                        dataElementDescription="help-email"
                        maxLength={50}
                    />
                    <div
                        className={classNames(
                            styles.formField,
                            styles.dropareaSmall,
                            { [styles.hidden]: dropZone },
                            {
                                [styles.errorBorder]: attachmentFormMessage === 2,
                            },
                            { [styles.acceptedFilesBorder]: attachments }
                        )}
                        tabIndex={0}
                        onClick={() => onClickAttachment()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onClickAttachment();
                            }
                        }}
                    >
                        <div>
                            <input {...getInputProps()} />
                            {attachmentFormMessage === 1 ? (
                                messageAttachment
                            ) : attachmentFormMessage === 0 ? (
                                <div />
                            ) : (
                                errorMessage
                            )}
                        </div>
                        <div className={styles.tooltipBlock}>
                            <NotifyIconTooltip
                                textTop={`Supported file types: ${FileUploadConstants.filetypes.join(', ')}`}
                                textBottom={`Filesize limit: ${FileUploadConstants.filesizeString}`}
                                classNameMessage="supportFormMessage"
                                classNameIcon="supportForm"
                                textForScreenReaderOnly="Supported files"
                                infoIconHidden={true}
                            />
                        </div>
                        <div className={styles.tooltipAttachBlock}>
                            <NotifyIconTooltip
                                textTop={`${TranslationService.translateIt('REMOVE_THE_EXISTING_FILE')} (${
                                    FileUploadConstants.filesizeString
                                } ${TranslationService.translateIt('MAX')}.)`}
                                tooltipNotVisible={!(attachments && attachments.length)}
                                classNameMessage="attachmentMessage"
                                classNameIcon={attachments ? 'attachIcon' : 'attachIconDisabled'}
                                customIcon
                                focusable={Boolean(attachments)}
                                hiddenFromScreenReader={!Boolean(attachments)}
                            />
                        </div>
                        {attachments && attachments.length > 0 && (
                            <div className={styles.attachmentWrapper}>
                                <div className={styles.attachedFile}>
                                    <div className={styles.attachedFileName}>{attachments[0].name}</div>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeFile(true);
                                        }}
                                        ariaLabel="delete attached file"
                                        className={styles.attachedFileCloseBtn}
                                    >
                                        <CloseIcon className={styles.closeIcon} />
                                    </Button>
                                </div>
                            </div>
                        )}
                        {loader && <AppLoader />}
                    </div>
                    <div className={classNames(styles.formField)}>
                        <Checkbox
                            iconClassName={styles.formCheckbox}
                            labelClassName={styles.formCheckboxLabel}
                            errorText={TranslationService.translateIt('HELP_TAB_FORM_AGREEMENT_ERROR')}
                            onClick={() => {
                                resetValidationField('agreementAcceptance');
                                onAgreeToProcessDataChange(!agreeToProcessData);
                                SessionStorageService.setSessionData(
                                    formDataKey,
                                    'agreeToProcessData',
                                    !agreeToProcessData
                                );
                            }}
                            state={
                                !formValidationObject.agreementAcceptance
                                    ? CheckboxTypes.WARNING
                                    : agreeToProcessData
                                    ? CheckboxTypes.ACTIVE
                                    : CheckboxTypes.INACTIVE
                            }
                            caption={TranslationService.translateIt('HELP_TAB_FORM_AGREEMENT_CAPTION') + ' *'}
                            dataElementDescription="radio-button"
                            btnType="button"
                            required={true}
                        />
                        <p className={styles.indicatorDescription}>* Indicates a required field</p>
                    </div>
                    <div className={classNames(styles.buttons, styles.formField)}>
                        <BorderedButton
                            caption={TranslationService.translateIt('CANCEL_BTN')}
                            onClick={onCancel}
                            className={styles.formButton}
                            dataElementDescription="feedback-cancel"
                        />

                        <BorderedButton
                            submit
                            caption={TranslationService.translateIt('SEND_BTN')}
                            className={classNames(styles.formButton)}
                            dataElementDescription="feedback-send"
                        />
                    </div>
                </form>
            </div>
        );
    }
);

const RelatedArticlesSection = ({ articles, className }: any) => (
    <Block className={className}>
        <>
            {articles?.recommended && articles.recommended.length > 0 && (
                <>
                    <h2 className={styles.relatedArticlesTitle}>Recommended help articles</h2>
                    <ul>
                        {articles.recommended.map((item, index) => (
                            <li key={item.title}>
                                <Button
                                    noPadding
                                    className={styles.question}
                                    onClick={() => {
                                        window.open(
                                            item.path
                                                ? `https://support.arkadium.com/en/support/solutions/articles/${item.path}`
                                                : item.pathFull,
                                            '_blank'
                                        );
                                    }}
                                    type="button"
                                >
                                    <span className={styles.relatedArticle}>
                                    {TranslationService.translateIt(item?.title)}
                                    </span>
                                </Button>
                            </li>
                            ))}
                    </ul>
                </>
            )}
            {articles?.popular && articles.popular.length > 0 && (
                <>
                    <h2 className={styles.relatedArticlesTitle}>Most popular help articles</h2>
                    <ul>
                        {articles.popular.map((item, index) => (
                            <li key={item.title}>
                                <Button
                                    noPadding
                                    className={styles.question}
                                    onClick={() => {
                                        window.open(
                                            item?.path
                                                ? `https://support.arkadium.com/en/support/solutions/articles/${item?.path}`
                                                : item?.pathFull,
                                            '_blank'
                                        );
                                    }}
                                    type="button"
                                >
                                    <span className={styles.relatedArticle}>
                                        {' '}
                                        {TranslationService.translateIt(item?.title)}{' '}
                                    </span>
                                </Button>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    </Block>
);
