import React, { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { ProfanityUtils } from '../../../../../utils/ProfanityUtils';
import { Input } from '../../../../atoms/HookFormInput/Input';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { getAvatarAnalyticsKey } from '../../../../constants/AvatarImagesData';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { RepeatIcon } from '../../../../FigmaStyleguide/Icons/RepeatIcon';
import { TextWithLink } from '../../../../FigmaStyleguide/TextWithLink/TextWithLink';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { TranslationService } from '../../../../services/TranslationService';
import UserService, { AuthType } from '../../../../services/UserService';
import { setProfilePanelState, setSignInUpState, setSnackbarData } from '../../../../store/ducks/layout';
import ChooseAvatar from '../ChooseAvatar/ChooseAvatar';
import styles from './PersonalizeProfile.css';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { LocalStorageService } from '../../../../services/LocalStorage';

type PersonalizeProfileProps = {
    onSignInUpFinished: () => unknown;
};
export const PersonalizeProfile = React.memo(({ onSignInUpFinished }: PersonalizeProfileProps) => {
    const [loading, setLoading] = useState(false);
    const [selectedAvatar, setSelectedAvatar] = useState('avatar_blank');

    const categoryPageName = useSelector((state) => state.categoryPageName);
    const gameArkadiumSlug = useSelector((state) => state.gameArkadiumSlug);
    const authDataContext = useContext(AuthDataContext);
    const { email, password, checkBox, authProvider, authProviderToken } = authDataContext.value;
    const games = useSelector((state) => state.games);
    const user = useSelector((state) => state.user);
    const socialRegistrationLocation = useSelector((state) => state.socialRegistrationLocation);
    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(RECAPTCHA_ACTIONS.SIGN_UP, (token) => {
        setValue('name', authDataContext.value.name);
    });

    const game = gameArkadiumSlug
        ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
        : '';

    const dispatch = useDispatch();

    const handleSkip = () => {
        const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedAvatar);
        Analytics.trackEvent(
            Analytics.profile.registrationAvatarSkip(avatarAnalyticsKey, MiscUtils.getAuthSource(authProvider))
        );
        handleSubmit(onSubmit);
    };

    const handleSocialRequest = (userData) => {
        if (authProvider === AuthType.Facebook) {
            return UserService.loginViaFacebook(
                authProviderToken,
                email,
                checkBox,
                new URL(UserService.generateUrl()),
                null,
                userData,
                captchaToken
            ).then((resData) => {
                setLoading(false);
                const res = resData.errorCode;
                Analytics.trackEvent(Analytics.profile.socialEmailRequest());
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.Facebook);
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
                    UserService.openAfterSignPanel();
                } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            });
        } else if (authProvider === AuthType.Google) {
            return UserService.loginViaGoogle(
                authProviderToken,
                email,
                checkBox,
                new URL(UserService.generateUrl()),
                null,
                userData,
                captchaToken
            ).then((resData) => {
                setLoading(false);
                const res = resData.errorCode;
                Analytics.trackEvent(Analytics.profile.socialEmailRequest());
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.Google);
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
                    UserService.openAfterSignPanel();
                } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            });
        } else {
            dispatch(
                setSnackbarData({
                    isOpened: true,
                    message: 'No social network selected',
                    type: 'error',
                    parentNode: 'rightSideMenu',
                })
            );
        }
    };

    const onSubmit = async () => {
        const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedAvatar);
        setLoading(true);
        const isNotProfanity = await ProfanityUtils.checkWord(name);
        // if the username is profanity, then we show the error
        if (!isNotProfanity) {
            setLoading(false);
            dispatch(
                setSnackbarData({
                    isOpened: true,
                    message: TranslationService.translateIt('SORRY_THAT_SCREEN_NAME_IS_NOT_AVAILABLE'),
                    type: 'error',
                    parentNode: 'rightSideMenu',
                })
            );
        }
        // if the username is not profanity, then we check it for existence
        isNotProfanity &&
            UserService.checkUserName({ name }).then((data) => {
                const isUserNameFree = data.result;
                // if the username is available, then we make a request (user update/social request/user registration - depends on the flow)
                if (isUserNameFree) {
                    const userData = {
                        name,
                        avatar: selectedAvatar,
                    };
                    if (user) {
                        // flow: the user registered for the first time through a social network
                        // figma: https://www.figma.com/file/3ULngpxCaEdP2yeC UHYEm8/Smarter-Login-%3E-DEV?node-id=583%3A13922
                        // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517540075385&cot=14
                        UserService.userUpdate(userData)
                            .then(() => {
                                // get user update
                                UserService.userLoad();

                                onSignInUpFinished();
                            })
                            .catch((err) => {
                                dispatch(
                                    setSnackbarData({
                                        isOpened: true,
                                        message: UserService.errorCodeToText(err),
                                        type: 'error',
                                        parentNode: 'rightSideMenu',
                                    })
                                );
                            });
                    } else if (authProvider && authProvider !== AuthType.EmailPassword) {
                        // flow: add an email to the user if he registered through a social network through a phone number
                        // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=583%3A13950
                        // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517611608158&cot=14
                        handleSocialRequest(userData);
                    } else {
                        // flow: standart provider: register user via email + password
                        // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=580%3A13847
                        // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517115541698&cot=14
                        return UserService.userRegister(email, password, checkBox, false, userData, captchaToken).then(
                            (res) => {
                                setLoading(false);
                                if (res && res.code === 200) {
                                    LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
                                    const { avatarBackground } = authDataContext.value;
                                    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
                                    // To have LP userAttribute {registered: true} set here we don't have email confirmed,
                                    // final Eagle user id, and LP session started with this id to set it in LP to correct user
                                    const { uid } = res;
                                    Analytics.trackEvent(
                                        Analytics.profile.registration(
                                            categoryPageName,
                                            game,
                                            RegistrationSource.EMAIL,
                                            avatarAnalyticsKey,
                                            avatarBackground,
                                            socialRegistrationLocation,
                                            uid
                                        )
                                    );

                                    Analytics.trackEvent(Analytics.profile.createAccountSuccess());

                                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                                } else {
                                    dispatch(
                                        setSnackbarData({
                                            isOpened: true,
                                            message: UserService.errorCodeToText(res),
                                            type: 'error',
                                            parentNode: 'rightSideMenu',
                                        })
                                    );
                                }
                                updateCaptchaToken(res !== 200);
                            }
                        );
                    }
                    // if the username already exists, then show the corresponding error
                } else {
                    setLoading(false);
                    setError('name', {
                        type: 'manual',
                        message: CHECK_USERNAME_ERROR,
                    });
                }
            });
    };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .matches(/^[0-9a-zA-Z]+$/, 'Your screen name should contain only Latin characters and numbers')
            .min(3, 'Your screen name needs to contain at least 3 characters.')
            .max(29, 'Your screen name needs to be under 30 characters.'),
    });

    const defaultValues = {
        name: '',
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const values = watch();
    const { name } = values;

    const CHECK_USERNAME_ERROR = 'Sorry, that username already exists';
    const PERSONALIZE_YOUR_PROFILE = 'Personalize your profile';

    const moreBtnClickHandler = () => {
        const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedAvatar);
        Analytics.trackEvent(
            Analytics.profile.registrationMoreAvatars(avatarAnalyticsKey, MiscUtils.getAuthSource(authProvider))
        );
        UserService.checkUserName({ name }).then((isUserNameFree) => {
            if (isUserNameFree) {
                onSignInUpFinished();
                authDataContext.setAuthData({ ...authDataContext.value, name });
                dispatch(
                    setProfilePanelState({
                        isOpened: true,
                        caption: TranslationService.translateIt('CHOOSE_YOUR_AVATAR'),
                        target: ProfileRightSideRoutes.AVATARS_PANEL,
                    })
                );
            } else {
                setError('name', {
                    type: 'manual',
                    message: CHECK_USERNAME_ERROR,
                });
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.tabContent}>
            <div className={styles.header}>
                <I18nText keyName={PERSONALIZE_YOUR_PROFILE} />
            </div>
            <div className={styles.inputWrapper}>
                <Input
                    className={styles.input}
                    isValid={!errors.name}
                    ariaInvalid={errors.name ? 'true' : 'false'}
                    register={register}
                    name="name"
                    type="text"
                    placeholder="Create your screen name"
                    value={name}
                    showError={errors?.name}
                    errorMessage={errors?.name?.message ?? ''}
                />
            </div>
            <Button
                isLink
                className={styles.forgotPassword}
                onClick={() => {
                    if (!loading) {
                        setLoading(true);
                        UserService.generateUserName().then((name) => {
                            setValue('name', name);
                            setLoading(false);
                        });
                    }
                }}
                type="button"
            >
                <I18nText keyName="Generate a new screen name" as="span" />
                <RepeatIcon className={styles.repeatIcon} />
            </Button>
            <ChooseAvatar
                selectedAvatar={selectedAvatar}
                setSelectedAvatar={setSelectedAvatar}
                moreBtnClickHandler={moreBtnClickHandler}
            />

            <Button type="submit" className={styles.submitButton} loading={loading}>
                <I18nText keyName="Save changes" as="span" />
            </Button>
            <TextWithLink
                className={styles.textWithLink}
                textKeyName="Do you want to finish later? "
                linkKeyName=" Skip this step"
                onClick={handleSkip}
            />
        </form>
    );
});
