import React from 'react';

import classNames from 'classnames';

import { devConsole } from '../../../../utils/DevConsole';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { AdTypes } from '../../../molecules/Ad/Ad';
import { DisplayAd } from '../../../molecules/DisplayAd/DisplayAd';
import { PwaPlatformsMobile } from '../../../services/Analytics/AnalyticsEventBuilder';
import { EKycBrowsers, PWAService } from '../../../services/PWAService';
import { UrlService } from '../../../services/UrlService';
import UserService from '../../../services/UserService';
import styles from './styles.css';

type TSteps = {
    stepsArr: string[];
    pathSuffix: string;
};

export const AddGamePwaTutorial = () => {
    const gameIconUrl = UrlService.createURL(
        `/images/pwa-tutorial/fallback_mahjong_icon${PWAService.kycIsSafari() ? '__old' : ''}.png`,
        true
    );
    const isUserSubscriber = UserService.isUserLoggedIn() && UserService.isUserSubscriber();
    const Title = () => {
        return (
            <div className={classNames(styles.AddGamePwaTutorialRow, styles.AddGamePwaTutorialTitle)}>
                <AddGamePwaTutorialTitleImg img={gameIconUrl} />
                <I18nText as="p" keyName="PWA_TUTORIAL_TITLE" />
            </div>
        );
    };
    const steps: TSteps = genStepsData();
    const { stepsArr, pathSuffix } = steps;
    let adSizeApplicable = [];
    let adOffsetLeft: number = 0;
    let blockSidePaddings: number = 0;
    let adOffsetStyle: React.CSSProperties = {};
    try {
        adSizeApplicable = Object.values(AdTypes as Object)
            .map((adType) => {
                const adsDimensionsArr = adType
                    .replace('AD_', '')
                    .split('x')
                    .map((dim) => parseInt(dim, 10));
                return adsDimensionsArr;
            })
            .filter((adSize) => {
                return adSize[0] <= window.innerWidth && adSize[1] <= 100;
            })
            .sort((a, b) => (a[0] >= b[0] && a[1] >= b[1] ? -1 : 1))
            .reverse();
        // [0]; it would be used for the biggest possible adSize if prop'd be this dimensions={[adSizeApplicable]}
        blockSidePaddings =
            1.5 /*rem*/ *
            2 /*paddings*/ *
            parseInt((window as any)?.getComputedStyle?.(document.documentElement)?.fontSize || 0); /*fontsize*/
        adOffsetLeft = blockSidePaddings ? (window.innerWidth - blockSidePaddings - adSizeApplicable[0][0]) / 2 : 0;
        devConsole(
            'PWA manual adSizeApplicable',
            Object.values(AdTypes as Object),
            window.innerWidth,
            adSizeApplicable
        );
    } catch (e) {
        console.error('Error in AddGamePwaTutorial Ads dimensions calculation: ', e);
    }
    adOffsetStyle = adOffsetLeft !== 0 ? { transform: `translateX(${adOffsetLeft}px)` } : {};
    devConsole('PWA manual adOffsetLeft', { adOffsetLeft, blockSidePaddings }, adOffsetStyle);

    return (
        <div className={styles.AddGamePwaTutorial}>
            {!isUserSubscriber && (
                <DisplayAd
                    id="ark_display_mobmenu_pwa_tutorial"
                    dimensions={adSizeApplicable}
                    bidtimeout={2500}
                    style={adOffsetStyle}
                />
            )}
            <Title />
            {stepsArr.map((key, index) => (
                <section className={classNames(styles.AddGamePwaTutorialRow, styles.AddGamePwaTutorialStep)} key={key}>
                    <I18nText as="p" keyName={key} className={styles.AddGamePwaTutorialStepText} />
                    <AddGamePwaTutorialScreenshot step={index + 1} pathSuffix={pathSuffix} />
                </section>
            ))}
        </div>
    );
};

function AddGamePwaTutorialTitleImg({ img }: { img: string }) {
    return (
        <ImageIcon
            blockClass={styles.AddGamePwaTutorialTitleImgBlock}
            img={img}
            alt={'game icon'}
            imgClass={styles.AddGamePwaTutorialTitleImg}
        />
    );
}

function AddGamePwaTutorialScreenshot({
    step,
    pathSuffix,
    ownClassBlock,
    ownClassImg,
}: {
    step: number;
    pathSuffix: string;
    ownClassBlock?: string;
    ownClassImg?: string;
}) {
    const screenshotUrl = UrlService.createURL(`/images/pwa-tutorial/screenshot-step${step}${pathSuffix}.png`, true);
    return (
        <ImageIcon
            blockClass={
                ownClassBlock
                    ? classNames(styles.AddGamePwaTutorialStepImgBlock, ownClassBlock)
                    : styles.AddGamePwaTutorialStepImgBlock
            }
            img={screenshotUrl}
            alt={`adding pwa step ${step}`}
            imgClass={
                ownClassImg
                    ? classNames(styles.AddGamePwaTutorialStepImg, ownClassImg)
                    : styles.AddGamePwaTutorialStepImgBlock
            }
        />
    );
}

function ImageIcon(props: { img: string; alt?: string; imgClass?: string; blockClass?: string }) {
    const { blockClass, imgClass, img, alt } = props;
    return (
        <div
            className={
                blockClass
                    ? classNames(styles.AddGamePwaTutorialImageBlock, blockClass)
                    : styles.AddGamePwaTutorialImageBlock
            }
        >
            <img className={imgClass || ''} src={img} alt={alt} />
        </div>
    );
}

function genStepsData(): TSteps {
    const browser = PWAService.kycBrowser();
    const os = PWAService.getDeviceOs();
    let stepsArr = ['PWA_TUTORIAL_STEP_1', 'PWA_TUTORIAL_STEP_2', 'PWA_TUTORIAL_STEP_3', 'PWA_TUTORIAL_STEP_4'];
    let pathSuffix = '';
    if (os === PwaPlatformsMobile.IOS) {
        // iOS has PWA (and manual available) only for Safari - solved in component with "-ios" suffix
        pathSuffix = '-ios';
        stepsArr = [
            'PWA_TUTORIAL_STEP_IOS_1',
            'PWA_TUTORIAL_STEP_IOS_2',
            'PWA_TUTORIAL_STEP_IOS_3',
            'PWA_TUTORIAL_STEP_IOS_4',
        ];
    } else {
        if (browser === EKycBrowsers.SAMSUNG) {
            pathSuffix = '-samsung';
            stepsArr = [
                'PWA_TUTORIAL_STEP_SAMSUNG_1',
                'PWA_TUTORIAL_STEP_SAMSUNG_2',
                'PWA_TUTORIAL_STEP_SAMSUNG_3',
                'PWA_TUTORIAL_STEP_SAMSUNG_4',
            ];
        }
    }
    return {
        stepsArr,
        pathSuffix,
    };
}
