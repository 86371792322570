import React from 'react';

import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import { setShouldTrackPageview } from '../store/ducks/pages';

let previousPathname = null;
let hasUnTrackedHelmetChanges = false;

// Helmet changes are being debounced, so flag is global for all calls
function onHelmetChangesApplied(dispatch) {
    if (hasUnTrackedHelmetChanges) {
        dispatch(setShouldTrackPageview(true));
        hasUnTrackedHelmetChanges = false;
    }
}

const WrappedHelmet = React.memo(({ dispatch, pathname, ...props }: any) => {
    // If location pathname changed, schedule tracking Pageview
    if (previousPathname && previousPathname !== pathname) {
        hasUnTrackedHelmetChanges = true;
    }
    previousPathname = pathname;

    return <Helmet onChangeClientState={() => onHelmetChangesApplied(dispatch)} {...props} />;
});

export const ConnectedHelmet = connect((state) => ({
    pathname: state.router.location.pathname,
}))(WrappedHelmet);
