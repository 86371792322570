import { LS_COOKIE_CONSTS } from '../models/Enums';
import { reduxStore } from '../store';
import { CookieService } from './CookieService';
import { DeviceType } from './DeviceDetector';

const { store } = reduxStore;

class AdFreeService {
    public showVignette(): boolean {
        const state = store.getState();
        const { adFree, subscription } = state.preLoadData;

        const returningVisitor =
            (CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN) as string) === 'returning';
        const isDesktop = state.deviceType === DeviceType.DESKTOP;

        const isAdfree = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';
        const isSubscriber = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';
        const isDevelopersPage = window.location.pathname.includes('developers') === true || window.location.origin.includes('developers') === true;


        if (adFree || subscription || isAdfree || isSubscriber || isDevelopersPage) return false;
        if (!isDesktop) return true;
        if (isDesktop && returningVisitor) return true;

        return false;
    }

    public vignetteAdsFix() {
        if (document.getElementById('aswift_1')) {
            document.getElementById('aswift_1').parentElement.innerHTML = '';
        }
        if (document.getElementById('aswift_2')) {
            document.getElementById('aswift_2').parentElement.innerHTML = '';
        }
    }

    public adFreeTurnOffFilter(adFreeVal: boolean) {
        return false;
    }
}

export default new AdFreeService();
