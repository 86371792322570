import { push } from 'connected-react-router';
import { batch } from 'react-redux';

import { LocalStorageService } from '../../services/LocalStorage';
import { lsSetGemsPurchaseFinished } from '../../services/LocalStorageListenerLogic';
import { setChosenGemsPack } from '../../store/ducks/gems/gems';

export const LS_SHOP_STATE_PROP = 'ARK_STATE_FOR_SHOP';
export const LS_SHOP_ERROR_TEXT = 'Error at opening /shop in new tab: ';
export type LsShopStateProps = {
    user?: any;
    gemPackChosen?: string;
};

class PurchaseNewTabHelper {
    static getTabData() {
        return LocalStorageService.getItem(LS_SHOP_STATE_PROP);
    }

    static isTabData() {
        return Boolean(this.getTabData());
    }

    static clearTabData() {
        if (this.isTabData()) {
            LocalStorageService.removeItem(LS_SHOP_STATE_PROP);
        }
    }

    static handleTabData({ newTabData, dispatch, chosenGemsPackItemName, redirectTarget, user }) {
        const isNewTab = Boolean(newTabData);
        let newTabDataObj: LsShopStateProps;
        try {
            newTabDataObj = JSON.parse(newTabData);
            const gemsPackChosenName = newTabDataObj?.gemPackChosen;
            dispatch(setChosenGemsPack(gemsPackChosenName));
        } catch (err) {
            console.error(LS_SHOP_ERROR_TEXT, err);
            dispatch(push(redirectTarget));
        }

        if (user && isNewTab && newTabData && newTabDataObj?.gemPackChosen === chosenGemsPackItemName) {
            this.clearTabData();
        } else {
            window.addEventListener('beforeunload', this.clearTabData);
            return window.removeEventListener('beforeunload', this.clearTabData);
        }
    }

    static gemCardClick({ currStatePack, currStateUser, dispatch, gameSlug }) {
        const url = gameSlug ? `/shop/?game=${gameSlug}` : '/shop';
        try {
            // save main entities from store
            if (currStateUser && currStatePack) {
                LocalStorageService.setItem(
                    LS_SHOP_STATE_PROP,
                    JSON.stringify({
                        user: currStateUser,
                        gemPackChosen: currStatePack,
                    })
                );
            }

            // Open shop page in new tab
            setTimeout(function () {
                window.open(url, '_blank');
            }, 200);
        } catch (err) {
            console.error(LS_SHOP_ERROR_TEXT, err);
            if (LocalStorageService.getItem(LS_SHOP_STATE_PROP)) {
                LocalStorageService.removeItem(LS_SHOP_STATE_PROP);
            }
            batch(() => {
                dispatch(setChosenGemsPack(currStatePack));
                dispatch(push(url));
            });
        }
    }

    static dispatchPurchaseDone() {
        lsSetGemsPurchaseFinished(new Date().getTime().toString());
    }
}

export { PurchaseNewTabHelper };
